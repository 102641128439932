export const GENERAL_LINKS = [
    { content: 'About', url: 'https://www.eventbrite.com/about' },
    { content: 'Blog', url: 'https://www.eventbrite.com/blog/' },
    { content: 'Help', url: 'https://www.eventbrite.com/support' },
    { content: 'Careers', url: 'https://www.eventbrite.com/careers/' },
    { content: 'Press', url: 'https://www.eventbrite.com/press' },
    { content: 'Security', url: 'https://www.eventbrite.com/security' },
    { content: 'Developers', url: 'http://developer.eventbrite.com/' },
    { content: 'Terms', url: 'https://www.eventbrite.com/tos' },
    { content: 'Privacy', url: 'https://www.eventbrite.com/privacypolicy' },
    { content: 'Cookies', url: 'https://www.eventbrite.com/cookies' },
];
