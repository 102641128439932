import { ROUTER_LINK, ANCHOR_LINK } from '@eb/eds-nav-list-item';
import { CONTENT_MAP, SIDEBAR_TITLE_MAP } from './mdxComponents';

const links = {
    gettingStarted: [
        'introduction',
        'authentication',
        'api-basics',
        'api-explorer',
    ],
    eventData: [
        'create-events',
        'events',
        'event-description',
        'ticket-classes',
        'questions',
        'orders',
        'attendees',
        'organizations',
        'image-upload',
        'online-event-page',
    ],
    searchEvents: ['by-date', 'by-category', 'by-location'],
    checkoutExperience: [
        'embedded-checkout',
        'order-lookup',
        'cancelling-and-refunding-orders',
    ],
    buildAnApp: [
        'what-is-app-marketplace',
        'app-approval-process',
        'app-oauth-flow',
        'integrate-your-app',
        'app-product-page',
        'managing-app-reviews',
    ],
    apps: ['mailchimp'],
    rateLimits: ['rate-limits'],
    webhooks: ['webhooks'],
    apiReference: ['api'],
    getSupport: ['contact'],
    termsOfUse: ['tos'],
    changelog: ['changelog'],
};

const getChildrenDocs = () => {
    const contentMap = CONTENT_MAP;
    const childrenArrMap = {
        gettingStarted: [],
        eventData: [],
        searchEvents: [],
        checkoutExperience: [],
        buildAnApp: [],
        apps: [],
        rateLimits: [],
        webhooks: [],
        apiReference: [],
        getSupport: [],
        termsOfUse: [],
        changelog: [],
    };

    for (const contentMapKey in contentMap) {
        if (contentMap.hasOwnProperty(contentMapKey)) {
            const childrenObj = {
                label: SIDEBAR_TITLE_MAP[contentMapKey],
                component: contentMap[contentMapKey],
            };

            for (const childrenArrMapKey in childrenArrMap) {
                if (childrenArrMap.hasOwnProperty(childrenArrMapKey)) {
                    const idx = links[childrenArrMapKey].indexOf(contentMapKey);

                    if (idx !== -1) {
                        childrenObj.link = links[childrenArrMapKey][idx];
                        childrenArrMap[childrenArrMapKey].push(childrenObj);
                    }
                }
            }
        }
    }

    return childrenArrMap;
};

const childrenDocs = getChildrenDocs();

const DOCS = [
    {
        title: 'Getting Started',
        children: childrenDocs.gettingStarted,
    },
    {
        title: 'Event Data',
        children: childrenDocs.eventData,
    },
    {
        title: 'Search Events',
        children: childrenDocs.searchEvents,
    },
    {
        title: 'Checkout Experience',
        children: childrenDocs.checkoutExperience,
    },
    {
        title: 'Build an App Integration',
        children: childrenDocs.buildAnApp,
    },
    {
        title: 'Mailchimp App',
        children: childrenDocs.apps,
    },
    {
        title: 'Rate Limits',
        children: childrenDocs.rateLimits,
    },
    {
        title: 'Webhooks',
        children: childrenDocs.webhooks,
    },
    {
        title: 'API Reference',
        children: [
            {
                label: 'API Reference',
                link: 'api',
                href: '/platform/api',
                type: ROUTER_LINK,
            },
        ],
    },
    {
        title: 'Get Support',
        children: childrenDocs.getSupport,
    },
    {
        title: 'Terms of Use',
        children: [
            {
                label: 'Terms of Use',
                link: 'tos',
                href: '/tos',
                type: ANCHOR_LINK,
            },
        ],
    },
    {
        title: 'Changelog',
        children: childrenDocs.changelog,
    },
];

export const PRIMARY_NAV_MAP = {
    docs: DOCS,
};
