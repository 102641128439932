import cookies from 'cookies-js';

import { getWindowObject } from '@eb/feature-detection';
/**
 *  Set a cookie with the GA client ID, so that sending events
 *  on the backend results in a consistent chain of actions
 *
 *  You may wonder why we're not just pulling the GA cookie.
 *  GA makes no promises about their internal implementation,
 *  just what's available through the API. If they change their
 *  cookie, we still get the right ID using the method here.
 */
export function setInternalUseGACookie() {
    const ga = getWindowObject('ga');
    let expirationDate;

    if (ga) {
        expirationDate = new Date();

        // roughly match GA's expiration
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        // wrap in async library call
        ga((tracker) => {
            var clientId = tracker.get('clientId'),
                cookieProperties = {
                    expires: expirationDate,
                    path: '/',
                };

            cookies.set('ebGAClientId', clientId, cookieProperties);
        });
    }
}
