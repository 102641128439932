
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Finding Information on an Order`}</MDXTag>
<MDXTag name="p" components={components}>{`You may want to find information about a specific Order, for example as part of
a checkout flow that involves an Order cancellation or refund. By default, an
Order object returns only financial and transactional information. Additional
Order data can be retrieved using expansions. The first step in finding
information about an Order is to identify the ID of the Order of interest. For
information on finding an Order ID by Event, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/orders"}}>{`Working with an Event's Orders`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`Order objects are considered private, meaning that all Order information is only
available to the Order Owner and a User who is a member of an Organization to
which the Event and it's associated Order belongs.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To work with Orders, you must authenticate your access to the Eventbrite API. To
learn how, refer to `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`
page.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Retrieving Information on a single Order`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the following GET request to retrieve information on an Order`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/orders/{order_id}/\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`An example response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "costs": {
        "base_price": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "eventbrite_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "gross": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "payment_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "tax": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        }
    },
    "resource_uri": "https://www.eventbriteapi.com/v3/orders/12345/",
    "id": "12345",
    "changed": "2018-11-02T19:07:24Z",
    "created": "2018-11-02T19:07:24Z",
    "name": "Kennedy Singleton",
    "first_name": "Kennedy",
    "last_name": "Singleton",
    "email": "kennedy@gmail.com",
    "status": "placed",
    "time_remaining": null,
    "event_id": "123456"
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Retrieving Information on a list of Orders`}</MDXTag>
<MDXTag name="p" components={components}>{`There are 3 endpoints to get a list of Orders. The response includes a
pagination object and an array of Orders.`}</MDXTag>
<MDXTag name="p" components={components}>{`An example response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "pagination": {
        "object_count": 6,
        "page_number": 1,
        "page_size": 50,
        "page_count": 1,
        "has_more_items": false
    },
    "orders": [
        ...
        ]
}
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`List Orders for a specific Event`}</MDXTag>
<MDXTag name="p" components={components}>{`To get list all Orders in an Event, use the following GET request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/orders\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`To filter in the Orders, append parameters listed below in the request url.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`status`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Filter Event's Order by status. Possible values are `}<MDXTag name="inlineCode" components={components} parentName="td">{`active`}</MDXTag>{` (Attending Order), `}<MDXTag name="inlineCode" components={components} parentName="td">{`inactive`}</MDXTag>{`(Not attending Order), `}<MDXTag name="inlineCode" components={components} parentName="td">{`both`}</MDXTag>{`(All Orders) and `}<MDXTag name="inlineCode" components={components} parentName="td">{`all_not_deleted`}</MDXTag>{`(Active and inactive, but not deleted, Orders).`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`changed_since`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Orders changed on or after this time.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`only_emails`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Orders placed by this email address.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`exclude_emails`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Orders placed by this email address are not included in return.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`refund_request_statuses`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string],`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Only Orders with specified refund request status are included in return. Possible values are `}<MDXTag name="inlineCode" components={components} parentName="td">{`completed`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="td">{`pending`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="td">{`outside_policy`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="td">{`disputed`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="td">{`denied`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`List Orders placed by your User`}</MDXTag>
<MDXTag name="p" components={components}>{`To get list all Orders placed by you, use the following GET request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/users/me/orders\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`To filter in the Orders, append parameters listed below in the request url.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`changed_since`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Order changed on or after this time.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`time_filter`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Filter the list to only, or all, past or current and future Orders. Possible values are `}<MDXTag name="inlineCode" components={components} parentName="td">{`all`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="td">{`past`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="td">{`current_future`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`List Orders under Events owned by a specific Organization`}</MDXTag>
<MDXTag name="p" components={components}>{`To get list all Orders by a specific `}<MDXTag name="inlineCode" components={components} parentName="p">{`organization_id`}</MDXTag>{`, use the following GET
request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/organizations/{organization_id}/orders\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`You can only access by Organizations your account is in.`}</MDXTag>
<MDXTag name="p" components={components}>{`To filter in the Orders, append parameters listed below in the request url.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`status`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Filter Order by status. Possible values are `}<MDXTag name="inlineCode" components={components} parentName="td">{`active`}</MDXTag>{` (Attending Order), `}<MDXTag name="inlineCode" components={components} parentName="td">{`inactive`}</MDXTag>{`(Not attending Order), `}<MDXTag name="inlineCode" components={components} parentName="td">{`both`}</MDXTag>{`(All Orders) and `}<MDXTag name="inlineCode" components={components} parentName="td">{`all_not_deleted`}</MDXTag>{`(Active and inactive, but not deleted, Orders).`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`changed_since`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Only return orders changed on or after the time given.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`only_emails`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Orders placed by this email address.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`exclude_emails`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Orders placed by this email address are not included in return.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<NextSteps
    steps={[
        {
            title: "Working with an Event's Orders",
            link: '/platform/docs/orders',
        },
        {
            title: 'Obtaining Attendee Information',
            link: '/platform/docs/attendees',
        },
    ]}
/>
           </MDXTag>
  }
}
  