import { keys, uniq } from 'lodash';
import { filter, flow, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { SVG_PROP_TYPE } from '@eb/eds-vector-image';

import { SIZES_JSON } from '@eb/eds-iconography';

export const ICON_TYPES_PAYMENT = [
    'discover',
    'amex',
    'android-pay',
    'apple-pay',
    'bancontact',
    'boleto',
    'google-wallet',
    'ideal',
    'jcb',
    'maestro',
    'mastercard',
    'moneris',
    'pagofacil',
    'paypal-bank',
    'paypal',
    'rapipago',
    'sepa',
    'sofort',
    'visa-debit',
    'visa',
    'oxxo',
    'elo',
    'hipercard',
    // payment icon hook for generator, do not remove!
];

export const PAYMENT_ICON_SIZES = flow(
    keys,
    filter((size) => size.indexOf('payment-') >= 0),
    map((size) =>
        size
            .replace('payment-icon-height-', '')
            .replace('payment-icon-width-', ''),
    ),
    uniq,
)(SIZES_JSON);

export const PAYMENT_TYPE_PROP_TYPE = SVG_PROP_TYPE;
export const PAYMENT_SIZE_PROP_TYPE = PropTypes.oneOf(PAYMENT_ICON_SIZES);
