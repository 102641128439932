import React from 'react';
import PropTypes from 'prop-types';
import { gettext, translationPropType } from '@eventbrite/i18n';
import isEmpty from 'lodash/isEmpty';
import findKey from 'lodash/findKey';
import classNames from 'classnames';
import { Link } from 'react-router';

import * as constants from './constants';
import { getMobileMenu, getNarrowMenuInfo } from './utils';
import { ROUTER_LINK } from '@eb/eds-nav-list-item';
import { ICON_TYPE_PROP_TYPE, COLOR_PROP_TYPE } from '@eb/eds-icon';
import { TEXT_ITEMS_PROP_TYPE } from '@eb/eds-text-list';
import { ALIGN_FULL, ALIGN_RIGHT } from '@eb/eds-containers';

import { Icon } from '@eb/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { AutocompleteField } from '@eb/eds-autocomplete-field';
import { FormField } from '@eb/eds-form-field';
import { Dropdown } from '@eb/eds-containers';
import { DropdownMenu } from '@eb/eds-dropdown-menu';
import { Avatar } from '@eb/eds-avatar';

import './globalHeader.scss';

import { MagnifyingGlassChunky } from '@eb/eds-iconography';
import { UserChunky } from '@eb/eds-iconography';

const GlobalHeaderLogo = ({ info }) => {
    const {
        url,
        iconType = constants.DEFAULT_LOGO_TYPE,
        iconColor = constants.DEFAULT_LOGO_COLOR,
        iconTitle = constants.DEFAULT_LOGO_TITLE,
        iconWidth = constants.DEFAULT_LOGO_SIZE.width,
        iconHeight = constants.DEFAULT_LOGO_SIZE.height,
        backgroundColor,
        shouldUseSpecialWidth,
        ...linkProps
    } = info;

    const className = classNames('eds-global-header__logo-link', {
        [`eds-bg-color--${backgroundColor}`]: backgroundColor,
        'eds-global-header--logo-special-width': !!shouldUseSpecialWidth,
    });

    return (
        <a
            href={url}
            className={className}
            data-spec="global-header-logo-link"
            {...linkProps}
        >
            <Icon
                type={iconType}
                color={iconColor}
                title={iconTitle}
                isBlockLevel={true}
                height={iconHeight}
                width={iconWidth}
                data-spec="global-header-logo"
            />
        </a>
    );
};

const GlobalHeaderSearchForm = ({
    searchInfo,
    id,
    className,
    hideIcon,
    onSelect,
    onSubmit,
    onChange,
    onBlur,
    onFocus,
    isLimitedSearch,
}) => {
    const {
        formAction,
        label,
        fieldName = constants.DEFAULT_SEARCH_FIELD_NAME,
        query = '',
        suggestions = [],
        formMethod = constants.DEFAULT_SEARCH_METHOD,
    } = searchInfo;
    const autocompleteId = `${id}-autocomplete`;
    let autocompleteIcon;

    if (!hideIcon) {
        autocompleteIcon = (
            <Icon
                type={<MagnifyingGlassChunky />}
                size="small"
                color="grey-500"
            />
        );
    }
    let form = (
        <FormField
            label={label}
            htmlFor={autocompleteId}
            hideLabel={true}
            bottomSpacing={0}
        >
            <AutocompleteField
                id={autocompleteId}
                type="search"
                name={fieldName}
                value={query}
                suggestions={suggestions}
                placeholder={label}
                label={label}
                data-automation="search-input"
                onSelect={onSelect}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                hideOutline={!hideIcon}
                isLimitedSearch={isLimitedSearch}
                prefix={autocompleteIcon}
                bottomSpacing={0}
                borderType="none"
            />
        </FormField>
    );

    if (formAction || onSubmit) {
        const handleFormSubmit = (e) => {
            // NOTE: If `onSubmit` is defined, we want to handle the form submission
            // in JavaScript, so we should prevent the default browser submission.
            // However, if `onSubmit` is not defined, we want to allow the default
            // broswer submission to happen.
            if (onSubmit) {
                e.preventDefault();
                onSubmit(query);
            }
        };

        form = (
            <form
                data-spec="global-header-search-form"
                action={formAction}
                method={formMethod}
                id={id}
                onSubmit={handleFormSubmit}
            >
                {form}
                <input type="hidden" name="mode" value="search" />
            </form>
        );
    }

    return <div className={className}>{form}</div>;
};

const GlobalHeaderSearchDropdown = ({
    show,
    searchInfo,
    id,
    onHide,
    onSelect,
    onSubmit,
    onChange,
    onBlur,
    onFocus,
    isLimitedSearch,
}) => {
    if (!show || !searchInfo) {
        return null;
    }

    return (
        <Dropdown align={ALIGN_FULL} onClickOutside={onHide}>
            <GlobalHeaderSearchForm
                searchInfo={searchInfo}
                id={id}
                hideIcon={true}
                onSelect={onSelect}
                onSubmit={onSubmit}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                isLimitedSearch={isLimitedSearch}
            />
        </Dropdown>
    );
};

const GlobalHeaderSearch = ({
    searchInfo,
    id,
    onSelect,
    onSubmit,
    onChange,
    onBlur,
    onFocus,
    onToggleDropdown,
    isLimitedSearch,
    launchSearchTakeoverExperience,
    onSearchClick,
}) => {
    if (!searchInfo) {
        return null;
    }

    if (launchSearchTakeoverExperience) {
        return (
            <div
                className="eds-global-header__search eds-text--right"
                data-spec="global-header-search"
            >
                <span className="eds-global-header__narrow-search-button-wrapper eds-show-down-mn eds-l-pad-right-2 ignore-react-onclickoutside">
                    <Button
                        style="none"
                        onClick={onSearchClick}
                        data-spec="global-search-narrow-search-button"
                    >
                        <Icon
                            type={<MagnifyingGlassChunky />}
                            size="small"
                            color="grey-700"
                        />
                    </Button>
                </span>
                <div className="eds-global-header__search-autocomplete eds-global-header__search-autocomplete--trigger eds-show-up-md">
                    <Button onClick={onSearchClick} style="none" size="block">
                        <AutocompleteField
                            disabled={true}
                            id="search-autocomplete"
                            type="search"
                            name="search-takeover"
                            value=""
                            suggestions={[]}
                            placeholder="Search events"
                            label="Trigger search event experience"
                            data-automation="search-input"
                            onSelect={onSelect}
                            onChange={onChange}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            hideOutline={true}
                            isLimitedSearch={isLimitedSearch}
                            prefix={
                                <Icon
                                    type={<MagnifyingGlassChunky />}
                                    size="small"
                                    color="grey-500"
                                />
                            }
                            bottomSpacing={0}
                            borderType="none"
                        />
                    </Button>
                </div>
            </div>
        );
    }

    // NOTE: the narrow search button below toggles the display of the narrow search dropdown.
    // That dropdown can also be hidden by "clicking away." However, clicking this button when
    // it's display should be considered a "click away" otherwise we'll have a case where the
    // dropdown will be hidden by "clicking away" and then show because we just clicked the
    // button to toggle it back on. So the "ignore-react-onclickoutside" class is a special
    // class that causes it not to be classified as a "click away".

    return (
        <div
            className="eds-global-header__search eds-text--right"
            data-spec="global-header-search"
        >
            <span className="eds-global-header__narrow-search-button-wrapper eds-show-down-mn eds-l-pad-right-2 ignore-react-onclickoutside">
                <Button
                    style="none"
                    onClick={onToggleDropdown}
                    data-spec="global-search-narrow-search-button"
                >
                    <Icon
                        type={<MagnifyingGlassChunky />}
                        size="small"
                        color="grey-700"
                    />
                </Button>
            </span>

            <GlobalHeaderSearchForm
                searchInfo={searchInfo}
                id={id}
                className="eds-global-header__search-autocomplete eds-show-up-md"
                onSelect={onSelect}
                onSubmit={onSubmit}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                isLimitedSearch={isLimitedSearch}
            />
        </div>
    );
};

const GlobalHeaderMenuIcon = ({ iconType, iconColor = 'grey-700' }) => (
    <Icon
        type={iconType}
        size="small"
        color={iconColor}
        data-spec="global-header-menu-icon"
    />
);

const GlobalHeaderQuickLinks = ({ links, useNarrowBreakpoint }) => {
    if (!links) {
        return null;
    }
    const linkComponents = links.map(
        ({
            url,
            content,
            narrowIconType,
            narrowIconColor,
            isActive,
            type,
            onClick,
            ...restProps
        }) => {
            let narrowIcon;
            let Component = 'a';
            const linkProps = restProps;
            const quickLinkWrapperClassName = classNames(
                'eds-global-header__quick-link',
                {
                    // If the link collapses into an icon on narrow screens,
                    // we want to make sure we're not hiding it
                    'eds-global-header__quick-link--narrow': narrowIconType,
                    // If the links not an icon and there's no reference to use a 'narrower' breakpoint
                    // we want to show the link at the medium breakpoint
                    'eds-show-up-md': !narrowIconType && !useNarrowBreakpoint,
                    // If the links not an icon and we're using a 'narrower' breakpoint
                    // we want to show the link at the medium narrow breakpoint
                    'eds-show-up-mn': !narrowIconType && useNarrowBreakpoint,
                    // If link is active apply this class
                    'eds-global-header__quick-link--active': isActive,
                },
            );
            // we only show the icon when the screen is "narrow". When `useNarrowBreakpoint`
            // is `true` we want it to be even "narrower".
            const narrowIconClassName = `eds-show-down-${
                useNarrowBreakpoint ? 'sw' : 'mn'
            }`;
            // we only show the content when the screen is *not* "narrow". When `useNarrowBreakpoint`
            // is `true` we want it to persist to a "narrower" point.
            const contentClassName = `eds-show-up-${
                useNarrowBreakpoint ? 'mn' : 'md'
            }`;

            if (narrowIconType) {
                narrowIcon = (
                    <GlobalHeaderMenuIcon
                        iconType={narrowIconType}
                        iconColor={narrowIconColor}
                    />
                );
            }

            if (type === ROUTER_LINK) {
                Component = Link;
                linkProps.to = url;
            } else {
                linkProps.href = url;
            }
            return (
                <Component
                    {...linkProps}
                    key={url}
                    className={quickLinkWrapperClassName}
                    data-spec="global-header-quick-link"
                    onClick={onClick}
                    aria-label={gettext('Quick Link').toString()}
                >
                    <span className={narrowIconClassName}>{narrowIcon}</span>
                    <span className={contentClassName}>{content}</span>
                </Component>
            );
        },
    );

    return (
        <div
            className="eds-global-header__quick-links"
            data-spec="global-header-quick-links"
        >
            {linkComponents}
        </div>
    );
};

const GlobalHeaderCallToAction = ({ linkInfo, useNarrowBreakpoint }) => {
    let component = null;

    if (linkInfo) {
        const { url, content, ...linkProps } = linkInfo;
        const className = classNames('eds-global-header__call-to-action', {
            'eds-show-up-md': !useNarrowBreakpoint,
            'eds-show-up-mn': useNarrowBreakpoint,
        });

        component = (
            <span className={className}>
                <a
                    {...linkProps}
                    href={url}
                    data-spec="global-header-call-to-action"
                    className="eds-global-header__call-to-action__link"
                >
                    {content}
                </a>
            </span>
        );
    }

    return component;
};

const GlobalHeaderDropdownMenuImage = ({ imageUrl }) => (
    <span className="eds-global-header__menu-profile-image--container">
        <img
            className="eds-global-header__menu-profile-image"
            src={imageUrl}
            alt={gettext('Profile Image')}
            data-spec="global-header-menu-profile-image"
        />
        <div className="eds-global-header__menu-profile-image--border" />
    </span>
);

const GlobalHeaderDropdownMenuNarrowOnlyIcon = ({
    narrowIconType,
    narrowIconColor,
}) => (
    <span className="eds-global-header__menu-icon eds-show-down-mn">
        <GlobalHeaderMenuIcon
            iconType={narrowIconType}
            iconColor={narrowIconColor}
        />
    </span>
);

const GlobalHeaderDropdownMenuIcon = ({
    iconType,
    narrowIconType,
    iconColor,
    narrowIconColor,
}) => {
    const className = classNames('eds-global-header__menu-icon', {
        'eds-show-up-md': narrowIconType,
    });
    let icon = null;

    if (narrowIconType) {
        icon = (
            <span
                className="eds-global-header__menu-icon eds-show-down-mn"
                data-spec="global-header-menu-icon-narrow-container"
            >
                <GlobalHeaderMenuIcon
                    iconType={narrowIconType}
                    iconColor={narrowIconColor}
                />
            </span>
        );
    }

    return (
        <span>
            <span
                className={className}
                data-spec="global-header-menu-icon-container"
            >
                <GlobalHeaderMenuIcon
                    iconType={iconType}
                    iconColor={iconColor}
                />
            </span>
            {icon}
        </span>
    );
};

const GlobalHeaderDropdownMenuAvatar = ({ avatarProps }) => (
    <Avatar size="small" {...avatarProps} />
);

const GlobalHeaderUserAvatar = (menuInfo) => {
    const { avatarProps, imageUrl, iconType, narrowIconType } = menuInfo;

    let component = null;

    const helperTypes = {
        avatar: { test: () => avatarProps, fn: GlobalHeaderDropdownMenuAvatar },
        image: { test: () => imageUrl, fn: GlobalHeaderDropdownMenuImage },
        icon: { test: () => iconType, fn: GlobalHeaderDropdownMenuIcon },
        narrowIcon: {
            test: () => !iconType && narrowIconType,
            fn: GlobalHeaderDropdownMenuNarrowOnlyIcon,
        },
    };

    const _getType = (types) => {
        const type = findKey(types, (value) => value.test());

        let Klass = null;

        if (type) {
            Klass = helperTypes[type].fn;
        }
        return Klass;
    };

    const UserAvatarComponent = _getType(helperTypes);

    if (UserAvatarComponent) {
        component = <UserAvatarComponent {...menuInfo} />;
    }

    return component;
};

const DropdownComponentByType = ({
    menuInfo,
    onAdditionalMenuTextItemSelect,
    noLabel,
    ...additionalProps
}) => {
    const {
        label,
        fallbackUrl,
        navItems,
        textItems,
        className,
        preventMouseOver,
        imageUrl,
        iconType,
        narrowIconType,
    } = menuInfo;

    const menuContainerClassName = classNames(
        className,
        'eds-global-header__menu',
    );

    const hasImageOrIcon = imageUrl || iconType || narrowIconType;

    return (
        <div className={menuContainerClassName}>
            <DropdownMenu
                {...additionalProps}
                fallbackUrl={fallbackUrl}
                navItems={navItems}
                textItems={textItems}
                dropdownAlign={ALIGN_RIGHT}
                preventMouseOver={preventMouseOver}
                onTextItemSelect={onAdditionalMenuTextItemSelect}
                hideDropIconWhenNarrow={!!hasImageOrIcon}
            >
                <GlobalHeaderUserAvatar {...menuInfo} />
                {!noLabel && (
                    <span
                        className="eds-global-header__menu-label"
                        data-spec="global-header-menu-label"
                    >
                        {label}
                    </span>
                )}
            </DropdownMenu>
        </div>
    );
};

const DropdownButton = ({
    item,
    iconType,
    narrowIconType,
    iconColor,
    narrowIconColor,
    onClick,
}) => {
    let component = null;
    const { value, content } = item;
    let icon = null;
    let narrowIcon = null;

    if (narrowIconType) {
        narrowIcon = (
            <div className="eds-global-header__menu-icon eds-show-down-mn">
                <GlobalHeaderMenuIcon
                    iconType={narrowIconType}
                    iconColor={narrowIconColor}
                />
            </div>
        );
    }

    if (iconType) {
        icon = (
            <span className="eds-global-header__menu-icon eds-show-up-mn">
                <GlobalHeaderMenuIcon
                    iconType={iconType}
                    iconColor={iconColor}
                />
            </span>
        );
    }

    component = (
        <div className="eds-global-header__menu-button">
            <Button
                style="none"
                onClick={onClick.bind(null, value, 0)}
                data-spec="global-header-dropdown-button"
            >
                {icon}
                {narrowIcon}
                <span className="eds-global-header__menu-label">{content}</span>
            </Button>
        </div>
    );

    return component;
};

const GlobalHeaderMenu = ({
    menuInfo,
    onAdditionalMenuTextItemSelect,
    noLabel = false,
    ...additionalProps
}) => {
    let component = null;

    if (menuInfo) {
        const {
            textItems = [],
            iconType,
            iconColor,
            narrowIconType,
            narrowIconColor,
        } = menuInfo;
        const hasOnlyOneItem = textItems.length === 1;

        if (hasOnlyOneItem) {
            const item = textItems[0];

            component = (
                <DropdownButton
                    item={item}
                    iconType={iconType}
                    iconColor={iconColor}
                    narrowIconType={narrowIconType}
                    narrowIconColor={narrowIconColor}
                    onClick={onAdditionalMenuTextItemSelect}
                />
            );
        } else {
            component = (
                <DropdownComponentByType
                    {...additionalProps}
                    menuInfo={menuInfo}
                    noLabel={noLabel}
                    onAdditionalMenuTextItemSelect={
                        onAdditionalMenuTextItemSelect
                    }
                />
            );
        }
    }

    return component;
};

const GlobalHeaderMenus = ({
    userMenuInfo,
    additionalMenus,
    onAdditionalMenuTextItemSelect,
    useNarrowBreakpoint,
    onUserDropdownMouseEnter,
    onClickDropdown,
}) => {
    let menus = [];
    let component = null;
    const className = classNames('eds-global-header__menus', {
        'eds-show-up-md': !useNarrowBreakpoint,
        'eds-show-up-mn': useNarrowBreakpoint,
    });

    /**
     * using the index to generate a unique key in this case as no other id
     * or unique value is available; current state some labels/urls are duplicates
     * and many are static — see https://reactjs.org/docs/lists-and-keys.html
     */
    if (!isEmpty(additionalMenus)) {
        menus = additionalMenus.map((menuInfo, index) => (
            <div
                key={`${menuInfo.label}-${index}`}
                className="eds-global-header__additional-menu"
            >
                <GlobalHeaderMenu
                    menuInfo={menuInfo}
                    onAdditionalMenuTextItemSelect={
                        onAdditionalMenuTextItemSelect
                    }
                />
            </div>
        ));
    }
    if (userMenuInfo) {
        const userMenuInfoWithIcon = {
            iconType: <UserChunky />,
            className: 'eds-global-header__user-menu',
            ...userMenuInfo,
        };

        menus = [
            ...menus,
            <GlobalHeaderMenu
                key="userMenu"
                menuInfo={userMenuInfoWithIcon}
                data-spec="global-header-user-menu"
                onMouseEnter={onUserDropdownMouseEnter}
                onClickDropdown={onClickDropdown}
            />,
        ];
    }

    if (!isEmpty(menus)) {
        component = (
            <div className={className} data-spec="global-header-menus">
                {menus}
            </div>
        );
    }

    return component;
};

const GlobalHeaderMajorSection = ({
    id,
    logoInfo,
    searchInfo,
    quickLinks,
    onSearchSelect,
    onSearchSubmit,
    onSearchChange,
    onSearchBlur,
    onSearchFocus,
    onToggleDropdown,
    isLimitedSearch,
    useNarrowBreakpoint,
    launchSearchTakeoverExperience,
    onSearchClick,
}) => (
    <div className="eds-global-header__major" data-spec="global-header-major">
        <GlobalHeaderLogo info={logoInfo} />
        <GlobalHeaderSearch
            id={`${id}-search`}
            searchInfo={searchInfo}
            onSelect={onSearchSelect}
            onSubmit={onSearchSubmit}
            onChange={onSearchChange}
            onBlur={onSearchBlur}
            onFocus={onSearchFocus}
            onToggleDropdown={onToggleDropdown}
            isLimitedSearch={isLimitedSearch}
            launchSearchTakeoverExperience={launchSearchTakeoverExperience}
            onSearchClick={onSearchClick}
        />
        <GlobalHeaderQuickLinks
            links={quickLinks}
            useNarrowBreakpoint={useNarrowBreakpoint}
        />
    </div>
);

const GlobalHeaderMinorSection = ({
    userMenuInfo,
    additionalMenus,
    nonUserQuickLinks,
    callToActionInfo,
    narrowMenuInfo,
    onAdditionalMenuTextItemSelect,
    useNarrowBreakpoint,
    onUserDropdownMouseEnter,
    onClickDropdown,
}) => {
    let narrowMenu;
    let narrowAdditionalMenus;

    if (narrowMenuInfo) {
        const className = classNames('eds-global-header__narrow-menu', {
            'eds-show-down-sw': useNarrowBreakpoint,
            'eds-show-down-mn': !useNarrowBreakpoint,
        });

        narrowMenu = narrowMenuInfo.map((menuInfo) => (
            <span key={menuInfo.label.toString()} className={className}>
                <GlobalHeaderMenu
                    menuInfo={menuInfo}
                    data-spec="global-header-narrow-menu"
                />
            </span>
        ));
    }

    if (!isEmpty(additionalMenus)) {
        narrowAdditionalMenus = additionalMenus.map((menuInfo) => {
            if (menuInfo.narrowIconType) {
                return (
                    <div
                        className="eds-global-header__narrow-menu eds-show-down-mn"
                        key={menuInfo.label.toString()}
                    >
                        <GlobalHeaderMenu
                            menuInfo={menuInfo}
                            onAdditionalMenuTextItemSelect={
                                onAdditionalMenuTextItemSelect
                            }
                        />
                    </div>
                );
            }

            return null;
        });
    }

    if (userMenuInfo) {
        const className = classNames({
            'eds-show-down-sw': useNarrowBreakpoint,
            'eds-show-down-mn': !useNarrowBreakpoint,
        });
        const newUserMenu = userMenuInfo.label
            ? getMobileMenu(userMenuInfo)
            : userMenuInfo;
        const userMenuInfoWithIcon = {
            iconType: <UserChunky />,
            className: 'eds-global-header__user-menu',
            ...newUserMenu,
        };
        narrowMenu = [
            ...narrowMenu,
            <GlobalHeaderMenu
                key="userMenu"
                menuInfo={userMenuInfoWithIcon}
                data-spec="global-header-user-menu-narrow"
                className={className}
                noLabel={true}
            />,
        ];
    }

    return (
        <div
            className="eds-global-header__minor"
            data-spec="global-header-minor"
        >
            <GlobalHeaderCallToAction
                linkInfo={callToActionInfo}
                useNarrowBreakpoint={useNarrowBreakpoint}
            />
            <GlobalHeaderMenus
                onUserDropdownMouseEnter={onUserDropdownMouseEnter}
                additionalMenus={additionalMenus}
                onAdditionalMenuTextItemSelect={onAdditionalMenuTextItemSelect}
                useNarrowBreakpoint={useNarrowBreakpoint}
            />
            <GlobalHeaderQuickLinks
                links={nonUserQuickLinks}
                useNarrowBreakpoint={useNarrowBreakpoint}
            />
            <GlobalHeaderMenus
                onUserDropdownMouseEnter={onUserDropdownMouseEnter}
                userMenuInfo={userMenuInfo}
                useNarrowBreakpoint={useNarrowBreakpoint}
                onClickDropdown={onClickDropdown}
            />
            {narrowAdditionalMenus}
            {narrowMenu}
        </div>
    );
};

export default class GlobalHeader extends React.PureComponent {
    static propTypes = {
        /**
         * Configuration for the global header logo, including the URL that
         * the logo link navigates to
         */
        logoInfo: PropTypes.shape({
            url: PropTypes.string.isRequired,
            iconType: ICON_TYPE_PROP_TYPE,
            iconColor: COLOR_PROP_TYPE,
            iconTitle: translationPropType,
            iconWidth: PropTypes.string,
            iconHeight: PropTypes.string,
            backgroundColor: COLOR_PROP_TYPE,
            shouldUseSpecialWidth: PropTypes.bool,
        }).isRequired,

        /**
         * ID of the global header
         */
        id: PropTypes.string,
        /**
         * Configuration for the search field. If omitted, no search field
         * is shown. If suggestions are omitted, a regular text input is
         * displayed.
         */
        searchInfo: PropTypes.shape({
            label: translationPropType.isRequired,
            formAction: PropTypes.string,
            fieldName: PropTypes.string,
            query: PropTypes.string,
            suggestions: TEXT_ITEMS_PROP_TYPE,
            formMethod: PropTypes.string,
        }),
        /**
         * Configuration for 1 or more "quick links" in the global header
         * that show before the menus (i.e. "Browse events").
         * If omitted or 0, they will not show.
         */
        quickLinks: constants.LINKS_PROP_TYPE,
        /**
         * Configuration for the user dropdown nav menu in the global header.
         */
        userMenuInfo: constants.DROPDOWN_PROP_TYPE,
        /**
         * Configuration for 1 or more additional dropdown nav/text menus in the global header.
         * If omitted or 0, they will not show. On a narrow screen, the menu labels
         * and fallbackUrls will be included in the userMenu if it exists, otherwise
         * in a special default menu. These show before the userMenu
         */
        additionalMenus: PropTypes.arrayOf(constants.DROPDOWN_PROP_TYPE),
        /**
         * Configuration for 1 or more "quick links" in the global header that show after
         * the menus, but only show when the userMenu is undefined (i.e. "Sign Up").
         * If omitted or 0, they will not show.
         */
        nonUserQuickLinks: constants.LINKS_PROP_TYPE,
        /**
         * Configuration for the main "call to action"
         * (i.e. "Create event"). If omitted, it will not show.
         */
        callToActionInfo: constants.LINK_PROP_TYPE,
        /**
         * Callback function invoked when the value of the search input changes.
         * (searchQuery) => { }
         */
        onSearchChange: PropTypes.func,
        /**
         * Callback function invoked when the search input is blurred.
         * (searchQuery) => { }
         */
        onSearchBlur: PropTypes.func,
        /**
         * Callback function invoked when the search input is focused.
         * (searchQuery) => { }
         */
        onSearchFocus: PropTypes.func,
        /**
         * Callback function invoked when a search suggestion is selected.
         * (searchQuery) => { }
         */
        onSearchSelect: PropTypes.func,
        /**
         * Callback function invoked when a search form is submitted (i.e. ENTER is pressed).
         * Default browser for submission is disabled.
         * (searchQuery) => { }
         */
        onSearchSubmit: PropTypes.func,
        /**
         * Whether or not you can **only** pick from the list of search suggestions
         */
        isLimitedSearch: PropTypes.bool,
        /**
         * Callback function invoked when a menu item is selected.
         * (value, index) => { }
         */
        onAdditionalMenuTextItemSelect: PropTypes.func,
        /**
         * For headers with a lot of content and still space for it to fit,
         * narrow breakpoint allows links to persist until 792px.
         */
        useNarrowBreakpoint: PropTypes.bool,
        /**
         * Changes the visual/color scheme of the header
         */
        style: PropTypes.oneOf(constants.STYLES),
    };

    static defaultProps = {
        id: constants.DEFAULT_ID,
        isLimitedSearch: false,
        useNarrowBreakpoint: false,
        style: constants.STYLE_DEFAULT,
    };

    state = {
        // the search dropdown is shown by clicking a search button that only
        // shows on a narrow screen. The main search autocomplete is hidden
        // when that narrow search button is displayed.
        showSearchDropdown: false,
    };

    _handleToggleSearchDropdown() {
        this.setState(({ showSearchDropdown: prevShowSearchDropDown }) => ({
            showSearchDropdown: !prevShowSearchDropDown,
        }));
    }

    _handleHideSearchDropdown() {
        this.setState({ showSearchDropdown: false });
    }

    _handleOnAdditionalMenuTextItemSelect(value, index) {
        if (this.props.onAdditionalMenuTextItemSelect) {
            this.props.onAdditionalMenuTextItemSelect(value, index);
        }
    }
    render() {
        const {
            id,
            logoInfo,
            searchInfo,
            quickLinks,
            userMenuInfo,
            additionalMenus,
            nonUserQuickLinks,
            callToActionInfo,
            onSearchSelect,
            onSearchChange,
            onSearchBlur,
            onSearchFocus,
            onSearchSubmit,
            isLimitedSearch,
            useNarrowBreakpoint,
            style,
            narrowMenuLinks = null,
            onUserDropdownMouseEnter,
            onClickDropdown,
            launchSearchTakeoverExperience,
            onSearchClick,
        } = this.props;
        const { showSearchDropdown } = this.state;
        let narrowMenuInfo =
            narrowMenuLinks ??
            getNarrowMenuInfo(
                userMenuInfo,
                additionalMenus,
                nonUserQuickLinks,
                callToActionInfo,
                quickLinks,
                !useNarrowBreakpoint,
            );

        if (narrowMenuInfo && !Array.isArray(narrowMenuInfo)) {
            narrowMenuInfo = [narrowMenuInfo];
        }

        const className = classNames('eds-global-header', {
            'eds-global-header--style-organizer':
                style === constants.STYLE_ORGANIZER,
        });

        return (
            <header
                id={id}
                className={className}
                data-spec="global-header"
                aria-label={gettext('Eventbrite header')}
            >
                <a
                    className="eds-global-header__skip-links eds-is-hidden-accessible"
                    href="#skip-heading"
                >
                    {gettext('Skip Main Navigation')}
                </a>

                <div className="eds-global-header__main" data-role="header">
                    <GlobalHeaderMajorSection
                        id={id}
                        logoInfo={logoInfo}
                        searchInfo={searchInfo}
                        quickLinks={quickLinks}
                        onSearchSelect={onSearchSelect}
                        onSearchSubmit={onSearchSubmit}
                        onSearchChange={onSearchChange}
                        onSearchBlur={onSearchBlur}
                        onSearchFocus={onSearchFocus}
                        launchSearchTakeoverExperience={
                            launchSearchTakeoverExperience
                        }
                        onSearchClick={onSearchClick}
                        onToggleDropdown={this._handleToggleSearchDropdown.bind(
                            this,
                        )}
                        isLimitedSearch={isLimitedSearch}
                        useNarrowBreakpoint={useNarrowBreakpoint}
                    />
                    <GlobalHeaderMinorSection
                        onUserDropdownMouseEnter={onUserDropdownMouseEnter}
                        userMenuInfo={userMenuInfo}
                        additionalMenus={additionalMenus}
                        onAdditionalMenuTextItemSelect={this._handleOnAdditionalMenuTextItemSelect.bind(
                            this,
                        )}
                        nonUserQuickLinks={nonUserQuickLinks}
                        callToActionInfo={callToActionInfo}
                        narrowMenuInfo={narrowMenuInfo}
                        useNarrowBreakpoint={useNarrowBreakpoint}
                        onClickDropdown={onClickDropdown}
                    />
                    <GlobalHeaderSearchDropdown
                        show={showSearchDropdown}
                        id={`${id}-search-dropdown`}
                        searchInfo={searchInfo}
                        onHide={this._handleHideSearchDropdown.bind(this)}
                        onSelect={onSearchSelect}
                        onSubmit={onSearchSubmit}
                        onChange={onSearchChange}
                        onBlur={onSearchBlur}
                        onFocus={onSearchFocus}
                        isLimitedSearch={isLimitedSearch}
                    />
                </div>

                <span id="skip-heading" className="eds-is-hidden-accessible">
                    {gettext('Page Content')}
                </span>
            </header>
        );
    }
}
