import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Icon } from '@eb/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { ArrowRightChunky } from '@eb/eds-iconography';

import './twoColumnCTA.scss';

const CTAColumn = ({
    heading,
    description,
    linkUrl,
    linkText,
    reverseHorizontal,
}) => {
    // eds-g-offset-3-12
    const ctaColumnClassName = classnames(
        'eds-g-cell',
        'eds-g-cell-12-12',
        'eds-g-cell-sw-6-12',
        'eds-l-sm-pad-hor-0',
        'eds-l-sn-pad-hor-0',
        'eds-l-sm-pad-bot-8',
        'eds-l-sn-pad-bot-8',
        {
            'eds-l-pad-left-20': reverseHorizontal,
            'eds-l-pad-right-20': !reverseHorizontal,
        },
    );

    return (
        <div className={ctaColumnClassName}>
            <div className="">
                <h2 className="eds-text-hm eds-l-mar-bot-4">{heading}</h2>
                <p className="eds-text-bm eds-text-height--open eds-l-mar-bot-4">
                    {description}
                </p>
                <p>
                    <Button
                        type="link"
                        style="anchor"
                        href={linkUrl}
                        __containerClassName="learn-more-link"
                    >
                        <span className="eds-l-mar-right-1">{linkText}</span>
                        <Icon
                            type={<ArrowRightChunky />}
                            color="ui-orange"
                            size="xsmall"
                            __containerClassName="learn-more-icon"
                        />
                    </Button>
                </p>
            </div>
        </div>
    );
};

export default class TwoColumnCTA extends PureComponent {
    render() {
        const {
            heading,
            description,
            linkUrl,
            linkText,
            reverseHorizontal,
        } = this.props;

        const containerClassName = classnames(
            'marketing-template',
            'eds-g-group',
            'eds-l-sn-pad-bot-8',
            'eds-l-sm-pad-bot-8',
            {
                'marketing-template--reverse-horizontal': reverseHorizontal,
            },
        );

        const imgColumnClassName = classnames(
            'eds-g-cell',
            'eds-g-cell-12-12',
            'eds-g-cell-sw-6-12',
            'eds-g-cell--has-overflow',
        );

        return (
            <section className="marketing-container marketing-two-column-cta eds-l-mar-bot-6">
                <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-10-12 eds-g-offset-sw-1-12 eds-g-cell--has-overflow">
                    <div className={containerClassName}>
                        <CTAColumn
                            heading={heading}
                            description={description}
                            linkUrl={linkUrl}
                            linkText={linkText}
                            reverseHorizontal={reverseHorizontal}
                        />
                        <div className={imgColumnClassName}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
