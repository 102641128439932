/* eslint-disable react/no-danger */
import range from 'lodash/range';
import React, { PureComponent } from 'react';

import classnames from 'classnames';
import { LOGOBARLOGOS, LOGOBARTITLE } from '../../constants/home';

import './logoBar.scss';

const NUMBER_OF_COLUMNS = LOGOBARLOGOS.length;

const _getFeaturedLogos = (logos) =>
    range(NUMBER_OF_COLUMNS).map((index) => {
        const { name: logoName, img, isVisibleMobile } = logos[index];
        const imgClassName = classnames(
            'eds-l-mar-hor-4',
            'eds-l-sn-mar-hor-0',
            {
                'eds-show-up-md': !isVisibleMobile,
            },
        );

        return (
            <img
                src={img}
                alt={logoName}
                key={`${logoName}`}
                className={imgClassName}
            />
        );
    });

export default class LogoBar extends PureComponent {
    render() {
        const featuredLogos = _getFeaturedLogos(LOGOBARLOGOS);

        return (
            <div className="marketing-logo-bar eds-l-pad-hor-10 eds-l-sn-pad-hor-0 eds-l-sm-pad-hor-0 eds-l-sw-pad-hor-5">
                <div className="marketing-container eds-l-pad-vert-6 eds-text--center eds-bg-color--smart-maroon">
                    <h2
                        className="eds-text-hm eds-l-mar-bot-4 eds-l-pad-hor-8"
                        dangerouslySetInnerHTML={LOGOBARTITLE}
                    />
                    <div className="marketing-logo-bar__logos eds-g-cell eds-g-cell-12-12">
                        {featuredLogos}
                    </div>
                </div>
            </div>
        );
    }
}
