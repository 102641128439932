import { day as DAY_COLORS_JSON } from '@eb/eds-settings';
import PropTypes from 'prop-types';
// Colors
import COLORS_JSON from './color_colors.json';
import GREYS_JSON from './color_greys.json';

export const COLORS = [
    // Legacy color names
    ...Object.keys(COLORS_JSON),
    ...Object.keys(GREYS_JSON),
    // Theme color names
    ...Object.keys(DAY_COLORS_JSON),
];

export const COLORS_PROP_TYPE = PropTypes.oneOf(COLORS);

export { COLORS_JSON, GREYS_JSON, DAY_COLORS_JSON };
