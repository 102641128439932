import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import omit from 'lodash/omit';
import OnClickOutsideMixin from 'react-onclickoutside';

// NOTE: We're using v4 of `react-onclickoutside` instead of v5 here in EDS because
// we're using `react-datepicker` that still (implicitly) depends on v4 of
// `react-onclickoutside`. v4 still uses ES5 mixins but *does* provide a `/decorator`
// path to use decorators/HOC. However, that path isn't in UMD so it fails when
// EDS is included in core. The best option we could come up with was to just
// rewrite the decorator code here in this utility. Since we need to rely on
// the `OnClickOutsideMixin`, we have to use `React.createClass` instead of using
// ES6 classes. This is a ES6-ish port of:
// https://github.com/Pomax/react-onclickoutside/blob/v4.9.0/decorator.js
// The interface is that a parent passes an `onClickOutside` prop handler to the
// child wrapped by this decorator and it'll be called when the user clicks outside

/* eslint-disable react/prefer-es6-class */
const addClickOutsideListener = (Component) => {
    const componentDisplayName = Component.displayName || Component.name;

    return createReactClass({
        displayName: `${componentDisplayName}ClickOutside`,
        propTypes: {
            // ensure that the HOC still supports the propTypes that
            // Component does
            ...Component.propTypes,
            onClickOutside: PropTypes.func,
        },
        mixins: [OnClickOutsideMixin],
        handleClickOutside(e) {
            if (this.props.onClickOutside) {
                this.props.onClickOutside(e);
            }
        },
        render() {
            // eslint-disable-next-line react/no-this-in-sfc
            const componentProps = omit(this.props, 'onClickOutside');

            return <Component {...componentProps} />;
        },
    });
};

export default (Component) => {
    // support the @decorator pattern with ES6 classes
    if (!Component) {
        return (ComponentToDecorate) =>
            addClickOutsideListener(ComponentToDecorate);
    }

    return addClickOutsideListener(Component);
};
