
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Working With Ticket Classes and Ticket Groups`}</MDXTag>
<MDXTag name="p" components={components}>{`A Ticket Class is a ticket type. Usually, multiple different types of tickets
for an Event are available. There are three Eventbrite Ticket Classes:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Free.`}</MDXTag>{` No cost or currency. An Event with only free Ticket Classes is a
free Event and doesn't require payout information.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Paid.`}</MDXTag>{` Ticket Classes with an associated cost in the Event's currency.
Currency is specified in the Event object and is duplicated in the Ticket
Class object as the return value of a currency type.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Donation.`}</MDXTag>{` Order owner is prompted to enter at their own discretion an
amount to donate during checkout. There is no fixed cost of donation.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Ticket Class price can be communicated in a way that either includes fees and
taxes in the total displayed price or shows these costs split out from the base
ticket price. The User determines how costs are communicated.`}</MDXTag>
<MDXTag name="p" components={components}>{`Ticket Classes can be grouped using the Ticket Group object, most commonly to
apply a Discount to multiple Ticket Classes.`}</MDXTag>
<MDXTag name="p" components={components}>{`For information on creating a Ticket Class, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/create-events"}}>{`Creating Events`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To work with Ticket Classes and Ticket Groups, you must authenticate your access
to the Eventbrite API. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Information on an Event's Ticket Class`}</MDXTag>
<MDXTag name="p" components={components}>{`If you want information on an Event's Ticket Classes, use the expansion
`}<MDXTag name="inlineCode" components={components} parentName="p">{`expand=ticket_classes`}</MDXTag>{` parameter by appending it to the GET request URL.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>{` `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbriteapi.com/v3/events/EVENT_ID/?expand=ticket_classes"}}>{`https://www.eventbriteapi.com/v3/events/EVENT_ID/?expand=ticket_classes`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating Ticket Classes in Tiered Events`}</MDXTag>
<MDXTag name="p" components={components}>{`Tiered Events are events which use Eventbrite’s new inventory management system,
which allows the creation of inventory tiers (“tiers”). You can think of the
tiers as an intermediate layer between event-level capacity enforcement and
ticket-level capacity.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Tiered Event example:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Name`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Object Type`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Sunset Fest`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}<MDXTag name="strong" components={components} parentName="td">{`General Admission (200 capacity)`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Inventory Tier`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` Presale (100 capacity)`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Class`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` Main Sale (not limited)`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Class`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` Day-of Sale (not limited)`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Class`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}<MDXTag name="strong" components={components} parentName="td">{`VIP (50 capacity)`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Inventory Tier`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` Presale( 30 capacity)`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Inventory Ticket Class`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` Main Sale (not limited)`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Class`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}<MDXTag name="strong" components={components} parentName="td">{`Parking (Add-on, 40 capacity)`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Inventory Tier, non-admission`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` Parking (not limited)`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Class`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`In this event, the tickets under General Admission, in total, can never exceed
200 capacity, and may go on sale at different times, have different prices,
purchase restrictions, etc. For Ticket Classes which have a lower capacity than
their tier (such as “Presale” or “VIP Presale”), the inventory check first
confirms that there is capacity at the ticket level, then also confirms that
there’s availability at the tier level. Tickets which are “not limited” will
only sell up to the capacity of their tier. Most ticket classes do not have a
capacity restriction.`}</MDXTag>
<MDXTag name="p" components={components}>{`Tiered events cannot enforce an event capacity different from the sum of their
admission tier capacities. This restriction was introduced because General
Admission Holds (entirely different concept) could interact badly with this
restriction.`}</MDXTag>
<MDXTag name="p" components={components}>{`Lastly, Add-ons are tiers with the additional property of “does not count
towards event capacity”. In this example “Parking” is a tier of 40 capacity,
with a Ticket Class (also called Parking). Events which do not have tiers at the
admission level (known in-product as “General Admission Sections”) can still
have add-ons, and add-ons are tiers with at least one ticket class.`}</MDXTag>
<MDXTag name="h2" components={components}>{`How to create Ticket Classes for Tiered Events`}</MDXTag>
<MDXTag name="h3" components={components}>{`1. Determine if the Event is tiered`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbriteapi.com/v3/events/EVENT_ID/?expand=basic_inventory_info"}}>{`https://www.eventbriteapi.com/v3/events/EVENT_ID/?expand=basic_inventory_info`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The basic_inventory_info expansion will retrieve an event’s inventory types. If
the basic_inventory_info.has_admission_tiers: true, ticket classes must be
created with an inventory tier. To do this, find or create an inventory tier and
assign the inventory_tier_id when creating the ticket class.`}</MDXTag>
<MDXTag name="h3" components={components}>{`2. List Inventory Tiers`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>{` `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbriteapi.com//v3/events/EVENT_ID/inventory_tiers/"}}>{`https://www.eventbriteapi.com//v3/events/EVENT_ID/inventory_tiers/`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`3. Create new Inventory Tier (Optional)`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`POST`}</MDXTag>{` `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbriteapi.com/v3/events/EVENT_ID/inventory_tiers/"}}>{`https://www.eventbriteapi.com/v3/events/EVENT_ID/inventory_tiers/`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`For more information on inventory tiers, refer the
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/platform/api#/reference/inventory-tiers/inventory-tiers/create-an-inventory-tier"}}>{`Create Inventory Tiers`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`Example Request Body:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`{
  "inventory_tier": {
    "name": "VIP",
    "count_against_event_capacity": true,
    "quantity_total": "10"
  }
}
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`4. Create Ticket Class with an Inventory Tier`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`POST`}</MDXTag>{` `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/api/v3/events/EVENT_ID/ticket_classes/"}}>{`https://www.eventbrite.com/api/v3/events/EVENT_ID/ticket_classes/`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Example Request Body:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`  {
    "ticket_class": {
      "name": "Booth",
      "free": false,
      "donation": false,
      "quantity_total": "",
      "minimum_quantity": "1",
      "maximum_quantity": "10",
      "cost": "USD,2000",
      "hidden": false,
      "auto_hide": false,
      "auto_hide_before": "",
      "auto_hide_after": "",
      "sales_channels": ["online","atd"],
      "hide_sale_dates": false,
      "delivery_methods": ["electronic"],
      "inventory_tier_id": "520007",
      "include_fee": false,
      "sales_start": "2019-12-09T08:00:00Z",
      "sales_end": "2021-02-01T03:00:00Z"
    }
  }
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Determining Remaining (Unsold) Ticket for an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`You can determine the remaining tickets for an Event's Ticket Class from the
information returned on that Ticket Class object. To do so, note the amount
identified in the `}<MDXTag name="inlineCode" components={components} parentName="p">{`quantity_total`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`quantity_sold`}</MDXTag>{` fields, and then use the
equation `}<MDXTag name="inlineCode" components={components} parentName="p">{`quantity_total`}</MDXTag>{` - `}<MDXTag name="inlineCode" components={components} parentName="p">{`quantity_sold`}</MDXTag>{` = remaining tickets for this Ticket
Class.`}</MDXTag>
<MDXTag name="p" components={components}>{`Using the example above, the remaining tickets for the Event is 100 - 23 = 77
tickets unsold.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Using Ticket Groups`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the Ticket Group object to group multiple Ticket Classes. This allows you
to, for example, apply a Discount to multiple Ticket Classes.`}</MDXTag>
<MDXTag name="p" components={components}>{`To create a Ticket Group, use the POST request URL. Since Ticket Groups are
created under an Organization, you must have an Organization ID to create a
Ticket Group.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Note: The `}<MDXTag name="inlineCode" components={components} parentName="p">{`event_ticket_ids`}</MDXTag>{` is following a specific format of
`}<MDXTag name="inlineCode" components={components} parentName="p">{`"{\"event_id\": [\"ticket_id_1\", \"ticket_class_id_2\"]}`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`POST`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbriteapi.com/v3/organizations/ORGANIZATION_ID/ticket_groups/"}}>{`https://www.eventbriteapi.com/v3/organizations/ORGANIZATION_ID/ticket_groups/`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Example Request Body:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`{
  "ticket_group":{
    "name": "Ticket Group Example",
    "status": "live",
    "event_ticket_ids": "{\"12345\": [\"14123\", \"123123\"]}"
  }
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The following fields are required when creating a Ticket Group:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Group name. A name containing more than 20 characters is automatically truncated.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`status`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Group status. Can be `}<MDXTag name="inlineCode" components={components} parentName="td">{`transfer`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="td">{`live`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="td">{`deleted`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="td">{`archived`}</MDXTag>{`. By default, the status is `}<MDXTag name="inlineCode" components={components} parentName="td">{`live`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`event_ticket_ids`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`dict`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Dictionary showing the Ticket Class IDs associated with a specific Event ID. Displayed in the form `}<MDXTag name="inlineCode" components={components} parentName="td">{`"{\"event_id\": [\"ticket_id_1\", \"ticket_class_id_2\"]}`}</MDXTag></MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Adding Ticket Classes to a Ticket Group`}</MDXTag>
<MDXTag name="p" components={components}>{`Once you have a Ticket Group, you add Ticket Classes to many Ticket Groups to
take advantage of the group object. Use the POST request URL to add a Ticket
Class to multiple Ticket Groups. You need to have the ID of the Organization to
which the Ticket Group belongs, the Event ID of the Ticket Class, the ID of the
Ticket Class and the IDs of the Ticket Groups.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`POST`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbriteapi.com/v3/organizations/ORGANIZATION_ID/events/EVENT_ID/ticket_classes/TICKET_CLASS_ID/ticket_groups/"}}>{`https://www.eventbriteapi.com/v3/organizations/ORGANIZATION_ID/events/EVENT_ID/ticket_classes/TICKET_CLASS_ID/ticket_groups/`}</MDXTag></MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "ticket_group_ids": ["11", "20"]
}
`}</MDXTag></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Getting Information on an Event',
            link: '/platform/docs/events',
        },
        {
            title: 'Including an Image with Your Event Listing',
            link: '/platform/docs/image-upload',
        },
    ]}
/>
           </MDXTag>
  }
}
  