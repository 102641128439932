import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './heroBanner.scss';

export default class HeroBanner extends PureComponent {
    static propTypes = {
        /*
        Content to display inside hero
        */
        children: PropTypes.node,
        /*
        Min height for the hero
        */
        minHeight: PropTypes.string,
        /*
        Path to background image, e.g. 'static/images/essential.png'
        */
        bgImgPath: PropTypes.string,
        /*
        Position of background image
        */
        bgPosition: PropTypes.string,
        /*
        Size of background image
        */
        bgSize: PropTypes.string,
    };

    render() {
        const {
            children,
            minHeight,
            bgImgPath,
            bgPosition,
            bgSize,
        } = this.props;

        let style = {};

        if (bgImgPath) {
            style = {
                backgroundImage: `url(${bgImgPath})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: bgPosition,
                backgroundSize: bgSize,
            };
            if (minHeight) {
                style.minHeight = minHeight;
            }
        }

        return (
            <div className="hero-banner eds-l-pad-hor-10 eds-l-sn-pad-hor-5 eds-l-sm-pad-hor-5 eds-l-sw-pad-hor-5">
                <div
                    className="hero-banner__image marketing-container eds-g-grid eds-l-pad-top-20 eds-l-sn-pad-top-10 eds-l-sm-pad-top-10 eds-l-sw-pad-top-10"
                    style={style}
                >
                    <div className="eds-g-group">
                        <div className="eds-g-cell eds-g-cell-12-12 hero-banner__content">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
