import { connect } from 'react-redux';

import ApiKeys from '../pages/apiKeys/ApiKeys';
import { setApiKey as setApiKeyAction } from '../actions/apiKey';

const _mapStateToProps = ({ app, apiKeyState }) => ({
    isLoading: app.isLoading,
    userData: app.userData,
    apiKey: apiKeyState.apiKey,
});

const _mapDispatchToProps = {
    setApiKey: setApiKeyAction,
};

export default connect(_mapStateToProps, _mapDispatchToProps)(ApiKeys);
