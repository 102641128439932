import React from 'react';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

const makeError = (propName, componentName, message) =>
    new Error(
        `Invalid prop '${propName}' supplied to '${componentName}': ${message}`,
    );

/**
 * React's proptypes are pretty great, but we'll need a couple of our own. Here's where they go.
 */

export const positiveIntegerPropType = (props, propName, componentName) => {
    try {
        const value = parseInt(props[propName], 10);

        // compare the parsed integer as a string to the original as a string to weed out cases
        // where we only parse part of the original string or accidentally convert floats to ints
        if (props[propName].toString() !== value.toString() || value < 0) {
            const err = 'failed validation';

            throw err;
        }
    } catch (exception) {
        return makeError(
            propName,
            componentName,
            `'${propName}' must be a positive integer.`,
        );
    }
    return null;
};

/**
 * Note: Previously both of these used Moment to validate that a object was a date or time format.
 * Unfortunately this caused almost every EDS component to implicitly import the entire moment library
 * which is very large. Replacing this with a object check for now.
 */
export const datePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
]);
export const timePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
]);

export const htmlPropType = (props, propName, componentName) => {
    if (props[propName] && !isObject(props[propName])) {
        return makeError(
            propName,
            componentName,
            `${propName} must be a valid HTMLElement`,
        );
    }

    if (props[propName] && !('nodeType' in props[propName])) {
        return makeError(
            propName,
            componentName,
            `${propName} must be a valid HTMLElement`,
        );
    }

    return null;
};

/*
 * Pass in a maximum char count and it returns a PropType that enforces the given max
 * on a string.
 *
 * @param {Int} The number of chars this string should allow
 */
export const makeMaxCharCountPropType = (maxCharCount) => (
    props,
    propName,
    componentName,
) => {
    if (typeof props[propName] !== 'string') {
        return makeError(
            propName,
            componentName,
            `${propName} must be of type String`,
        );
    }

    if (props[propName].length > maxCharCount) {
        return makeError(
            propName,
            componentName,
            `${propName} must be less than ${maxCharCount} characters.`,
        );
    }

    return null;
};

/*
 * Given a component instance reference, returns any props not defined in its propTypes
 * @param {React.Component} a reference to the component class
 * @param {object} the component props currently being rendered
 */
export const getAdditionalProps = (componentInstance) =>
    omit(
        componentInstance.props,
        ...Object.keys(componentInstance.constructor.propTypes),
    );

/**
 * Wraps a given propType function to first check that the specified property is *not* undefined.
 *
 * @param propTypeCheck [function]
 * @return [function]

 */
const makeIsRequired = (propTypeCheck) => (props, propName, componentName) => {
    if (props[propName] === undefined) {
        return makeError(propName, componentName, `${propName} is required`);
    }
    return propTypeCheck(props, propName, componentName);
};

/**
 * A helper function to wrap around our custom propType functions.
 *
 * This adds an `isRequired` property that first confirms the value is not undefined then runs the propTypeCheck.
 *
 * To use this effectively we'll give a quick example:
 *
 *      // this is the type check for the foo proptype, which is only valid if it is the string `foo`.
 *      const fooPropTypeCheck = (props, propName, componentName) => {
 *
 *          // first we'll see if the value is here
 *          if (props[propName] === undefined) {
 *              // it's not here, for this basic propTypeCheck treat missing as valid
 *              return;
 *          }
 *
 *          // if we get here, we have a value. let's check that it's valid
 *          if (props[propName] !== 'foo') {
 *              // looks like it's invalid. let's return an error.
 *              return makeError(propName, componentName, 'Must be `foo`');
 *          }
 *      }
 *      // now this is actually a valid propType function that can be used for optional fields.
 *
 *      // but what about required fields? That's where this makePropType function comes in handy.
 *      export const fooPropType = makePropType(fooPropTypeCheck);
 *      // this is now the same function as above but it now has an `isRequired` property that first confirms
 *      // the value is not undefined, then it runs the given propTypeCheck.
 *
 * @param propTypeCheck [function]
 */
export const makePropType = (propTypeCheck) => {
    // eslint-disable-next-line no-param-reassign
    propTypeCheck.isRequired = makeIsRequired(propTypeCheck);
    return propTypeCheck;
};

const COMPONENT_FUNCTION_PATTERN = /\.createElement\(/;

/**
 * Defaults to optional but provides `positiveIntegerPropType.isRequired` as well.
 */
export const componentPropType = makePropType(
    (props, propName, componentName) => {
        const propValue = props[propName];

        if (propValue === undefined) {
            // it's optional so undefined is okay
            return;
        }
        if (React.Component.isPrototypeOf(propValue)) {
            // an object that extends React.Component it's okay
            return;
        } else if (
            typeof propValue === 'function' &&
            propValue.toString().search(COMPONENT_FUNCTION_PATTERN) !== -1
        ) {
            // it's a function that can return a react element, it's okay
            return;
        }

        // we didn't satisfy any of the earlier options so it's not okay
        // eslint-disable-next-line consistent-return
        return makeError(
            propName,
            componentName,
            `${propName} must extend React.Component. instead got: ${propValue}`,
        );
    },
);
