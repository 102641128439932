import React from 'react';
import PropTypes from 'prop-types';
import { gettext } from '@eventbrite/i18n';

import {
    SIZE_NAMES,
    STYLES,
    STYLE_GRADIENT,
    STYLE_PROGRESS,
    TYPES,
    STYLE_CONFIG,
    CIRCULAR_SIZES,
    CIRCULAR_DETERMINATE_VIEWBOX,
    CIRCULAR_INDETERMINATE_VIEWBOX,
    TYPE_INDETERMINATE,
    TYPE_DETERMINATE,
} from './constants';

import { getStyleByProgress } from './utils';

import './circular.scss';

const Indeterminate = ({
    containerStyle,
    gradientId,
    stopColor1,
    stopColor2,
    stroke,
    strokeWidth,
}) => (
    <svg
        className="eds-progress-indicator--circular eds-fx--rotate"
        viewBox={CIRCULAR_INDETERMINATE_VIEWBOX}
        style={containerStyle}
        aria-valuetext={gettext('In progress')}
    >
        <defs>
            <linearGradient id={gradientId}>
                <stop offset="0%" stopColor={stopColor1} />
                <stop offset="50%" stopColor={stopColor1} />
                <stop offset="100%" stopColor={stopColor2} stopOpacity="0" />
            </linearGradient>
        </defs>
        <path
            d="M93.5,50C93.5,74,74,93.5,50,93.5S6.5,74,6.5,50S26,6.5,50,6.5"
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            shapeRendering="geometricPrecision"
            fill="none"
        />
    </svg>
);

// Calculate the length of the svg path (stroke) based on the progress
// Then return a formatted stroke-dasharray
const _getProgressDashArray = (radius, progress) => {
    // C = 2π * r - Thanks Archimedes of Syracuse!
    const circumference = 2 * Math.PI * radius;
    const portion = circumference / 100;
    const progressPortion = progress * portion;

    return `${progressPortion},${circumference}`;
};

const Determinate = ({
    containerStyle,
    style,
    gradientId,
    stopColor1,
    stopColor2,
    railColor,
    stroke,
    strokeWidth,
    progress,
}) => {
    const radius = 50;
    const center = 52;

    let strokeStyle = stopColor1;
    const railStrokeWidth = strokeWidth - 1;
    const strokeDasharray = _getProgressDashArray(radius, progress);

    // Use a linear gradient stroke
    if (style === STYLE_GRADIENT) {
        strokeStyle = stroke;
    }

    return (
        <svg
            className="eds-progress-indicator--circular-determinate"
            viewBox={CIRCULAR_DETERMINATE_VIEWBOX}
            style={containerStyle}
            shapeRendering="geometricPrecision"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
        >
            <defs>
                <linearGradient id={gradientId}>
                    <stop offset="0%" stopColor={stopColor1} />
                    <stop offset="100%" stopColor={stopColor2} />
                </linearGradient>
            </defs>
            <circle
                className="eds-progress-indicator--circular-determinate__rail"
                cx={center}
                cy={center}
                r={radius}
                stroke={railColor}
                strokeWidth={railStrokeWidth}
                fill="none"
            />
            <circle
                className="eds-progress-indicator--circular-determinate__circle"
                cx={center}
                cy={center}
                r={radius}
                stroke={strokeStyle}
                strokeWidth={strokeWidth}
                strokeDasharray={strokeDasharray}
                strokeLinecap="round"
                fill="none"
            />
        </svg>
    );
};

const PROGRESS_INDICATOR_TYPE_MAP = {
    [TYPE_INDETERMINATE]: Indeterminate,
    [TYPE_DETERMINATE]: Determinate,
};

export default class Circular extends React.PureComponent {
    static propTypes = {
        /**
         * size and stroke of the loader
         * (small-thin, small-chunky, large-thin, large-chunky)
         */
        size: PropTypes.oneOf(SIZE_NAMES),

        /**
         * Type of indicator for the loader (indeterminate | determinate)
         */
        type: PropTypes.oneOf(TYPES),

        /**
         * Color of the loader (dark | light | gradient)
         */
        style: PropTypes.oneOf(STYLES),

        /**
         * Progress bar if the chosen Type is determinate
         */
        progress: PropTypes.number,
    };

    render() {
        const { size, style, type, progress } = this.props;
        const { stopColor1, stopColor2, railColor } =
            style === STYLE_PROGRESS
                ? getStyleByProgress(progress)
                : STYLE_CONFIG[style];
        const { diameter, strokeWidth } = CIRCULAR_SIZES[size];
        const containerStyle = {
            width: diameter,
            height: diameter,
        };
        const gradientId = `stroke-${size}-${style}-${type}`;
        const stroke = `url(#${gradientId})`;

        const Component = PROGRESS_INDICATOR_TYPE_MAP[type];

        return (
            <Component
                containerStyle={containerStyle}
                style={style}
                gradientId={gradientId}
                stopColor1={stopColor1}
                stopColor2={stopColor2}
                railColor={railColor}
                stroke={stroke}
                strokeWidth={strokeWidth}
                progress={progress}
            />
        );
    }
}
