import React, { PureComponent } from 'react';

import { APIARYEMBEDPARAMS } from '../constants/apiReference';
import { LoadingOverlay } from '@eb/eds-loading-overlay';
import { getWindowObject, HAS_WINDOW } from '@eb/feature-detection';

export default class ApiaryEmbed extends PureComponent {
    state = {
        isLoading: true,
    };

    componentDidMount() {
        if (!getWindowObject('Apiary')) {
            const script = document.createElement('script');

            script.type = 'text/javascript';
            script.src = 'https://api.apiary.io/seeds/embed.js';
            const headScript = document.getElementsByTagName('script')[0];

            headScript.parentNode.insertBefore(script, headScript);
            script.addEventListener('load', () => {
                this.onLoad();
            });
        } else {
            this.onLoad();
        }
    }

    componentWillUnmount() {
        if (this.embed !== null) {
            this.embed.destroy();
        }
    }

    embed = null;

    onLoad = () => {
        if (HAS_WINDOW) {
            this.embed = new window.Apiary.Embed(APIARYEMBEDPARAMS);

            this.embed.onReady(() => {
                setTimeout(() => {
                    // Slight delay so the content styling flash isn't as abrupt
                    this.setState({ isLoading: false });
                }, 500);
            });

            this.embed.messageHub.onDocumentReady(() => {
                // Check for a src on the iframe.
                // If there is a src, that means the apiary docs are loading.
                // If there isn't a src, it should mean that the embed isn't compatible with the device
                const embedIFrame = document.querySelector(
                    `${APIARYEMBEDPARAMS.element} > iframe`,
                );

                if (!embedIFrame.src) {
                    this.setState({ isLoading: false });
                }
            });
        }
    };

    render() {
        const { isLoading } = this.state;

        return (
            <div
                id="apiary-embed"
                style={{ height: 'calc(100vh - 60px - 54px)', width: '100vw' }}
            >
                <LoadingOverlay size="large-chunky" isShown={isLoading} />
            </div>
        );
    }
}
