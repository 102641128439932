import React from 'react';
import PropTypes from 'prop-types';
import { HotKeys } from 'react-hotkeys';

import { TextListItem } from '@eb/eds-text-list-item';
import { Divider } from '@eb/eds-divider';
import { TEXT_ITEMS_PROP_TYPE } from './constants';

import {
    TABBABLE_INDEX,
    UNTABBABLE_INDEX,
    MOVE_TO_NEXT,
    MOVE_TO_PREV,
    MOVE_TO_FIRST,
    MOVE_TO_LAST,
    ACTIVATE_ITEM,
} from '@eb/eds-hot-keys';
import {
    focusNewPosition,
    isToggledHelper,
    filterNonEmptyItems,
} from '@eb/eds-hot-keys';
import { ACTION_KEY_MAP } from './hotKeys';

// polyfill for Element.closest
import '@eventbrite/eds-utils';

import './textList.scss';

const STATIC_FOCUS_POSITION_ON_HOVER = -2;

class TextList extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        /**
         * Array of list item data.
         */
        items: TEXT_ITEMS_PROP_TYPE.isRequired,
        /**
         * The `value` in `items` that should be marked "selected"
         */
        selectedItem: PropTypes.string,
        /**
         * Callback function that will be called when a list item is selected
         */
        onItemSelect: PropTypes.func,
        /**
         * Callback function that will be called when a list item is tentatively selected
         */
        onItemTentativeSelect: PropTypes.func,
        /**
         * Should this list try to scroll to the selected item?
         */
        autoscrollToSelected: PropTypes.bool,
        /**
         * A valid CSS selector of the parent scroll container
         * This is required if autoscrollToSelected prop is true
         */
        scrollContainerSelector: PropTypes.string,
        /**
         * Default position (zero-based) within item list - received from DropdownMenu/AutocompleteField
         */
        defaultFocusPosition: PropTypes.number,
        /**
         * Whether the navigation for items should be un-wrapped (i.e. no first to last, last to first)
         */
        preventFocusWrap: PropTypes.bool,
        /**
         * Callback function that will be called when TextList's parent container should receive focus
         */
        onParentFocus: PropTypes.func,
        /**
         * Whether or not to set focus to the first text list item
         */
        shouldFocusFirstItem: PropTypes.bool,
        /**
         * A keyboard action coming from the parent component to help dictate which item in the list
         * should be focused by default (if any)
         */
        parentKeyboardAction: PropTypes.string,
        /**
         * Whether or not to update text list focusPosition state on
         * item hover tentative select.
         */
        staticFocusOnTentativeSelect: PropTypes.bool,
        /**
         * Ref function for interacting with the unordered list element from outside of the component.
         */
        // eslint-disable-next-line no-undef
        innerRef: PropTypes.func,
        /**
         * Whether or not the item should use a progress indicator while the image is loading
         */
        useProgressIndicator: PropTypes.bool,
    };

    static defaultProps = {
        autoscrollToSelected: false,
        preventFocusWrap: false,
        shouldFocusFirstItem: false,
        useProgressIndicator: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            focusPosition: props.defaultFocusPosition,
        };
    }

    componentDidMount() {
        const { _selectedItemElement } = this;
        const { autoscrollToSelected, scrollContainerSelector } = this.props;
        const shouldScroll = autoscrollToSelected && _selectedItemElement;

        if (shouldScroll) {
            this._scrollIntoView(_selectedItemElement, scrollContainerSelector);
        }

        if (this._focusedItemElement) {
            this._focusedItemElement.focus();
        }
    }

    UNSAFE_componentWillReceiveProps({
        defaultFocusPosition,
        preventFocusWrap,
        parentKeyboardAction,
    }) {
        const { focusPosition } = this.state;
        const isToggled = isToggledHelper(
            defaultFocusPosition,
            focusPosition,
            parentKeyboardAction,
        );

        if (preventFocusWrap) {
            if (isToggled) {
                this.setState({ focusPosition: defaultFocusPosition });
            }
        }
    }

    UNSAFE_componentWillUpdate() {
        this._clearSelectedItemRef();
    }

    componentDidUpdate(prevProps) {
        const { _selectedItemElement } = this;
        const {
            autoscrollToSelected,
            scrollContainerSelector,
            selectedItem,
        } = this.props;
        const wasJustSelected = selectedItem && !prevProps.selectedItem;
        const shouldScroll =
            autoscrollToSelected && _selectedItemElement && wasJustSelected;

        if (shouldScroll) {
            this._scrollIntoView(_selectedItemElement, scrollContainerSelector);
        }

        if (this._focusedItemElement) {
            this._focusedItemElement.focus();
        }
    }

    hotKeyHandlers = {
        [MOVE_TO_NEXT]: (e) => {
            if (!this._getIsToggled()) {
                e.stopPropagation();

                this._changeFocusedTextListItem(e, MOVE_TO_NEXT);
            }
        },
        [MOVE_TO_PREV]: (e) => {
            if (this.state.focusPosition === 0 && this.props.preventFocusWrap) {
                this.props.onParentFocus();
            } else if (!this._getIsToggled()) {
                this._changeFocusedTextListItem(e, MOVE_TO_PREV);
            }
        },
        [MOVE_TO_FIRST]: (e) =>
            this._changeFocusedTextListItem(e, MOVE_TO_FIRST),
        [MOVE_TO_LAST]: (e) => this._changeFocusedTextListItem(e, MOVE_TO_LAST),
        [ACTIVATE_ITEM]: (e) => {
            e.stopPropagation();
            e.preventDefault();

            const selectedItemValue = this._focusedItemElement.id;

            if (this.props.onItemSelect) {
                this.props.onItemSelect(
                    selectedItemValue,
                    this.state.focusPosition,
                    e,
                );
            }
        },
    };

    _getIsToggled() {
        const { defaultFocusPosition, parentKeyboardAction } = this.props;
        const { focusPosition } = this.state;

        return isToggledHelper(
            defaultFocusPosition,
            focusPosition,
            parentKeyboardAction,
        );
    }

    _changeFocusedTextListItem(e, direction) {
        const { items, preventFocusWrap } = this.props;
        const { focusPosition } = this.state;
        const nonEmptyItems = filterNonEmptyItems(items);
        const boundFocusNewPosition = focusNewPosition.bind(
            null,
            nonEmptyItems,
            focusPosition,
            e,
            preventFocusWrap,
        );

        this.setState(boundFocusNewPosition(direction));
    }

    _scrollIntoView(element, scrollContainerSelector) {
        if (element) {
            const scrollContainer = element.closest(scrollContainerSelector);

            if (scrollContainer) {
                scrollContainer.scrollTop = element.offsetTop;
            }
        }
    }

    _handleClick(value, index, syntheticEvent) {
        const { onItemSelect } = this.props;

        if (onItemSelect) {
            onItemSelect(value, index, syntheticEvent);
        }
    }

    _handleItemContentFocus(value, content, index) {
        this._handleItemTentativeSelect(value, content, index);
    }

    _clearSelectedItemRef() {
        this._selectedItemElement = null;
    }

    _setListItemRef(isSelected, isTentativelySelected, element) {
        if (isSelected) {
            this._selectedItemElement = element;
        }

        if (isTentativelySelected) {
            this._focusedItemElement = element;
        }
    }

    // NOTE: Merging keyboard event with mouse events so that in the case of a mouseOver,
    // the focusPosition is changed from the one determined from keyboard navigation
    // to the one determined by the mouseOver. This removes multiple highlights within
    // the same list.
    _handleItemTentativeSelect(value, content, focusPosition) {
        const { onItemTentativeSelect } = this.props;

        if (value && focusPosition !== -1 && onItemTentativeSelect) {
            onItemTentativeSelect(value, content);
        }

        this.setState({ focusPosition });
    }

    _handleItemTentativeSelectFocus = ({ value, content, index }) => {
        this._handleItemTentativeSelect(value, content, index);
    };

    _handleItemTentativeSelectHover = ({ value, content, index }) => {
        const { staticFocusOnTentativeSelect } = this.props;

        let focusPosition = index;

        if (focusPosition !== -1 && staticFocusOnTentativeSelect) {
            /* Setting focus position explicitly here to ensure that
            when hovering, initial items do not incorrectly hold
            default focused appearance */
            focusPosition = STATIC_FOCUS_POSITION_ON_HOVER;
        }

        this._handleItemTentativeSelect(value, content, focusPosition);
    };

    _getListItems() {
        const {
            items,
            selectedItem,
            shouldFocusFirstItem,
            defaultFocusPosition,
            useProgressIndicator: progressIndicatorComponent,
        } = this.props;
        const { focusPosition } = this.state;

        // eslint-disable-next-line complexity
        return items.map(
            (
                {
                    path,
                    value,
                    content,
                    renderContainer,
                    iconType,
                    iconTypes,
                    iconSize,
                    imageAlt,
                    imageUrl,
                    iconColor,
                    buttonType,
                    isDisabled,
                    isSquareImage,
                    tertiaryContent,
                    secondaryContent,
                    header,
                    onItemTentativeSelectKeyUp,
                    dividerVertSpacing = 0,
                    showDivider = true,
                    useProgressIndicator = progressIndicatorComponent,
                },
                index,
            ) => {
                const isSelected = value === selectedItem;
                const onSelect = this._handleClick.bind(this, value, index);
                const onItemContentFocus = this._handleItemContentFocus.bind(
                    this,
                    value,
                    content,
                    index,
                );
                let tentativeSelectHandlers = {};
                let isTentativelySelected;
                let isFocusable;
                let tabIndex;
                const isLastItem = index + 1 === items.length;
                const showingAsset =
                    iconType || iconTypes || imageUrl || renderContainer;
                let divider = null;

                if (showDivider && showingAsset && !isLastItem) {
                    divider = (
                        <div className={`eds-l-pad-vert-${dividerVertSpacing}`}>
                            <Divider />
                        </div>
                    );
                }

                let headerComponent = null;

                if (header) {
                    headerComponent = (
                        <h3
                            className="eds-l-pad-hor-6 eds-text-color--ui-800 eds-l-pad-vert-1 eds-text-bl eds-text-weight--heavy"
                            data-spec="eds-text-list-item-header"
                        >
                            {header}
                        </h3>
                    );
                }

                isTentativelySelected = index === focusPosition;
                const tentativeSelectedData = { value, content, index };
                const clearTentativeSelectedData = {
                    value: null,
                    content: null,
                    index: -1,
                };

                // we only want to have focus-management related props when there is a parent container
                // that passes in defaultFocusPosition to TextList
                if (defaultFocusPosition !== undefined) {
                    isFocusable = false;
                    tabIndex = isTentativelySelected
                        ? TABBABLE_INDEX
                        : UNTABBABLE_INDEX;
                    tentativeSelectHandlers = {
                        onMouseOver: () =>
                            this._handleItemTentativeSelectHover(
                                tentativeSelectedData,
                            ),
                        onMouseOut: () =>
                            this._handleItemTentativeSelectHover(
                                clearTentativeSelectedData,
                            ),
                        onFocus: () =>
                            this._handleItemTentativeSelectFocus(
                                tentativeSelectedData,
                            ),
                        onBlur: () =>
                            this._handleItemTentativeSelectFocus(
                                clearTentativeSelectedData,
                            ),
                        onKeyUp: (e) => {
                            onItemTentativeSelectKeyUp &&
                                onItemTentativeSelectKeyUp(e);
                        },
                    };
                } else {
                    tentativeSelectHandlers = {
                        onBlur: () =>
                            this._handleItemTentativeSelectFocus(
                                clearTentativeSelectedData,
                            ),
                    };
                }

                const storeRef = this._setListItemRef.bind(
                    this,
                    isSelected,
                    isTentativelySelected,
                );

                // If shouldFocusFirstItem, highlights the first option when the
                // user is in parent container (i.e. TextInput of AutocompleteField)
                if (
                    shouldFocusFirstItem &&
                    focusPosition === -1 &&
                    index === 0
                ) {
                    isTentativelySelected = true;
                }

                // **should** be ok to disable since we're using hotkeys to provide a11y support
                // (and we have tests for it)
                /* eslint-disable jsx-a11y/click-events-have-key-events */
                return (
                    <li
                        ref={storeRef}
                        className="eds-text-list__item"
                        key={value}
                        id={value}
                        tabIndex={tabIndex}
                        role="menuitem"
                        onClick={onSelect}
                        data-spec="eds-text-list-item"
                        {...tentativeSelectHandlers}
                    >
                        {headerComponent}
                        <TextListItem
                            buttonType={buttonType}
                            render={renderContainer}
                            content={content || value}
                            iconColor={iconColor}
                            iconType={iconType}
                            iconTypes={iconTypes}
                            iconSize={iconSize}
                            imageAlt={imageAlt}
                            imageUrl={imageUrl}
                            isDisabled={isDisabled}
                            isSelected={isSelected}
                            isSquareImage={isSquareImage}
                            isTentativelySelected={isTentativelySelected}
                            isFocusable={isFocusable}
                            onSelect={onSelect}
                            onItemContentFocus={onItemContentFocus}
                            path={path}
                            secondaryContent={secondaryContent}
                            tertiaryContent={tertiaryContent}
                            useProgressIndicator={useProgressIndicator}
                        />
                        {divider}
                    </li>
                );
                /* eslint-enable jsx-a11y/click-events-have-key-events */
            },
        );
    }

    render() {
        const { id, innerRef } = this.props;

        return (
            <HotKeys keyMap={ACTION_KEY_MAP} handlers={this.hotKeyHandlers}>
                <ul
                    id={id}
                    data-spec="text-list"
                    className="eds-text-list"
                    role="menu"
                    ref={innerRef}
                >
                    {this._getListItems()}
                </ul>
            </HotKeys>
        );
    }
}

export default TextList;
