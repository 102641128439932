
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Creating an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`You can use the Eventbrite website to quickly and easily create a new Event.
However, you can use the Eventbrite API if, for example, you want to create an
Event within a workflow outside of Eventbrite such as in your CRM or CMS.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are two ways to create Events using the Eventbrite API:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Create an Event from scratch by defining an new Event object.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Copy an Event already existing in your account and then update it.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Once you have created a new Event, you must create at least one Ticket Class
object for the Event, and then publish the Event so that Attendees can access
tickets. See below for information on both of these actions.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To create an Event, you must authenticate your access to the Eventbrite API. To
learn how, refer to `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating an Event from Scratch`}</MDXTag>
<MDXTag name="p" components={components}>{`Every Event is created within an Organization. To create a new Event from
scratch, you must have the ID of an Organization to which you (and the new Event
should) belong. If you are on Eventbrite Music you can belong to multiple
Organizations. For information on finding your Organization ID, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/organizations"}}>{`Organizations`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`Create a new Event by using the call`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/organizations/{organization_id}/events/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
        "event": {
            "name": {
                "html": "My New Event"
            },
            "start":{
                "timezone": "America/Los_Angeles",
                "utc": "2019-12-01T02:00:00Z"
            },
            "end":{
                "timezone": "America/Los_Angeles",
                "utc": "2019-12-01T05:00:00Z"
            },
            "currency": "USD"
        }
    }'
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The following fields are required when creating an Event, and should be appended
to the end of the POST request URL:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`multipart-text`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event name (title), made up of two fields.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`start`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime-tz`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event start date and time (must be a future date) made up of two fields.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`end`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime-tz`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event end date and time (must be a future date after the start date) made up of two fields.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`currency`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://en.wikipedia.org/wiki/ISO_4217"}}>{`ISO 4217`}</MDXTag>{` currency code.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The `}<MDXTag name="inlineCode" components={components} parentName="p">{`multipart-text`}</MDXTag>{` data type is an object with two fields.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`html`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`(Required) HTML format.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`text`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`(Optional) Plain text.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The `}<MDXTag name="inlineCode" components={components} parentName="p">{`datetime-tz`}</MDXTag>{` data type is an object with two fields.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`timezone`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`The timezone`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`utc`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`The time relative to UTC`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`If the call is successful, the new Event object is returned. The `}<MDXTag name="inlineCode" components={components} parentName="p">{`id`}</MDXTag>{` attribute
is the unique ID of the new Event. You'll need the Event ID when using the API
to update the Event, add Ticket Classes and publish the Event, as explained
below.`}</MDXTag>
<MDXTag name="p" components={components}>{`Events created using the API call above will be standard events that occur one
time. In order to create a series of events with multiple occurrences (also
known as a "repeating event" or "recurring event"), you must first create one
event to serve as the "series parent", then add occurrences to the series
parent. For more information on how to create a series parent and add
occurrences, please refer to the
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event/create"}}>{`Create Event API`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating a New Event by Copying an Existing Event`}</MDXTag>
<MDXTag name="p" components={components}>{`To create a new Event by copying an existing Event, you need to have the ID of
the Event you want to copy. For more information, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/events"}}>{`Getting Information on an Event`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`Specify the Event ID of the Event you want to copy, as the basis for the new
Event, in the request URL. The copied Event is created under the same
Organization as the original Event.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/copy/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`If the call is successful, the copied Event object is returned with a new Event
ID (the `}<MDXTag name="inlineCode" components={components} parentName="p">{`id`}</MDXTag>{` attribute).`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Updating an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`You use the following API call, with the appropriate Event attributes of the
fields you want to update. For example, updating the Event capacity to 200.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
        "event": {
            "capacity": 200
            }
        }'
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Event Attributes`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`multipart-text`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event name.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`summary`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event summary. Limited to 140 characters.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`description`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`multipart-text`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`(`}<MDXTag name="em" components={components} parentName="td">{`DEPRECATED`}</MDXTag>{`) `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/docs/event-description"}}>{`Event description`}</MDXTag>{`. Description can be lengthy and have significant formatting.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`url`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`URL of the Event's Listing page on eventbrite.com.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`start`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime-tz`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event start date and time.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`end`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime-tz`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event end date and time.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`currency`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://en.wikipedia.org/wiki/ISO_4217"}}>{`ISO 4217`}</MDXTag>{` currency code.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`online_event`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`true = Specifies that the Event is online only (i.e. the Event does not have a Venue).`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`listed`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`true = Allows the Event to be publicly searchable on the Eventbrite website.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`shareable`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`true = Event is shareable, by including social sharing buttons for the Event to Eventbrite applications.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`invite_only`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`true = Only invitees who have received an email inviting them to the Event are able to see Eventbrite applications.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`show_remaining`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`true = Provides, to Eventbrite applications, the total number of remaining tickets for the Event.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`password`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event password used by visitors to access the details of the Event.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`capacity`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`integer`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Maximum number of tickets for the Event that can be sold to Attendees. The total capacity is calculated by the sum of the quantity_total of the Ticket Class.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating a Ticket Class`}</MDXTag>
<MDXTag name="p" components={components}>{`Regardless of the method you use to create an new Event, you must create at
least one Ticket Class (ticket type) for the new Event. For example, a Ticket
Class that is a paid, \\$10 ticket with the ticket name VIP.`}</MDXTag>
<MDXTag name="p" components={components}>{`You create a Ticket Class for an Event (identified by the Event ID) using the
following API call`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/ticket_classes/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
        "ticket_class": {
            "name": "VIP",
            "quantity_total": 100,
            "cost": "USD,1000"
            }
        }'
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`These fields are required to create a Ticket Class:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Ticket Class name.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`quantity_total`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`integer`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Number of this Ticket Class available for sale.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`free`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`(Only for free tickets) true = Ticket Class is Free.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`cost`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`(Only for paid tickets) Cost of the Ticket Class (example format: `}<MDXTag name="inlineCode" components={components} parentName="td">{`USD,1000`}</MDXTag>{`) \\$10`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Publishing an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`All new Events must be published so that Attendees can access tickets to your
Event. Use the following API call, to publish an Event.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/publish/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Creating Ticket Classes in Tiered Events',
            link: '/platform/docs/ticket-classes#tiered_events',
        },
        {
            title: 'Uploading an Image',
            link: '/platform/docs/image-upload',
        },
    ]}
/>
           </MDXTag>
  }
}
  