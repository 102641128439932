import React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { Router } from 'react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';

import analytics from './utils/analytics/analytics';
import configureStore from './store/configureStore';
import reducer from './reducers';
import { getInitialState } from './reducers/utils';
import { getHistory, getRoutes } from './routes';

const pageview = () => {
    //eslint-disable-next-line import/no-named-as-default-member
    analytics.pageview();
};

export default class App extends React.Component {
    constructor(props) {
        super(props);

        const historyManagement = getHistory();

        // creates a store w/ Redux logging defaulted in development environment
        // also adds `redux-thunk` middleware by default for async actions
        this._store = configureStore({
            reducer,
            initialState: getInitialState(props),
            middleware: [thunk, routerMiddleware(historyManagement)],
        });

        this._history = syncHistoryWithStore(historyManagement, this._store);

        // GA pageview tracking
        this._history.listen(pageview);
    }

    componentDidMount() {
        const crazyEggScript = document.createElement('script');

        crazyEggScript.type = 'text/javascript';
        crazyEggScript.src = '//script.crazyegg.com/pages/scripts/0047/7928.js';
        crazyEggScript.async = 'async';
        document.head.appendChild(crazyEggScript);
    }

    render() {
        const routes = getRoutes(this.props, this._store);

        return (
            <Provider store={this._store}>
                <Router history={this._history} routes={routes} />
            </Provider>
        );
    }
}
