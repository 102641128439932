
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Using Questions`}</MDXTag>
<MDXTag name="p" components={components}>{`Questions are used to collect information from an Attendee during the ticket
purchase checkout process. There are two types of questions in Eventbrite:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Default Questions`}</MDXTag>{`. For example first name, last name and email address.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Custom Questions`}</MDXTag>{`. Questions and answers unique to the account and/or
Event and created by the User. For example, to require the acknowledgement
of terms and conditions as part of the ticket purchase process.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Working with the Eventbrite API, you can get a list of an Event's default and
custom questions, display a list of the answers Attendees supplied to an Event's
questions, and create custom questions for an Event.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`You must first authenticate your access to the Eventbrite API before using
questions. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Information on an Event's Default Questions`}</MDXTag>
<MDXTag name="p" components={components}>{`You can get a list of default questions for an Event by using the following URL`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/canned_questions/\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This API call returns an array of Question objects as a paginated response, for
example`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "pagination": {
        "object_count": 3,
        "page_number": 1,
        "page_size": 10000,
        "page_count": 1,
        "has_more_items": false
    },
    "questions": [
        {
            "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/questions/first_name/",
            "id": "first_name",
            "question": {
                "text": "First Name",
                "html": "First Name"
            },
            "type": "text",
            "required": true,
            "choices": [],
            "ticket_classes": [],
            "group_id": "contact_information",
            "respondent": "ticket_buyer",
            "default_value": ""
        },
        {
            "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/questions/last_name/",
            "id": "last_name",
            "question": {
                "text": "Last Name",
                "html": "Last Name"
            },
            "type": "text",
            "required": true,
            "choices": [],
            "ticket_classes": [],
            "group_id": "contact_information",
            "respondent": "ticket_buyer",
            "default_value": ""
        },
        {
            "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/questions/email/",
            "id": "email",
            "question": {
                "text": "Email",
                "html": "Email"
            },
            "type": "email",
            "required": true,
            "choices": [],
            "ticket_classes": [],
            "group_id": "contact_information",
            "respondent": "ticket_buyer",
            "default_value": ""
        }
    ]
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Information on an Event's Custom Questions`}</MDXTag>
<MDXTag name="p" components={components}>{`If an Event has custom questions, you can display a list of those custom
questions using this API call`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/questions/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This API call returns an array of Question objects as a paginated response, for
example`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  "pagination": {
    ...
  },
  "questions": [
    {
      "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/questions/642123456/",
      "id": "642123456",
      "question": {
        "text": "Ticketing Terms of Service: I acknowledge that...",
        "html": "Ticketing Terms of Service: I acknowledge that..."
      },
      "type": "checkbox",
      "required": true,
      "choices": [
        {
          "answer": {
            "text": "yes",
            "html": "yes"
          },
          "id": "231231",
          "subquestion_ids": []
        }
      ],
      "ticket_classes": [],
      "respondent": "attendee"
    },
    ...
  ]
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting a List of Answers to Default and Custom Questions`}</MDXTag>
<MDXTag name="p" components={components}>{`Answers to Questions are in Attendee object. You can get a list of the answers
supplied by Event Attendees to default and custom questions using this GET
request URL`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/attendees/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The result is an array object nested in the Attendee object. For default
questions, the answers are in the 'profile' attribute; for custom questions the
answers are in the 'answers' attribute.`}</MDXTag>
<MDXTag name="p" components={components}>{`Example response of answers to default questions`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "pagination": {
        ...
    },
    "attendees": [
        {
           ...
            "profile": {
                "first_name": "Kennedy",
                "last_name": "Singleton",
                "email": "kennedy@gmail.com",
               ...
            },
            ...
        },
        ...
    ]
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Example response of an answer to a custom Question`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "pagination": {
        ...
    },
    "attendees": [
        {
           ...
             "answers": [
                {
                    "answer": "Eventbrite!",
                    "question": "What's your company?",
                    "type": "text",
                    "question_id": "123123"
                },
            ],
            ...
        },
        ...
    ]
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating Custom Questions`}</MDXTag>
<MDXTag name="p" components={components}>{`Creating a custom question for an Event requires one API request. In this
example, we are creating a required checkbox question for ticket class `}<MDXTag name="inlineCode" components={components} parentName="p">{`1234`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/questions/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
        "question": {
          "question": {
            "html": "Ticketing Terms of Service: I acknowledge that..."
          },
          "required": true,
          "type": "checkbox",
          "choices": [
            {
              "answer": {
                "html": "yes"
              }
            }
          ],
          "ticket_classes": [
            {
              "id": "1234"
            }
          ]
        }
      }'
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`When creating a custom question, you need to specify the question type and the
user required to answer a question, as explained below.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Specifying the Custom Question Type`}</MDXTag>
<MDXTag name="p" components={components}>{`Eventbrite allows you to create custom questions of various types. These
question types are `}<MDXTag name="inlineCode" components={components} parentName="p">{`checkbox`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`dropdown`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`text`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`radio`}</MDXTag>{`, or `}<MDXTag name="inlineCode" components={components} parentName="p">{`waiver`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`For the waiver question type, use the `}<MDXTag name="inlineCode" components={components} parentName="p">{`question.waiver`}</MDXTag>{` attribute to show the
wavier content.`}</MDXTag>
<MDXTag name="p" components={components}>{`The question types `}<MDXTag name="inlineCode" components={components} parentName="p">{`checkbox`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`dropdown`}</MDXTag>{`, and `}<MDXTag name="inlineCode" components={components} parentName="p">{`radio`}</MDXTag>{` are considered multiple
choice questions, meaning that the Attendee selects the answer from a set of
answer options. For these question types you need to include the
`}<MDXTag name="inlineCode" components={components} parentName="p">{`question.choices`}</MDXTag>{` attribute. The format to create a multiple choice custom
question is:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
        "question": {
          ...
          "choices":[
            {
                “answer”:{
                  “html”:“Choice goes here...”
                }
            },
            {
                “answer”:{
                  “html”:“Another choice goes here...”
                }
            }
          ],
         ...
        }
      }
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`Identifying the User Required to Answer a Question`}</MDXTag>
<MDXTag name="p" components={components}>{`Because default and custom questions can be answered either by the Order Owner
or by each Attendee, you must specify the `}<MDXTag name="inlineCode" components={components} parentName="p">{`question.respondent`}</MDXTag>{` attribute in the
Question object when creating a question as either`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Value`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`ticket_buyer`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Order owner required to answer questions once during the ticket checkout process.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`attendee`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Each Attendee required to answer questions during the checkout process.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Working with Orders',
            link: '/platform/docs/orders',
        },
        {
            title: 'Obtaining Attendee Information',
            link: '/platform/docs/attendees',
        },
    ]}
/>
           </MDXTag>
  }
}
  