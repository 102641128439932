import moment from 'moment';

/**
 * For the browser version of this util we just set the global moment
 * locale and forward it.
 * Since the code runs on the browser the global locale only affects the
 * current user as opposed on the server where the global locale might affect
 * all requests.
 */
export const momentBrowser = (function (root) {
    function localizedMoment(...rest) {
        const i18nSettings = root.__i18n__;
        const globalLocale = i18nSettings && i18nSettings.locale;

        // We're re-applying the locale globally on each instance because
        // of our code relies on this behavior from a previous revision of this
        // wrapper. If this is moved outside of localizedMoment some things
        // will start failing. Unit tests cover this though.
        if (globalLocale) {
            moment.locale(globalLocale);
        } else {
            moment.locale('en');
        }

        return moment(...rest);
    }

    // Copy static methods from the moment object
    for (const method in moment) {
        if (
            moment.hasOwnProperty(method) &&
            !localizedMoment.hasOwnProperty(method)
        ) {
            localizedMoment[method] = moment[method];
        }
    }

    return localizedMoment;
})(typeof global === 'undefined' ? window : global);

// Needed for server/browser logic
export default momentBrowser;
