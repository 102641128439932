/*
 *	This file is built via the create-content-map build script.
 *	Please do not modify this file.
 *	If you need to make a change, alter the build script in
 *	../scripts/createContentMap.js
 */

/* eslint-disable quotes */
import IntroductionMdx from '../content/1-getting-started/1-introduction.mdx';
import AuthenticationMdx from '../content/1-getting-started/2-authentication.mdx';
import ApiBasicsMdx from '../content/1-getting-started/3-api-basics.mdx';
import ApiExplorerMdx from '../content/1-getting-started/4-api-explorer.mdx';
import CreateEventsMdx from '../content/2-event-data/01-create-events.mdx';
import EventsMdx from '../content/2-event-data/02-events.mdx';
import EventDescriptionMdx from '../content/2-event-data/03-event-description.mdx';
import TicketClassesMdx from '../content/2-event-data/04-ticket-classes.mdx';
import QuestionsMdx from '../content/2-event-data/05-questions.mdx';
import OrdersMdx from '../content/2-event-data/06-orders.mdx';
import AttendeesMdx from '../content/2-event-data/07-attendees.mdx';
import ImageUploadMdx from '../content/2-event-data/08-image-upload.mdx';
import OrganizationsMdx from '../content/2-event-data/09-organizations.mdx';
import OnlineEventPageMdx from '../content/2-event-data/10-online-event-page.mdx';
import ByCategoryMdx from '../content/3-search-events/1-by-category.mdx';
import ByDateMdx from '../content/3-search-events/2-by-date.mdx';
import ByLocationMdx from '../content/3-search-events/3-by-location.mdx';
import EmbeddedCheckoutMdx from '../content/4-checkout-experience/1-embedded-checkout.mdx';
import OrderLookupMdx from '../content/4-checkout-experience/3-order-lookup.mdx';
import CancelRefundOrderMdx from '../content/4-checkout-experience/4-cancel-refund-order.mdx';
import WhatIsAppMarketplaceMdx from '../content/5-build-an-app/1-what-is-app-marketplace.mdx';
import WhatIsSpectrumDeprecatedMdx from '../content/5-build-an-app/1-what-is-spectrum-deprecated.mdx';
import AppApprovalProcessMdx from '../content/5-build-an-app/2-app-approval-process.mdx';
import AppOauthFlowMdx from '../content/5-build-an-app/3-app-oauth-flow.mdx';
import IntegrateWithEventbriteManageMdx from '../content/5-build-an-app/4-integrate-with-eventbrite-manage.mdx';
import AppProductPageMdx from '../content/5-build-an-app/5-app-product-page.mdx';
import ManagingAppReviewsMdx from '../content/5-build-an-app/6-managing-app-reviews.mdx';
import MailchimpMdx from '../content/6-apps/1-mailchimp.mdx';
import ContactSupportMdx from '../content/7-support/1-contact-support.mdx';
import ChangelogMdx from '../content/changelog.mdx';
import RateLimitsMdx from '../content/rate-limits.mdx';
import WebhooksMdx from '../content/webhooks.mdx';

export const CONTENT_MAP = {
    introduction: IntroductionMdx,
    authentication: AuthenticationMdx,
    'api-basics': ApiBasicsMdx,
    'api-explorer': ApiExplorerMdx,
    'create-events': CreateEventsMdx,
    events: EventsMdx,
    'event-description': EventDescriptionMdx,
    'ticket-classes': TicketClassesMdx,
    questions: QuestionsMdx,
    orders: OrdersMdx,
    attendees: AttendeesMdx,
    'image-upload': ImageUploadMdx,
    organizations: OrganizationsMdx,
    'online-event-page': OnlineEventPageMdx,
    'by-category': ByCategoryMdx,
    'by-date': ByDateMdx,
    'by-location': ByLocationMdx,
    'embedded-checkout': EmbeddedCheckoutMdx,
    'order-lookup': OrderLookupMdx,
    'cancelling-and-refunding-orders': CancelRefundOrderMdx,
    'what-is-app-marketplace': WhatIsAppMarketplaceMdx,
    'what-is-spectrum': WhatIsSpectrumDeprecatedMdx,
    'app-approval-process': AppApprovalProcessMdx,
    'app-oauth-flow': AppOauthFlowMdx,
    'integrate-your-app': IntegrateWithEventbriteManageMdx,
    'app-product-page': AppProductPageMdx,
    'managing-app-reviews': ManagingAppReviewsMdx,
    mailchimp: MailchimpMdx,
    contact: ContactSupportMdx,
    changelog: ChangelogMdx,
    'rate-limits': RateLimitsMdx,
    webhooks: WebhooksMdx,
};

export const PAGE_TITLE_MAP = {
    introduction: 'Intro to APIs — Documentation | Eventbrite Platform',
    authentication:
        'Intro to Authentication — Documentation | Eventbrite Platform',
    'api-basics': 'API Basics — Documentation | Eventbrite Platform',
    'api-explorer': 'Exploring the API — Documentation | Eventbrite Platform',
    'create-events': 'Creating an Event — Documentation | Eventbrite Platform',
    events:
        'Getting Information on Events - Documentation | Eventbrite Platform',
    'event-description':
        'Creating and Updating the Event Description - Documentation | Eventbrite Platform',
    'ticket-classes':
        'Ticket Classes and Ticket Groups - Documentation | Eventbrite Platform',
    questions: 'Using Questions - Documentation | Eventbrite Platform',
    orders: 'Working with Event Orders - Documentation | Eventbrite Platform',
    attendees:
        'Obtaining Attendee Information - Documentation | Eventbrite Platform',
    'image-upload':
        'Including an Image with Your Event Listing - Documentation | Eventbrite Platform',
    organizations:
        'Understanding Organizations - Documentation | Eventbrite Platform',
    'online-event-page':
        'Online Event Page - Documentation | Eventbrite Platform',
    'by-category':
        'Searching Events by Category - Documentation | Eventbrite Platform',
    'by-date': 'Searching Events by Date - Documentation | Eventbrite Platform',
    'by-location':
        'Searching Events by Location - Documentation | Eventbrite Platform',
    'embedded-checkout':
        'Embedding a Checkout Flow - Documentation | Eventbrite Platform',
    'order-lookup':
        'Finding Order Information - Documentation | Eventbrite Platform',
    'cancelling-and-refunding-orders':
        'Cancel and Refund Order - Documentation | Eventbrite Platform',
    'what-is-app-marketplace':
        'What is the App Marketplace? - Documentation | Eventbrite Platform',
    'what-is-spectrum':
        'What is the App Marketplace? - Documentation | Eventbrite Platform',
    'app-approval-process':
        'App Approval Process - Documentation | Eventbrite Platform',
    'app-oauth-flow': 'IApp OAuth Flow - Documentation | Eventbrite Platform',
    'integrate-your-app':
        'Integrating with Eventbrite Manage - Documentation | Eventbrite Platform',
    'app-product-page': 'App Product Page | Eventbrite Platform',
    'managing-app-reviews':
        'Managing App Reviews - Documentation | Eventbrite Platform',
    mailchimp: 'Mailchimp App - Documentation | Eventbrite Platform',
    contact: 'Contact Support - Documentation | Eventbrite Platform',
    changelog: 'Changelog - Documentation | Eventbrite Platform',
    'rate-limits': 'Rate Limits - Documentation | Eventbrite Platform',
    webhooks: 'Using Webhooks - Documentation | Eventbrite Platform',
};

export const DESCRIPTION_MAP = {
    introduction:
        'Use the Eventbrite API to push and pull data, customize the checkout experience, integrate your application or website, and get notification of changes.',
    authentication:
        'Authenticate your access to the Eventbrite API using a private token. Create a token and then provide the token each time you access the API.',
    'api-basics':
        'Use the Eventbrite REST API to communicate between Eventbrite and your application or website. Understand API basics and terminology to successfully use the Eventbrite API.',
    'api-explorer':
        'Using the API Explorer helps you to understand and debug Eventbrite endpoints, since the tool provides detailed information on an endpoint in a web browser.',
    'create-events':
        'Learn how to create Events from scratch or by copying and updating an existing Event. Also learn how to create a Ticket Class and publish an Event.',
    events:
        'Learn how to get information on an Event by first finding an Event ID. Then you can find Event information by Venue, Category, status, date, Organization and other characteristics.',
    'event-description':
        'The Eventbrite api provides a way to create a rich, flexible interface for event descriptions.',
    'ticket-classes':
        "Getting information on an Event's Ticket Classes also enables you to determine the number of remaining unsold tickets for an Event, and combine Ticket Classes into Ticket Groups.",
    questions:
        "You can retrieve information on an Order's default and custom Questions, including the list of questions and provided answers, and create custom questions.",
    orders:
        'You can get a list of all Orders for an Event, and narrow the search results by using specific parameters and expansions.',
    attendees:
        "Available Attendee information includes for a single Attendee of an Event, for all Attendees of an Event, an Order's Attendees, and Attendee status.",
    'image-upload':
        'You can include an image with you Event listing. To do so you must retrieve the image upload instructions, upload the image file and then use the provided upload token.',
    organizations:
        'The new Organization architecture has introduced changes to the permissions model and requires identifying an Organization ID to create a new Event.',
    'online-event-page':
        'The Online Event Page (previously known as Digital Links) features allows online event organizers to create and automatically distribute online content specific to attendees of their event.',
    'by-category':
        'You can search for public Events by Category, Subcategory and high affinity Category. You first learn how to obtain a Category and Subcategory ID.',
    'by-date':
        'Learn how to narrow you search for public Events by date, by date range and by date keyword.',
    'by-location':
        'Filter your search for public Events by location, including address, city, state, country, or by longitude and latitude.',
    'embedded-checkout':
        'Embed a checkout widget in your app or website. The checkout widget can be either modal or non-modal.',
    'order-lookup':
        "Using the Order ID, you can find Order financial and transactional information, detailed billing information of the Order owner, and answers to an Order's questions.",
    'cancelling-and-refunding-orders':
        'Cancelling and refunding Orders using the Order Service API. You can also find an Order by refund status.',
    'what-is-app-marketplace':
        "The App Marketplace is Eventbrite's marketplace of apps and integrations. Learn more.",
    'what-is-spectrum':
        "The App Marketplace is Eventbrite's marketplace of apps and integrations. Learn more.",
    'app-approval-process':
        'Eventbrite App Marketplace app approval process for becoming an Eventbrite partner.',
    'app-oauth-flow':
        'Integrate your application with Eventbrite to reach new customers. Integration requires authenticating access to the Eventbrite API for all users of your app.',
    'integrate-your-app':
        'How to integrate with Eventbrite Manage as an Eventbrite partner.',
    'app-product-page':
        "How to optimize your App Product Page on Eventbrite. Use this page to tell your app's story, showcase its features with texts and screenshots",
    'managing-app-reviews':
        "The App Marketplace is Eventbrite's marketplace of apps and integrations. Learn more.",
    mailchimp: 'Data mapping for the Mailchimp app',
    contact:
        'Contact the Eventbrite API Support Google group for help in using the Eventbrite API. Make sure you have the required information as described here before contacting Support.',
    changelog: 'Improvements and changes related to Eventbrite Platform.',
    'rate-limits':
        'Rate limits determine tne number of API call you can make to Eventbrite on a hourly and daily basis.',
    webhooks:
        'Webhooks make it possible for you to receive real-time notifications on changes to specific Eventbrite API objects.',
};

export const SIDEBAR_TITLE_MAP = {
    introduction: 'Intro to APIs',
    authentication: 'Intro to Authentication',
    'api-basics': 'API Basics',
    'api-explorer': 'API Explorer',
    'create-events': 'Create Events',
    events: 'Events',
    'event-description': 'Event Description',
    'ticket-classes': 'Ticket Classes',
    questions: 'Questions',
    orders: 'Orders',
    attendees: 'Attendees',
    'image-upload': 'Image Upload',
    organizations: 'Organizations',
    'online-event-page': 'Online Event Page',
    'by-category': 'By Category',
    'by-date': 'By Date',
    'by-location': 'By Location',
    'embedded-checkout': 'Embedded Checkout',
    'order-lookup': 'Order Lookup',
    'cancelling-and-refunding-orders': 'Cancel & Refund Order',
    'what-is-app-marketplace': 'Eventbrite App Marketplace',
    'what-is-spectrum': 'Eventbrite App Marketplace',
    'app-approval-process': 'App Approval Process',
    'app-oauth-flow': 'App OAuth Flow',
    'integrate-your-app': 'Integrate with Eventbrite Manage',
    'app-product-page': 'App Product Page',
    'managing-app-reviews': 'Managing App Reviews',
    mailchimp: 'Mailchimp App',
    contact: 'Contact Support',
    changelog: 'Changelog',
    'rate-limits': 'Rate Limits',
    webhooks: 'Webhooks',
};
