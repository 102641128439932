const EVENT_TYPE = 'event';
const ARTICLE_TYPE = 'article';
const PROFILE_TYPE = 'profile';

const EVENTS_TYPE = 'events';
const ARTICLES_TYPE = 'articles';
const PROFILES_TYPE = 'profiles';

export const BASE_URL = '/';

export const SEARCH_TYPES = [EVENTS_TYPE, ARTICLES_TYPE, PROFILES_TYPE];

export const SEARCH_CRITERIA_TYPES = [
    `${EVENT_TYPE}_search`,
    `${ARTICLE_TYPE}_search`,
    `${PROFILE_TYPE}_search`,
];

export const SINGULARIZED_ENTITY_TYPE_MAP = {
    [EVENTS_TYPE]: EVENT_TYPE,
    [ARTICLES_TYPE]: ARTICLE_TYPE,
    [PROFILES_TYPE]: PROFILE_TYPE,
};

export const DEVELOPER_URL = 'https://developer.eventbrite.com';

export const SALES_CONTACT_SUPPORTED_TLDS = {
    '.com': 1,
    '.co.uk': 1,
    '.com.au': 1,
    '.de': 1,
    '.ie': 1,
    '.com.ar': 1,
};

export const HAS_INDEPENDENT_SCROLL = {
    docs: true,
};
