import Cookies from 'cookies-js';

/**
 * get the CSRF token. This uses the INIT_COOKIES because CSRF tokens should not
 * be set dynamically in the client.
 */
const getCSRFToken = () => Cookies.get('csrftoken');

const checkStatus = (response = {}) => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    }

    return Promise.reject(response);
};

const parseData = (response) => {
    if ('json' in response) {
        return response.json();
    }

    return Promise.resolve({});
};

const displayError = (error) => Promise.reject(error);

export const fetchFromApi = (path, { headers, method, ...options } = {}) => {
    let url = '';

    let fetchOptions = {
        headers,
        method: method || 'GET',
        ...options,
    };

    if (fetchOptions.method !== 'GET') {
        fetchOptions = {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                'Content-Type': 'application/json',
            },
        };
    }

    fetchOptions = {
        ...fetchOptions,
        headers: {
            ...fetchOptions.headers,
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRFToken': getCSRFToken(),
        },
        credentials: 'include',
    };

    url = `/api/v3${path}`;

    return fetch(url, fetchOptions)
        .then(checkStatus)
        .then(parseData)
        .catch(displayError);
};
