
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h1" components={components}>{`Intro to APIs`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Already know what an API is? Skip to `}<a href="https://www.eventbrite.com/platform/api#/introduction/about-our-api" target="_blank">{`About our API`}</a>{` to get started.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`So, what `}<MDXTag name="em" components={components} parentName="h2">{`is`}</MDXTag>{` an API?`}</MDXTag>
<MDXTag name="p" components={components}>{`In a nutshell, an `}<MDXTag name="strong" components={components} parentName="p">{`API`}</MDXTag>{` (or Application Programming Interface) is the code
that `}<MDXTag name="strong" components={components} parentName="p">{`allows applications to talk to each other`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`But why would you need an API?`}</MDXTag>
<MDXTag name="p" components={components}>{`Let's say you want to build an app that will list all the off-leash dog parks
within a 20-mile radius and give you directions on how to get there from your
location.`}</MDXTag>
<MDXTag name="p" components={components}>{`Instead of collecting all that data and building all those features yourself
from scratch, you can take a shortcut and utilize APIs.`}</MDXTag>
<MDXTag name="p" components={components}>{`How `}<MDXTag name="em" components={components} parentName="p">{`exactly`}</MDXTag>{` would you do that, in this example?`}</MDXTag>
<MDXTag name="p" components={components}>{`Many cities provide APIs that list the locations and other information for their
dog parks. Google Maps provides an API that will tell you how to get from where
you are to any given location.`}</MDXTag>
<MDXTag name="p" components={components}>{`You can utilize those two existing APIs to build a new app that will do the
following:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Call the city’s API to retrieve the nearest off-leash dog parks, and`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Call Google’s API to retrieve the directions from your location.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Bada-bing, bada-boom. Your dog is chasing frisbees in no time.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`What can you do with the Eventbrite API?`}</MDXTag>
<MDXTag name="p" components={components}>{`Here are some examples of cool things you can do with the Eventbrite API:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#push-data-into-eventbrite"}}>{`Push data into Eventbrite.`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#pull-data-from-eventbrite"}}>{`Pull data from Eventbrite.`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#customize-your-checkout-experience"}}>{`Customize your checkout experience.`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#integrate-your-application-or-website"}}>{`Integrate your application or website.`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#use-webhooks-to-get-real-time-information-from-eventbrite"}}>{`Use webhooks to get real-time information from Eventbrite.`}</MDXTag>
<a name="push-data-into-eventbrite"></a></MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Push data into Eventbrite`}</MDXTag>
<MDXTag name="p" components={components}>{`You can use POST and DELETE requests to:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Create, cancel, and delete events.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Update an existing event.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Publish and unpublish an event.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Create, update, and delete ticket classes and discounts.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Create and update venues.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Create default and custom order questions.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}><a name="pull-data-from-eventbrite"></a></MDXTag>
<MDXTag name="h3" components={components}>{`Pull data from Eventbrite`}</MDXTag>
<MDXTag name="p" components={components}>{`You can use GET requests to:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Events.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Ticket classes and discounts.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Orders and order questions.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Attendees.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Eventbrite users.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Organizations.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Venues.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Other event characteristics like organizers, assortments, categories and
subcategories, formats, pricing, display settings, and media.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Eventbrite also makes it possible to extract data and display it in reports.`}</MDXTag>
<MDXTag name="p" components={components}><a name="customize-your-checkout-experience"></a></MDXTag>
<MDXTag name="h3" components={components}>{`Customize your checkout experience`}</MDXTag>
<MDXTag name="p" components={components}>{`You can embed a custom ticket checkout experience in your app or website. In
addition, you can define a custom payment flow to collect registration
information and sell tickets as a unique experience directly on your website.`}</MDXTag>
<MDXTag name="p" components={components}><a name="integrate-your-application-or-website"></a></MDXTag>
<MDXTag name="h3" components={components}>{`Integrate your application or website`}</MDXTag>
<MDXTag name="p" components={components}>{`You can integrate your app with Eventbrite to reach new customers, including the
700,000 Eventbrite Event creators. Integrated apps can be made available to
users through the Eventbrite App Marketplace.`}</MDXTag>
<MDXTag name="p" components={components}>{`Check out
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/platform/docs/what-is-app-marketplace"}}>{`Eventbrite App Marketplace`}</MDXTag>{`
for more details.`}</MDXTag>
<MDXTag name="p" components={components}><a name="use-webhooks-to-get-real-time-information-from-eventbrite"></a></MDXTag>
<MDXTag name="h3" components={components}>{`Using Webhooks to get real-time information from Eventbrite`}</MDXTag>
<MDXTag name="p" components={components}>{`You can use Webhooks to deliver information to your integrated applications in
real time, meaning you get immediate notification of any API changes.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Started with the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`Before you can start using the Eventbrite API, you'll need to do the following:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Create a free Eventbrite account.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Go to the `}<MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.eventbrite.com/platform/"}}>{`Developer Portal`}</MDXTag>{` and click
"Get a Free API Key" to retrieve your API key.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Authenticate your API access.`}<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">{`New to authentication? Check out
`}<MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.eventbrite.com/platform/docs/authentication"}}>{`Intro to Authentication`}</MDXTag>{`
to get up to speed.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Already know what authentication is? Check out
`}<MDXTag name="a" components={components} parentName="li" props={{"href":"https://www.eventbrite.com/platform/api#/introduction/authentication"}}>{`Authentication`}</MDXTag>{`
to get started.`}</MDXTag>
</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
           </MDXTag>
  }
}
  