import { fetchFromApi } from './base';

export const createNewApplication = (user) => {
    const data = {
        application: {
            contact_first_name: user.first_name,
            contact_last_name: user.last_name,
            application_url: 'http://www.example.com',
            application_name: 'Your First API Key',
            description: 'This is your first API Key!',
            user_id: user.id,
        },
    };

    return fetchFromApi('/applications/?expand.application=credentials_info', {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((response) => {
            const firstAppToken =
                response.application.credentials_info.personal_oauth_token;

            return { status: response.status, token: firstAppToken };
        })
        .catch((response) => ({ status: response.status, token: null }));
};

export const fetchApplications = (user) =>
    fetchFromApi(
        '/users/me/applications/?expand.application=credentials_info',
        {
            method: 'GET',
        },
    )
        .then((response) => {
            const applications = response.applications;

            if (applications.length === 0) {
                return createNewApplication(user);
            }

            const firstAppCredentials =
                response.applications[0].credentials_info;

            return {
                status: response.status,
                token: firstAppCredentials.personal_oauth_token,
            };
        })
        .catch((response) => ({ status: response.status, token: null }));
