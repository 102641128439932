import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Icon } from '@eb/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { ArrowRightChunky } from '@eb/eds-iconography';

import './feature.scss';

const IMAGE_HEIGHT = 350;
const hasShadow = true;

export default class Feature extends PureComponent {
    render() {
        const { heading, description, link, linkText, imagePath } = this.props;

        let image;

        let featureLink;

        if (imagePath) {
            const imgClassNames = classnames('eds-max-img', {
                'has-shadow': hasShadow,
            });

            image = (
                <div className="eds-align--center eds-l-mar-bot-6">
                    <img
                        className={imgClassNames}
                        alt={heading}
                        src={imagePath}
                        height={`${IMAGE_HEIGHT}px`}
                    />
                </div>
            );
        }
        if (link && linkText) {
            featureLink = (
                <p>
                    <Button
                        type="link"
                        style="anchor"
                        href={link}
                        __containerClassName="learn-more-link"
                    >
                        <span className="eds-l-mar-right-1">{linkText}</span>
                        <Icon
                            type={<ArrowRightChunky />}
                            color="ui-orange"
                            size="xsmall"
                            __containerClassName="learn-more-icon"
                        />
                    </Button>
                </p>
            );
        }

        return (
            <section>
                {image}
                <h1 className="eds-text-bm eds-text-weight--heavy eds-l-mar-bot-2">
                    {heading}
                </h1>
                <p className="eds-l-mar-bot-1">{description}</p>
                {featureLink}
            </section>
        );
    }
}
