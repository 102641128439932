
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';
import AppProductPageDesignTemplate from '../../assets/eventbrite_app_product_page_design_template.zip';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`App Product Page`}</MDXTag>
<MDXTag name="p" components={components}>{`People discover your app and decide whether to install it on your App Product
Page. Use this space to tell your app’s story, showcase its features with text
and screenshots, and ultimately attract new users.`}</MDXTag>
<MDXTag name="p" components={components}>{`Looking to create or update your app product page? We've done the heavy lifting
for you: `}<a href={AppProductPageDesignTemplate}>{`Download the App Product Page
Design Template`}</a>{` for your ready-to-customize design template!`}</MDXTag>
<MDXTag name="h2" components={components}>{`App Name`}</MDXTag>
<img
    src={require('../../images/App-Name.jpg').default}
    style={{ width: '90%' }}
    className="eds-l-mar-all-4 eds-show-down-md"
/>
<div style={{display: 'flex', alignItems: 'center'}} className="eds-l-mar-bot-4">
Your app’s name plays a major role in how users discover it on the App Marketplace. A great name is punchy, memorable, and provides insight on what the app does. Avoid names that use generic terms or are too similar to existing app names.
<div>
  <img src={require('../../images/App-Name.jpg').default} style={{width: 430+'px'}} className="eds-l-mar-all-4 eds-show-up-mw" />
  </div>
</div>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Requirements:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Less than 30 characters long`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Does not include the word “Eventbrite”`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Unique and specific`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Subtitle (Short Description)`}</MDXTag>
<img
    src={require('../../images/Subtitle.jpg').default}
    style={{ width: '90%' }}
    className="eds-l-mar-all-4 eds-show-down-md"
/>
<div style={{display: 'flex', alignItems: 'center'}} className="eds-l-mar-bot-4">
Your app’s subtitle should briefly explain what your app does. Be concise and intriguing so that users click to find out more. Avoid generic descriptions and instead highlight specific features that resonate with your target market.
<div>
  <img src={require('../../images/Subtitle.jpg').default} style={{width: 430+'px'}} className="eds-l-mar-all-4 eds-show-up-mw" />
  </div>
</div>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Requirements:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Less than 40 characters long`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Describe your product’s Eventbrite app specifically, not your product in
general`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Icon`}</MDXTag>
<img
    src={require('../../images/Icon.jpg').default}
    style={{ width: '90%' }}
    className="eds-l-mar-all-4 eds-show-down-md"
/>
<div style={{display: 'flex', alignItems: 'center'}} className="eds-l-mar-bot-4">
Your app’s icon should be recognizable and catch people’s eye while they’re browsing the marketplace. Make it unique and simple, so people can understand what it is.
<div>
  <img src={require('../../images/Icon.jpg').default} style={{width: 430+'px'}} className="eds-l-mar-all-4 eds-show-up-mw" />
  </div>
</div>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Requirements:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Must be 300 x 300px in PNG format with a full-bleed background`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`You must have the right to use the image`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Must not include text, screenshots, or any form of the Eventbrite logo`}</MDXTag>
</MDXTag>
<img
    src={require('../../images/Sample-Icons.jpg').default}
    style={{ width: '100%' }}
    className="eds-l-mar-top-4 eds-l-mar-bot-2"
/>
<MDXTag name="h2" components={components}>{`Screenshots`}</MDXTag>
<MDXTag name="p" components={components}>{`Screenshots help users quickly understand what your app does and its key
features. Start with a few that display your app being used with Eventbrite, and
include a couple that specifically highlight your app’s other valuable features.
Avoid only using marketing slides that do not show users a real-life, in-app
experience.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Requirements:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Include 1-4 screenshots (we recommend at least 2)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Must be at least 776x450px (4:3 ratio; landscape)`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`JPEG format; under 5mb`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Make it easy to see the feature you’re showing off by using zooms, text, and
highlights`}</MDXTag>
</MDXTag>
<img
    src={require('../../images/Sample-Screenshot.jpg').default}
    style={{ width: '100%' }}
    className="eds-l-mar-top-4 eds-l-mar-bot-2"
/>
<MDXTag name="h2" components={components}>{`Banner`}</MDXTag>
<MDXTag name="p" components={components}>{`The banner gives your app product page a unique look and feel. Expose your brand
through the app’s banner. Your banner should complement your app’s icon. We
recommend using a banner that does not add distraction to your logo or your
product page.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Requirements:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Must be 1920 x 350px`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Does not include the Eventbrite logo`}</MDXTag>
</MDXTag>
<img
    src={require('../../images/Sample-Banner.jpg').default}
    style={{ width: '100%' }}
    className="eds-l-mar-top-4 eds-l-mar-bot-2"
/>
<MDXTag name="h2" components={components}>{`Description (Long)`}</MDXTag>
<MDXTag name="p" components={components}>{`Provide an engaging description of your app and how it will work with
Eventbrite. Focus on helping users understand the benefits of using your app,
what it does, and what makes it unique. Start with a list of 2 to 3 key features
followed by a concise and informative paragraph. Be clear and clever. To
increase the likelihood of users discovering your app through search, include a
variety of words in your app description that represent the core features of
your app.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Requirements:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Up to 2,800 characters`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Include a features section with 2 to 3 features`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Use active voice and speak to users directly`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Categories`}</MDXTag>
<MDXTag name="p" components={components}>{`Categories on the App Marketplace give users a way to discover new apps that can
help them solve their problems and grow their business. The category you choose
for your app will help users discover your app both in the category filter and
when using the search bar. When picking a category, think about who is most
likely to use your app and what they’ll want to use it for. Consider the
category they’ll be most likely to look for a solution like yours.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Choose a category from this list:`}</MDXTag></MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`CRM`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Email`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Marketing & Promotion`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Productivity`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Reporting`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Tickets & Onsite`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Website`}</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Learn More URL (“Get Started”)`}</MDXTag>
<MDXTag name="p" components={components}>{`If your app is installed via your product/website (rather than directly from the
Eventbrite App Marketplace), you must link to a page on your site where users
can find out more detail about your app, and how to install and use it. This can
be a landing page or help article. You can use UTM tags if you want to track
referrals from your product page.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Support`}</MDXTag>
<MDXTag name="p" components={components}>{`Contact information is an important part of your app listing. The information
you enter here helps users learn more about your app and contact you with
questions or issues. You must include a support email, and you can optionally
add a sales email. Additionally, you can add a support or sales phone number.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Resources Links`}</MDXTag>
<MDXTag name="p" components={components}>{`Provide links to your website, landing page, or help articles.`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Managing App Reviews',
            link: '/platform/docs/managing-app-reviews',
        },
        {
            title: 'App Approval Process',
            link: '/platform/docs/app-approval-process',
        },
    ]}
/>
           </MDXTag>
  }
}
  