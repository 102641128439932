import logger from '@eb/client-logger';
import React from 'react';

interface ErrorBoundaryProps {
    children: React.ReactNode;
    fallback: React.ReactNode;
    name?: string;
}

interface DevErrorDisplayProps {
    name?: string;
}

const IS_DEV = process.env.NODE_ENV !== 'production';

const DevErrorDisplay: React.FunctionComponent<DevErrorDisplayProps> = (
    props,
) => (
    <>
        <p>
            <b>{props.name}</b> - Failed to render
        </p>
        <p>Check the developers console for a more detailed error breakdown</p>
        <p>
            <b>
                Be sure to include a fallback view before launching, this copy
                will only appear in dev.
            </b>
        </p>
    </>
);

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    { hasError: boolean }
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.debug({
            name: this.props?.name || 'ApplicationError',
            error,
            errorInfo,
        });

        logger.error(
            this.props.name
                ? `Error in  ${name}`
                : 'Error in application content',
            {
                error: error.message ? error.message : error,
            },
        );
    }

    render() {
        if (this.state.hasError) {
            return (
                this.props.fallback ||
                (IS_DEV && <DevErrorDisplay name={this.props.name} />)
            );
        }

        return this.props.children;
    }
}
