
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Working with an Event's Orders`}</MDXTag>
<MDXTag name="p" components={components}>{`An Order is placed in Eventbrite for one or more Ticket Classes. This means that
a single Order can be made up of multiple tickets. By default, an Order object
returns only financial and transactional information. Additional Order data can
be retrieved using expansions.`}</MDXTag>
<MDXTag name="p" components={components}>{`Order objects are considered private, meaning that all Order information is only
available to the Order Owner and a User who is a member of an Organization to
which the Event and it's associated Order belongs.`}</MDXTag>
<MDXTag name="p" components={components}>{`The first step in working with an Event's Orders is to identify the ID of the
Event associated with the Order of interest. For information on finding an Event
ID, refer to `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/events"}}>{`Getting Information on an Event`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To work with Orders, you must authenticate your access to the Eventbrite API. To
learn how, refer to `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`
page.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Listing an Event's Orders`}</MDXTag>
<MDXTag name="p" components={components}>{`You can get a list of all an Event's Orders by using the following request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/orders/\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Example response:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "costs": {
        "base_price": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "eventbrite_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "gross": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "payment_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "tax": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        }
    },
    "resource_uri": "https://www.eventbriteapi.com/v3/orders/12345/",
    "id": "12345",
    "changed": "2018-11-02T19:07:24Z",
    "created": "2018-11-02T19:07:24Z",
    "name": "Kennedy Singleton",
    "first_name": "Kennedy",
    "last_name": "Singleton",
    "email": "kennedy@gmail.com",
    "status": "placed",
    "time_remaining": null,
    "event_id": "123456"
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Narrowing Order Search Returns for an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`You can narrow the return of Orders for an Event by using the appropriate
parameter, appended to the end of the Get request URL.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Parameter`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`status`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Possible values are: `}<MDXTag name="inlineCode" components={components} parentName="td">{`active`}</MDXTag>{` - Order has Attendees, `}<MDXTag name="inlineCode" components={components} parentName="td">{`inactive`}</MDXTag>{` - Order does not have Attendees, `}<MDXTag name="inlineCode" components={components} parentName="td">{`both`}</MDXTag>{` - Active, inactive and deleted Orders, or `}<MDXTag name="inlineCode" components={components} parentName="td">{`all_not_deleted`}</MDXTag>{` - Active and inactive, but not deleted, Orders`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`changed_since`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`datetime`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Orders changed on or after this time. Date time format is `}<MDXTag name="inlineCode" components={components} parentName="td">{`YYYY-MM-DDThh:mm:ssZ`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`last_item_seen`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Orders changed on or after this time, and with an ID larger than last item seen.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`only_emails`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Orders placed only by this email address.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`exclude_emails`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Do not include Orders placed by this email address in the return.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`refund_request_statuses`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`array[string]`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Include only Orders with the specified refund request status in the return. Possible values are `}<MDXTag name="inlineCode" components={components} parentName="td">{`completed`}</MDXTag>{`,`}<MDXTag name="inlineCode" components={components} parentName="td">{`pending`}</MDXTag>{`,`}<MDXTag name="inlineCode" components={components} parentName="td">{`outside_policy`}</MDXTag>{`,`}<MDXTag name="inlineCode" components={components} parentName="td">{`disputed`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="td">{`denied`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`For example, to obtain a list of an Event's Orders changed after the date
2018-01-01T00:00:01z, you use the following request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/orders?changed_since=2019-01-01T00:00:01z\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Information on Order Attendees for an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the expansion `}<MDXTag name="inlineCode" components={components} parentName="p">{`expand=attendees`}</MDXTag>{` to get detail information on the Attendees
associated with an Order, by appending the expansion to the end of the following
request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/EVENT_ID/orders/expand=attendees\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The result is an array of Attendee objects attached in the Order object, as
shown in this example`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  ...
  "attendees": [
    {
    "team": null,
    "costs": {
        "base_price": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "eventbrite_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "gross": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "payment_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "tax": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        }
    },
    "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/attendees/12345/",
    "id": "12345",
    "changed": "2018-11-02T22:36:48Z",
    "created": "2018-11-02T19:07:24Z",
    "quantity": 1,
    "variant_id": null,
    "profile": {
        "first_name": "Kennedy",
        "last_name": "Singleton",
        "email": "kennedy@gmail.com",
        "name": "Kennedy Singleton",
        "addresses": {
            "home": {},
            "ship": {},
            "work": {},
            "bill": {},
            "fulfillment": {}
        }
    },
    "barcodes": [
        {
            "status": "used",
            "barcode": "1234567435234",
            "created": "2018-11-02T19:07:24Z",
            "changed": "2018-11-02T22:36:48Z",
            "checkin_type": 0,
            "checkin_method": "search",
            "is_printed": false
        }
    ],
    "answers": [
    ],
    "checked_in": true,
    "cancelled": false,
    "refunded": false,
    "affiliate": null,
    "guestlist_id": null,
    "invited_by": null,
    "status": "Checked In",
    "ticket_class_name": "General Admission",
    "delivery_method": "electronic",
    "event_id": "12345",
    "order_id": "123123123",
    "ticket_class_id": "123123123"
    },
  ]
  ...
}
`}</MDXTag></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Obtaining Attendee Information',
            link: '/platform/docs/attendees',
        },
        {
            title: 'Finding Information on an Order',
            link: '/platform/docs/order-lookup',
        },
    ]}
/>
           </MDXTag>
  }
}
  