import { day as EDSColors } from '@eb/eds-settings';

const FONTMAP = {
    h1: {
        color: EDSColors['ui-900'],
        family: 'Roboto, sans-serif',
        size: '35px',
        weight: '500',
    },
    h2: {
        border: `1px solid ${EDSColors['ui-200']}`,
        color: EDSColors['ui-900'],
        family: 'Roboto, sans-serif',
        margin: '16px 0',
        padding: '0 0 16px 0',
        size: '26px',
        weight: '500',
    },
    h3: {
        color: EDSColors['ui-900'],
        family: 'Roboto, sans-serif',
        size: '15px',
        weight: '500',
    },
    body: {
        family: 'Roboto, sans-serif',
        size: '15px',
        weight: '400',
    },
    caption: {
        family: 'Roboto, sans-serif',
        size: '13px',
        weight: '400',
    },
    strings: {
        family: 'Liberation Mono, Consolas, Menlo, Courier, monospace',
        size: '13px',
        weight: '400',
    },
    code: {
        h1: {
            family: 'monospace',
            size: '13px',
            weight: '400',
        },
        body: {
            family: 'monospace',
            size: '9px',
            weight: '400',
        },
        strings: {
            family: 'Roboto, sans-serif',
            size: '9px',
            weight: '500',
        },
    },
};

const EMBEDSTYLES = {
    code: {
        backgroundColor: EDSColors['ui-200'],
        borderColor: EDSColors['ui-300'],
        borderRadius: '2px',
        fontFamily: FONTMAP.strings.family,
        fontSize: FONTMAP.strings.size,
        padding: '2px 4px 1px',
        verticalAlign: 'bottom',
    },
};

const EMBEDTHEME = {
    fontFamily: 'Roboto',
    tableOfContents: {
        backgroundColor: EDSColors['ui-100'],
        section: {
            title: {
                color: EDSColors['ui-900'],
                fontSize: '15px',
                fontWeight: '500',
                textTransform: 'capitalize',
            },
            item: {
                color: EDSColors['ui-900'],
                $selected: {
                    text: {
                        fontWeight: '500',
                    },
                },
                subitems: {
                    borderLeft: `3px solid ${EDSColors['ui-200']}`,
                    subitem: {
                        borderLeft: `3px solid ${EDSColors['ui-200']}`,
                        text: {
                            color: EDSColors['ui-600'],
                        },
                        $selected: {
                            borderLeft: `3px solid ${EDSColors['primary-brand--focus']}`,
                            text: {
                                fontWeight: 'normal',
                            },
                        },
                        $hover: {
                            text: {
                                color: EDSColors['ui-900'],
                            },
                        },
                    },
                },
            },
        },
    },
    humanColumn: {
        wrapper: {
            backgroundColor: EDSColors.background,
        },
        content: {
            fontSize: FONTMAP.body.size,
            color: EDSColors['ui-800'],
            apiName: {
                borderBottom: '0',
                color: FONTMAP.h1.color,
                fontSize: FONTMAP.h1.size,
                fontWeight: FONTMAP.h1.weight,
                marginBottom: '0',
                paddingBottom: '0',
            },
            section: {
                title: {
                    text: {
                        fontSize: FONTMAP.h3.size,
                        textTransform: 'none',
                    },
                },
                apiDescription: {
                    fontSize: FONTMAP.body.size,
                    h2: {
                        borderBottom: FONTMAP.h2.border,
                        color: FONTMAP.h2.color,
                        fontSize: FONTMAP.h2.size,
                        fontWeight: FONTMAP.h2.weight,
                        margin: FONTMAP.h2.margin,
                        padding: FONTMAP.h2.padding,
                    },
                    h3: {
                        color: FONTMAP.h3.color,
                        fontSize: FONTMAP.h3.size,
                        fontWeight: FONTMAP.h3.weight,
                        margin: '8px 0',
                    },
                    p: {
                        fontSize: FONTMAP.body.size,
                        fontWeight: FONTMAP.body.weight,
                        marginBottom: '24px',
                    },
                    ul: {
                        li: {
                            paddingTop: '3px',
                        },
                    },
                    code: EMBEDSTYLES.code,
                    pre: {
                        code: {
                            fontFamily: `${FONTMAP.code.h1.family} !important`,
                            fontSize: `${FONTMAP.code.h1.size} !important`,
                            padding: '8px !important',
                        },
                    },
                },
                resourceGroups: {
                    resourceGroup: {
                        name: {
                            borderBottom: FONTMAP.h2.border,
                            color: FONTMAP.h2.color,
                            fontSize: FONTMAP.h2.size,
                            fontWeight: FONTMAP.h2.weight,
                            margin: FONTMAP.h2.margin,
                            padding: FONTMAP.h2.padding,
                        },
                        description: {
                            h2: {
                                color: FONTMAP.h3.color,
                                fontSize: FONTMAP.h3.size,
                                fontWeight: FONTMAP.h3.weight,
                            },
                            code: EMBEDSTYLES.code,
                        },
                        resources: {
                            resource: {
                                name: {
                                    color: FONTMAP.h3.color,
                                    fontSize: FONTMAP.h3.size,
                                    fontWeight: FONTMAP.h3.weight,
                                },
                                description: {
                                    code: EMBEDSTYLES.code,
                                },
                                actions: {
                                    action: {
                                        invitation: {
                                            backgroundColor:
                                                EDSColors['ui-100'],
                                            $hover: {
                                                backgroundColor:
                                                    EDSColors['ui-200'],
                                            },
                                            $selected: {
                                                backgroundColor:
                                                    EDSColors['ui-1000'],
                                                $hover: {
                                                    backgroundColor:
                                                        EDSColors['ui-1000'],
                                                },
                                                text: {
                                                    color: '#fff',
                                                },
                                            },
                                            text: {
                                                color: FONTMAP.h3.color,
                                                fontSize: FONTMAP.h3.size,
                                                fontWeight: FONTMAP.h3.weight,
                                                marginBottom: '6px',
                                                marginTop: '7px',
                                            },
                                            method: {
                                                fontSize: '0',
                                                maxWidth: '4px',
                                                padding: '0',
                                                width: '4px',
                                                $get: {
                                                    backgroundColor:
                                                        EDSColors[
                                                            'primary-brand--hover'
                                                        ],
                                                },
                                                $post: {
                                                    backgroundColor:
                                                        EDSColors.control,
                                                },
                                                $patch: {
                                                    backgroundColor:
                                                        EDSColors.success,
                                                },
                                                $delete: {
                                                    backgroundColor:
                                                        EDSColors.error,
                                                },
                                            },
                                            tag: {
                                                width: '4px',
                                            },
                                            icon: {
                                                backgroundSize: '7px 14px',
                                                backgroundImage:
                                                    'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDE4IDM2Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iIzRCNEQ2MyIgZD0iTTE4IDE4YS45OS45OSAwIDAgMC0uMjgyLS42OTFsLjAwMy0uMDAyLTE2LTE3LS4wMDMuMDAxQS45OTcuOTk3IDAgMSAwIC4yODIgMS42OTFsLS4wMDMuMDAyTDE1LjYxMSAxOCAuMjc5IDM0LjMwN2wuMDAzLjAwMkEuOTkuOTkgMCAwIDAgMCAzNWExIDEgMCAwIDAgMS43MTguNjkybC4wMDMuMDAyIDE2LTE3LS4wMDMtLjAwMkEuOTk3Ljk5NyAwIDAgMCAxOCAxOHoiLz48L3N2Zz4=)',
                                            },
                                        },
                                        description: {
                                            padding: '20px',
                                            code: EMBEDSTYLES.code,
                                            ul: {
                                                li: {
                                                    paddingTop: '3px',
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    machineColumn: {
        content: {
            backgroundColor: EDSColors['ui-800'],
            breadcrumbs: {
                container: {
                    backgroundColor: EDSColors['ui-900'],
                    borderBottom: `2px solid ${EDSColors['ui-700']}`,
                    borderTop: `2px solid ${EDSColors['ui-700']}`,
                },
            },
            title: {
                fontWeight: '500',
            },
            parameters: {
                title: {
                    borderBottomColor: EDSColors['ui-700'],
                    fontWeight: '500',
                },
                list: {
                    fontSize: `${FONTMAP.body.size} !important`,
                },
            },
            destination: {
                borderBottomColor: EDSColors['ui-700'],
            },
        },
        header: {
            backgroundColor: EDSColors['ui-800'],
            consoleButton: {
                button: {
                    border: 'none',
                    backgroundColor: EDSColors['ui-700'],
                },
            },
        },
    },
};

export const APIARYEMBEDPARAMS = {
    subdomain: 'eventbriteapiv3public',
    preferences: {
        displayHttpMethods: true,
        collapseMachineColumnByDefault: true,
        // Human column takes 100% width
        fluidHumanColumn: true,
        permalinks: true,
    },
    theme: EMBEDTHEME,
    element: '#apiary-embed',
    customFonts: {
        googleFonts:
            'https://fonts.googleapis.com/css?family=Roboto:400,500,700',
    },
};
