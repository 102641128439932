import React from 'react';
import { render } from 'react-dom';
import { initializeErrorReporting } from '@eb/error-reporting';
import {
    trackInitialPageView,
    initDimensionsFromState,
} from '@eb/site-analytics';

import './app.scss';
import App from './app';

// retrieve data from server to hydrate on the client
const props = window.__SERVER_DATA__ || {};

const root = document.getElementById('root');

initializeErrorReporting();

render(<App {...props} />, root);

initDimensionsFromState(props);
trackInitialPageView(props.gaSettings);
