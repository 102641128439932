import { connect } from 'react-redux';

import BasePage from '../pages/Base';

const _mapStateToProps = ({ app }) => ({
    isLoading: app.isLoading,
    userData: app.userData,
});

export default connect(_mapStateToProps)(BasePage);
