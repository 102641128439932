
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`What is the Eventbrite App Marketplace?`}</MDXTag>
<MDXTag name="p" components={components}>{`The Eventbrite App Marketplace is both a marketplace for event creators and a
program for developers or software companies looking to partner with Eventbrite.`}</MDXTag>
<MDXTag name="p" components={components}>{`For event creators, the Eventbrite App Marketplace is the marketplace to
discover apps that will save time and maximize success. Explore
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/apps"}}>{`eventbrite.com/apps`}</MDXTag>{` to discover existing apps in the marketplace.`}</MDXTag>
<MDXTag name="p" components={components}>{`For partners, the Eventbrite App Marketplace partner program provides a path for
utilizing the Eventbrite API and teaming up with Eventbrite with an eye toward
mutual growth. Explore `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/l/partner-program"}}>{`eventbrite.com/l/partner-program/`}</MDXTag>{`
to learn more about the partner program and reach out if you're interested in
joining.`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'App Approval Process',
            link: '/platform/docs/app-approval-process',
        },
    ]}
/>
           </MDXTag>
  }
}
  