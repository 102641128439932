import { combineReducers } from 'redux';

import { SET_API_KEY } from '../actions/apiKey';

const apiKey = (state = '', { type, payload }) => {
    let nextState = state;

    if (type === SET_API_KEY) {
        nextState = payload;
    }

    return nextState;
};

export default combineReducers({
    apiKey,
});
