import React, { PureComponent } from 'react';

import Plx from 'react-plx';
import MetaTags from 'react-meta-tags';
import HeroBanner from '../../components/heroBanner/HeroBanner';
import HeroBannerContent from './HeroContent';
import LogoBar from '../../components/logoBar/LogoBar';
import FooterPromo from '../../components/footer/FooterPromo';
import TwoColumnCTA from '../../components/feature/TwoColumnCTA';
import { HEROBG, HOMEFEATURES } from '../../constants/home';

import './home.scss';

export default class IndexScreen extends PureComponent {
    componentDidMount() {
        // Add a dark "theme" for content
        document.body.classList.add('theme-dark');
    }

    componentWillUnmount() {
        document.body.classList.remove('theme-dark');
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>
                        Eventbrite for Developers - Eventbrite Platform
                    </title>
                    <meta
                        name="description"
                        content="The Eventbrite Platform enables developers to create fully custom event solutions, automate processes, and integrate Eventbrite with other products and services."
                    />
                </MetaTags>
                <HeroBanner
                    bgImgPath={HEROBG.url}
                    bgSize={HEROBG.size}
                    bgPosition={HEROBG.pos}
                    minHeight="568px"
                >
                    <HeroBannerContent />
                </HeroBanner>

                <LogoBar />

                <div className="eds-l-mar-vert-20 eds-l-sn-mar-vert-10 eds-l-sm-mar-vert-10 eds-l-sn-pad-hor-5 eds-l-sm-pad-hor-5 eds-l-sw-pad-hor-5">
                    <TwoColumnCTA
                        heading={HOMEFEATURES.ticketing.heading}
                        description={HOMEFEATURES.ticketing.description}
                        linkUrl={HOMEFEATURES.ticketing.linkUrl}
                        linkText={HOMEFEATURES.ticketing.linkText}
                    >
                        <div style={{ position: 'relative' }}>
                            <img
                                className="eds-max-img"
                                alt={HOMEFEATURES.ticketing.heading}
                                src={HOMEFEATURES.ticketing.images[0]}
                            />
                            <Plx
                                parallaxData={HOMEFEATURES.ticketing.animation}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    opacity: 0,
                                }}
                            >
                                <img
                                    className="eds-max-img"
                                    alt={HOMEFEATURES.ticketing.heading}
                                    src={HOMEFEATURES.ticketing.images[1]}
                                />
                            </Plx>
                        </div>
                    </TwoColumnCTA>

                    <TwoColumnCTA
                        heading={HOMEFEATURES.reporting.heading}
                        description={HOMEFEATURES.reporting.description}
                        linkUrl={HOMEFEATURES.reporting.linkUrl}
                        linkText={HOMEFEATURES.reporting.linkText}
                        reverseHorizontal={true}
                    >
                        <div style={{ position: 'relative' }}>
                            <img
                                className="eds-max-img"
                                alt={HOMEFEATURES.reporting.heading}
                                src={HOMEFEATURES.reporting.images[0]}
                            />
                            <Plx
                                parallaxData={HOMEFEATURES.reporting.animation}
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    opacity: 0,
                                }}
                            >
                                <img
                                    className="eds-max-img"
                                    alt={HOMEFEATURES.reporting.heading}
                                    src={HOMEFEATURES.reporting.images[1]}
                                />
                            </Plx>
                        </div>
                    </TwoColumnCTA>

                    <TwoColumnCTA
                        heading={HOMEFEATURES.search.heading}
                        description={HOMEFEATURES.search.description}
                        linkUrl={HOMEFEATURES.search.linkUrl}
                        linkText={HOMEFEATURES.search.linkText}
                    >
                        <div style={{ position: 'relative' }}>
                            <img
                                className="eds-max-img"
                                alt={HOMEFEATURES.search.heading}
                                src={HOMEFEATURES.search.images[0]}
                            />
                            <Plx
                                parallaxData={HOMEFEATURES.search.animation}
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    transformOrigin: '58% 50%',
                                }}
                            >
                                <img
                                    className="eds-max-img"
                                    alt={HOMEFEATURES.search.heading}
                                    src={HOMEFEATURES.search.images[1]}
                                />
                            </Plx>
                            <img
                                className="eds-max-img"
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                }}
                                alt={HOMEFEATURES.search.heading}
                                src={HOMEFEATURES.search.images[2]}
                            />
                        </div>
                    </TwoColumnCTA>

                    <TwoColumnCTA
                        heading={HOMEFEATURES.develop.heading}
                        description={HOMEFEATURES.develop.description}
                        linkUrl={HOMEFEATURES.develop.linkUrl}
                        linkText={HOMEFEATURES.develop.linkText}
                        reverseHorizontal={true}
                    >
                        <div style={{ position: 'relative' }}>
                            <img
                                className="eds-max-img"
                                alt={HOMEFEATURES.develop.heading}
                                src={HOMEFEATURES.develop.images[0]}
                                style={{ width: '100%', display: 'block' }}
                            />
                            <img
                                className="eds-max-img"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '16%',
                                    maxWidth: '16%',
                                }}
                                alt={HOMEFEATURES.develop.heading}
                                src={HOMEFEATURES.develop.images[1]}
                            />
                            <Plx
                                className="develop-parallax"
                                parallaxData={HOMEFEATURES.develop.animation[0]}
                                style={{
                                    position: 'absolute',
                                    top: '-5%',
                                    bottom: '-5%',
                                    left: '-5%',
                                    right: '-5%',
                                    width: '110%',
                                    transformOrigin: '50% 50%',
                                }}
                            >
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[1]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '26%',
                                        left: '37%',
                                        transformOrigin: '50% 50%',
                                        width: '14.5%',
                                        maxWidth: '14.5%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[2]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[1]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '60.5%',
                                        left: '39%',
                                        transformOrigin: '50% 50%',
                                        width: '13.5%',
                                        maxWidth: '13.5%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[3]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                            </Plx>
                            <Plx
                                parallaxData={HOMEFEATURES.develop.animation[1]}
                                style={{
                                    position: 'absolute',
                                    top: '-5%',
                                    bottom: '-5%',
                                    left: '-5%',
                                    right: '-5%',
                                    width: '110%',
                                    transformOrigin: '50% 50%',
                                }}
                            >
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[0]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '64%',
                                        left: '65%',
                                        transformOrigin: '50% 50%',
                                        width: '13.5%',
                                        maxWidth: '13.5%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[4]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[0]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '46%',
                                        left: '13%',
                                        transformOrigin: '50% 50%',
                                        width: '13.5%',
                                        maxWidth: '13.5%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[5]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[0]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '18%',
                                        left: '61%',
                                        transformOrigin: '50% 50%',
                                        width: '13.5%',
                                        maxWidth: '13.5%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[6]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                            </Plx>
                            <Plx
                                parallaxData={HOMEFEATURES.develop.animation[0]}
                                style={{
                                    position: 'absolute',
                                    top: '-5%',
                                    bottom: '-5%',
                                    left: '-5%',
                                    right: '-5%',
                                    width: '110%',
                                    transformOrigin: '50% 50%',
                                }}
                            >
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[1]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '31%',
                                        left: '84%',
                                        transformOrigin: '50% 50%',
                                        width: '13.5%',
                                        maxWidth: '13.5%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[7]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                                <Plx
                                    parallaxData={
                                        HOMEFEATURES.develop.animation[1]
                                    }
                                    style={{
                                        position: 'absolute',
                                        top: '81%',
                                        left: '21%',
                                        transformOrigin: '50% 50%',
                                        width: '14%',
                                        maxWidth: '14%',
                                    }}
                                >
                                    <img
                                        className="eds-max-img"
                                        alt={HOMEFEATURES.develop.heading}
                                        src={HOMEFEATURES.develop.images[8]}
                                        style={{
                                            width: '100%',
                                            maxWidth: '100%',
                                        }}
                                    />
                                </Plx>
                            </Plx>
                        </div>
                    </TwoColumnCTA>
                </div>

                <FooterPromo
                    heading="Build"
                    subHeading="The future of live experiences"
                    btnText="Get a Free API Key"
                    btnLink="/login/?referrer=/platform/api-keys/"
                />
            </div>
        );
    }
}
