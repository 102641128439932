import { fetchLoggedInUser } from '../api/user';

export const getInitialState = () => {
    // get initial state
    const state = {
        app: {
            isLoading: false,
            userData: fetchLoggedInUser(),
        },
    };

    return state;
};
