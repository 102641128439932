
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`What is the Eventbrite App Marketplace?`}</MDXTag>
<MDXTag name="p" components={components}>{`Out with the old, and in with the new!`}</MDXTag>
<MDXTag name="p" components={components}><b>{`Spectrum`}</b>{` has been redesigned and relaunched as the `}<b>{`
Eventbrite App Marketplace
`}</b>{`
.{' '}`}</MDXTag>
<MDXTag name="p" components={components}>{`Check out `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/what-is-app-marketplace"}}>{`Eventbrite App Marketplace`}</MDXTag>{`
for more details!`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Eventbrite App Marketplace',
            link: '/platform/docs/what-is-app-marketplace',
        },
        {
            title: 'App Approval Process',
            link: '/platform/docs/app-approval-process',
        },
    ]}
/>
           </MDXTag>
  }
}
  