import debounce from 'lodash/debounce';
import { $FixMe } from 'packages/infra/ts-utils/src';
import { useCallback, useEffect, useRef } from 'react';

export const useScrollWatcher = (
    callback: (value: number) => void,
    {
        deps = [],
        interval = 100,
        selector = '',
    }: { deps: Array<boolean | number>; interval: number; selector: string },
) => {
    const scrollListenerRef = useRef<$FixMe>(null);
    const positionRef = useRef<number>(0);
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        containerRef.current = document.querySelector(selector);
    }, [selector]);

    const checkScrollPosition = useCallback(() => {
        callback(positionRef.current);
        positionRef.current = containerRef.current?.scrollTop || 0;
    }, [callback]);

    useEffect(() => {
        const handleScroll = () => {
            checkScrollPosition();
        };

        const debouncedHandleScroll = debounce(handleScroll, interval);

        scrollListenerRef.current = containerRef.current?.addEventListener(
            'scroll',
            debouncedHandleScroll,
        );

        return containerRef.current?.removeEventListener(
            'scroll',
            scrollListenerRef.current,
        );
        //eslint-disable-next-line
    }, [...deps, checkScrollPosition, interval]);
};
