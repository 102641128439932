export type ParentLanguageCode =
    | 'de_DE'
    | 'en_AU'
    | 'en_GB'
    | 'en_US'
    | 'es_AR'
    | 'es_ES'
    | 'es_MX'
    | 'fr_FR'
    | 'fr_CA'
    | 'it_IT'
    | 'nl_NL'
    | 'pt_BR'
    | 'pt_PT'
    // sv is not a country code, but we treat it like one for legacy transifex reasons
    | 'sv';

export type InheritedLanguageCode =
    | 'de_AT'
    | 'de_CH'
    | 'en_NZ'
    | 'en_IE'
    | 'en_SG'
    | 'en_HK'
    | 'en_DK'
    | 'en_FI'
    | 'en_CA'
    | 'es_CL'
    | 'es_CO'
    | 'es_PE'
    | 'fr_BE'
    | 'fr_CH'
    | 'nl_BE';

export type LanguageCode = ParentLanguageCode | InheritedLanguageCode;

export function isLanguageCode(arg: string): arg is LanguageCode {
    return Object.keys(__parentLanguageLookup).includes(arg);
}

export function isLanguageCodes(arg: string[]): arg is LanguageCode[] {
    return arg.map(isLanguageCode).every(Boolean);
}

/**
 * Map of parent languages to child lanaguages in transifex.
 *
 * We use this both as a list of parent languages to pull down when pulling from transifex
 * and as a map to know what country codes to copy over when we register strings with i18next
 *
 * Parent -> child means children "inherit" from that parent code
 * instead of copying translations over
 *
 * undefined signifies a parent has no children
 *
 * IF YOU EDIT THIS, PLEASE EDIT __parentLanguageLookup
 */
export const languageInheritanceMap: Record<
    ParentLanguageCode,
    InheritedLanguageCode[] | undefined
> = {
    de_DE: ['de_AT', 'de_CH'],
    en_AU: ['en_NZ'],
    en_GB: ['en_IE', 'en_SG', 'en_HK', 'en_DK', 'en_FI'],
    en_US: ['en_CA'],
    es_AR: undefined,
    es_ES: ['es_CL', 'es_CO', 'es_PE'],
    es_MX: undefined,
    fr_FR: ['fr_BE', 'fr_CH'],
    fr_CA: undefined,
    it_IT: undefined,
    nl_NL: ['nl_BE'],
    pt_BR: undefined,
    pt_PT: undefined,
    // sv is different. Possibly explained by this verbatim comment by Patrick McLoughlin
    // My guess is that when we added Swedish (in 2010) for some reason Transifex didn’t support the full language code.
    // It does now.
    // It would be relatively straightforward for me to change codes in transifex but then you or someone would have to make sure the code is updated for core as well as eb-UI
    sv: undefined,
};

/**
 * This is a precomputed map for easy lookup for getParentLocale. EDIT IF YOU EDIT ABOVE MAP
 */
export const __parentLanguageLookup: Record<
    LanguageCode,
    ParentLanguageCode
> = {
    de_AT: 'de_DE',
    de_CH: 'de_DE',
    de_DE: 'de_DE',
    en_NZ: 'en_AU',
    en_AU: 'en_AU',
    en_IE: 'en_GB',
    en_SG: 'en_GB',
    en_HK: 'en_GB',
    en_DK: 'en_GB',
    en_FI: 'en_GB',
    en_GB: 'en_GB',
    en_CA: 'en_US',
    en_US: 'en_US',
    es_AR: 'es_AR',
    es_CL: 'es_ES',
    es_CO: 'es_ES',
    es_PE: 'es_ES',
    es_ES: 'es_ES',
    es_MX: 'es_MX',
    fr_BE: 'fr_FR',
    fr_CH: 'fr_FR',
    fr_FR: 'fr_FR',
    fr_CA: 'fr_CA',
    it_IT: 'it_IT',
    nl_BE: 'nl_NL',
    nl_NL: 'nl_NL',
    pt_BR: 'pt_BR',
    pt_PT: 'pt_PT',
    sv: 'sv',
};

/**
 * These are not supported via transifex officially but exist in this map for dev server purposes. Unclear why.
 */
type UnsupportedLanguageCode = 'en_IN' | 'en_SE' | 'nn_NO';

export const SUPPORTED_TLDS: {
    [tld: string]: LanguageCode | UnsupportedLanguageCode;
} = Object.freeze({
    at: 'de_AT',
    be: 'nl_BE',
    ca: 'en_CA',
    ch: 'de_CH',
    cl: 'es_CL',
    co: 'es_CO',
    'co.nz': 'en_NZ',
    'co.uk': 'en_GB',
    com: 'en_US',
    'com.ar': 'es_AR',
    'com.au': 'en_AU',
    'com.br': 'pt_BR',
    'com.mx': 'es_MX',
    'com.pe': 'es_PE',
    de: 'de_DE',
    dk: 'en_DK',
    es: 'es_ES',
    fi: 'en_FI',
    fr: 'fr_FR',
    hk: 'en_HK',
    ie: 'en_IE',
    in: 'en_IN',
    it: 'it_IT',
    nl: 'nl_NL',
    no: 'nn_NO',
    pt: 'pt_PT',
    se: 'sv',
    sg: 'en_SG',
});
