import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import {
    setupNotificationAutoDelay,
    clearNotificationTimeouts,
} from './helper';
import { ADD_OVERLAY_CONTROLS_NAMESPACE } from './constants';
// addOverlayControls is a HOC used to wrap a page component
// it adds the prop `overlayOptions` into the scope of the page component
// which is used in tandem with `withOverlayControls` to pass modal / dialog options
// from sub components
const addOverlayControls = (PageComponent) =>
    class AddOverlayControlsWrapper extends React.PureComponent {
        static childContextTypes = {
            showOverlay: PropTypes.func,
            hideOverlay: PropTypes.func,
            addOverlayNotification: PropTypes.func,
            hideOverlayNotification: PropTypes.func,
        };

        state = {
            overlayOptions: null,
        };

        // Add the showOverlay function in to the context tree
        getChildContext() {
            return {
                showOverlay: this._showOverlay.bind(this),
                hideOverlay: this._hideOverlay.bind(this),
                addOverlayNotification: this._addOverlayNotification.bind(this),
                hideOverlayNotification: this._hideOverlayNotification.bind(
                    this,
                ),
            };
        }

        // We need to reset overlay options to null but also pass the callback functions
        _handleClose(onClose = noop) {
            clearNotificationTimeouts(ADD_OVERLAY_CONTROLS_NAMESPACE);
            this.setState({ overlayOptions: null });
            onClose();
        }

        _handleAction(onAction = noop, preventClose) {
            if (!preventClose) {
                clearNotificationTimeouts(ADD_OVERLAY_CONTROLS_NAMESPACE);
                this.setState({ overlayOptions: null });
            }
            onAction();
        }

        // A function that is passed into context to be used directly
        // from within a component
        _showOverlay(kind, options) {
            let overlayOptions = null;

            if (options) {
                overlayOptions = {
                    ...options,
                    kind,
                    isShown: true,
                    onPrimaryAction: this._handleAction.bind(
                        this,
                        options.onPrimaryAction,
                        options.preventCloseOnPrimaryAction,
                    ),
                    onSecondaryAction: this._handleAction.bind(
                        this,
                        options.onSecondaryAction,
                        options.preventCloseOnSecondaryAction,
                    ),
                };

                // If we pass an onClose function the modal / dialog will show an 'X'
                // this is not desired for our case
                if (options.onClose) {
                    overlayOptions = {
                        ...overlayOptions,
                        onClose: this._handleClose.bind(this, options.onClose),
                    };
                }
            }

            // manage the state of the modal within this component so that the developer
            // using an overlay within the structure does not have to
            this.setState({ overlayOptions });
        }

        _hideOverlay() {
            this._handleClose();
        }

        _addOverlayNotification(notificationOptions = {}) {
            // Need to clear any lingering timeouts
            clearNotificationTimeouts(ADD_OVERLAY_CONTROLS_NAMESPACE);

            // managing the state of the notificationBar w/in this HOC so that
            // components further down the tree don't have to
            const shouldPersist = notificationOptions?.shouldPersist;
            const callAction = notificationOptions?.callAction;
            const onClose = notificationOptions?.onClose;

            if (this.state.overlayOptions) {
                this.setState(
                    ({ overlayOptions: prevOverlayOptions = {} }) => ({
                        overlayOptions: {
                            ...prevOverlayOptions,
                            notificationOptions: {
                                hasCloseButton: true,
                                ...notificationOptions,

                                // Need to always pass in `onClose` so that if the notificationBar
                                // button is pressed this HOC can set its internal state.
                                // If an `onClose` was passed in, it'll get called.
                                onClose: this._hideOverlayNotification.bind(
                                    this,
                                    onClose,
                                ),
                            },
                        },
                    }),
                );

                // Self remove non-callAction notifications. Notifications
                // which have CTAs should not be auto removed
                if (!callAction && !shouldPersist) {
                    // Fire the notification removal sequence
                    setupNotificationAutoDelay(
                        this._animateClose,
                        this._hideOverlayNotification.bind(this, onClose),
                        ADD_OVERLAY_CONTROLS_NAMESPACE,
                    );
                }
            }
        }

        _hideOverlayNotification(onClose) {
            // Need to clear any lingering timeouts
            clearNotificationTimeouts(ADD_OVERLAY_CONTROLS_NAMESPACE);

            // NOTE: We could also set `notificationOptions` to undefined, but since the
            // notification is hidden, we don't *have* to clear out the content. that
            // would result in the markup also being cleared out. If the next time
            // the bottom bar is shown and the values end up being the same,
            // we could save some unnecessary DOM rewrites by *only* changing the
            // shown property

            // NOTE: using the callback form for `setState` since the new state
            // I want to set depends on the previous state. Because `setState`
            // is async this ensures that we're using the right version
            if (this.state.overlayOptions) {
                this.setState(
                    ({ overlayOptions: prevOverlayOptions = {} }) => ({
                        overlayOptions: {
                            ...prevOverlayOptions,
                            notificationOptions: undefined,
                        },
                    }),
                );
            }

            if (onClose) {
                onClose();
            }
        }

        _animateClose = (next) => {
            if (this.state.overlayOptions) {
                this.setState(
                    ({ overlayOptions: prevOverlayOptions = {} }) => ({
                        overlayOptions: {
                            ...prevOverlayOptions,
                            notificationOptions: {
                                ...prevOverlayOptions.notificationOptions,
                                isClosing: true,
                            },
                        },
                    }),
                    next,
                );
            }
        };

        // Pass overlayOptions back into the page component's scope as props
        render() {
            const { overlayOptions } = this.state;

            return (
                <PageComponent
                    overlayOptions={overlayOptions}
                    {...this.props}
                />
            );
        }
    };

export default addOverlayControls;
