
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`App OAuth Flow`}</MDXTag>
<MDXTag name="p" components={components}>{`Integrating your app requires authenticating and securing access to the
Eventbrite API. This involves both authenticating your personal access, and
authenticating access for the users of your app.`}</MDXTag>
<MDXTag name="p" components={components}>{`OAuth Authorize URL: `}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/authorize`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`OAuth Access token URL: `}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/token`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Personal Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To integrate your app, you must authenticate your personal access to the
Eventbrite API. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Access to the Eventbrite API for Users of Your App`}</MDXTag>
<MDXTag name="p" components={components}>{`Since you’re also accessing the API on behalf of Users other than yourself, you
need to use the OAuth Token flow for each of your application Users. The flow is
slightly different depending on whether your application is client-side or
server-side.`}</MDXTag>
<MDXTag name="p" components={components}>{`To authenticate Users via the API, make sure you have the following information,
available from your Eventbrite App Management page.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Name`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`App Key`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Identifies your app during the OAuth handshake. Not secret.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Client Secret`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Identifies your app during a server-side handshake. Secret.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Redirect URI`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`URI to which Users are redirected once they confirm access to your app. You need to set this in the Eventbrite app settings.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Note: If the User's OAuth Token expires (which may happen, if for example the
User changes their password), you'll receive an error. In this instance, your
User must again go through the OAuth Token Flow. However if the User still has
confirmed access to your application, they won't be required to do that step
again.`}</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Authenticating Users of a Client-Side Application`}</MDXTag>
<MDXTag name="p" components={components}>{`To authenticate a User from a client-side (JavaScript) application, use the
following URL as your Redirect URI`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=YOUR_APP_KEY&redirect_uri=YOUR_URL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The OAuth Token flow for a client-side application is:`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`Your Users are directed from your website or application to the Eventbrite
website.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`On the Eventbrite website, the User indicates their desire to use your
application, by using the Approve/Deny page.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`The User is redirected back to your application or website, as specified by
the Redirect URI.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`If the User has approved access to your application, an OAuth token is
included in the hash fragment of the URL.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`You store the User's token, and then use it to access the API on their
behalf.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Authenticating Users of a Server-Side Application`}</MDXTag>
<MDXTag name="p" components={components}>{`To authenticate a User from a server-side application, use the following URL as
your Redirect URI`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=YOUR_APP_KEY`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The OAuth Token flow for a server-side application is:`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`Your Users are directed from your website or application to the Eventbrite
website.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`On the Eventbrite website, the User indicates their desire to use your
application, by using the Approve/Deny page.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`The User is redirected back to your application or website, as specified by
the Redirect URI.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`If the User has approved access to your application, a code query parameter
is appended to the end of the URL representing their access code.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`Exchange the access code for an OAuth token by sending a `}<MDXTag name="inlineCode" components={components} parentName="p">{`POST`}</MDXTag>{` request to
`}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/token`}</MDXTag>{`. The request returns the User's
token.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`You store the User's token, and then use it to access the API on their
behalf.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Note: The `}<MDXTag name="inlineCode" components={components} parentName="p">{`POST`}</MDXTag>{` request used in step 5 must contain the following urlencoded
data, along with the Content-type header `}<MDXTag name="inlineCode" components={components} parentName="p">{`application/x-www-form-urlencoded`}</MDXTag></MDXTag>
</MDXTag>
{/* ```
code=THE_USERS_AUTH_CODE&client_secret=YOUR_CLIENT_SECRET&client_id=YOUR_API_KEY&grant_type=authorization_code
``` */}
<NextSteps
    steps={[
        {
            title: 'Integrate with Eventbrite Manage',
            link: '/platform/docs/integrate-your-app',
        },
    ]}
/>
           </MDXTag>
  }
}
  