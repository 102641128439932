import has from 'lodash/has';
import kebabCase from 'lodash/kebabCase';
import {
    VECTOR_IMAGE_ICON,
    VECTOR_IMAGE_BRAND,
    VECTOR_IMAGE_PAYMENT,
} from '@eb/eds-vector-image';

const VECTOR_TYPE_TESTS = {
    [VECTOR_IMAGE_BRAND]: (name) => name.includes(`-${VECTOR_IMAGE_BRAND}`),
    [VECTOR_IMAGE_PAYMENT]: (name) => name.includes(`-${VECTOR_IMAGE_PAYMENT}`),
};
const SVG_COMPONENT_NAME_MATCH = /^(.+)-svg$/;

/**
 * Formats name by kebab-casing and then stripping off starting "svg-"
 * @param {string} svgComponentName PascalCase SVG component name
 * @returns {string} formatted named
 */
const formatSvgComponentName = (svgComponentName) => {
    const kebabCaseName = kebabCase(svgComponentName);
    const [, strippedName] = kebabCaseName.match(SVG_COMPONENT_NAME_MATCH);

    return strippedName;
};

/**
 * Whether or not the specified matchers match the specified icon type
 * @param {import('react').ReactElement|string} iconType Icon type
 * @param {(svgComponentName: string) => boolean} svgNameMatcher Matcher when iconType is an SVG Component
 * @returns {boolean}
 */
export const iconNameMatches = (iconType, svgNameMatcher) =>
    has(iconType, 'type.displayName') &&
    svgNameMatcher(formatSvgComponentName(iconType.type.displayName));

/**
 * Gets the type of vector image based on the specified icon type
 * (NOTE: This is only intended for icons, not graphics or illustrations)
 * @param {import('react').ReactElement} iconType Icon type
 * @returns {'icon'|'brand'|'payment'}
 */
export const getIconVectorType = (iconType) => {
    const foundVectorType = Object.keys(VECTOR_TYPE_TESTS).find(
        (vectorType) => {
            const svgTest = VECTOR_TYPE_TESTS[vectorType];

            return iconNameMatches(iconType, svgTest);
        },
    );

    return foundVectorType || VECTOR_IMAGE_ICON;
};
