import { last } from 'lodash';
import { HAS_INDEPENDENT_SCROLL } from '../constants/constants';
import { findKeyForPath } from './nav';

/**
 * Takes a path and checks whether or not to set independent scroll
 *
 * @param {*} path ex: '/api-reference'
 */
export const getScrollInfo = (path) => {
    const pathKey = findKeyForPath(path) || last(path.split('/'));

    return HAS_INDEPENDENT_SCROLL[pathKey] || false;
};
