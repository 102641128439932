import keyBy from 'lodash/keyBy';
import { gettext } from '@eventbrite/i18n';
import {
    AT_LOCALE,
    DE_LOCALE,
    ES_LOCALE,
    FR_BE_LOCALE,
    FR_CA_LOCALE,
    FR_LOCALE,
    IT_LOCALE,
} from './locales';

// ported from https://github.com/eventbrite/currint/blob/7a38b1f4ef5bef984e79cab343211d950a20f730/currint/currency.py#L117-L285
const RAW_CURRENCY_INFO = [
    ['AED', '', '', '784', 100, false, gettext('UAE Dirham')],
    ['AFN', '\u060b', '', '971', 100, false, gettext('Afghani')],
    ['ALL', 'Lek', '', '008', 100, false, gettext('Lek')],
    ['AMD', '', '', '051', 100, false, gettext('Armenian Dram')],
    [
        'ANG',
        'ƒ',
        '',
        '532',
        100,
        false,
        gettext('Netherlands Antillean Guilder'),
    ],
    ['AOA', '', '', '973', 100, false, gettext('Kwanza')],
    ['ARS', '$', '$', '032', 100, false, gettext('Argentine Peso')],
    ['AUD', 'A$', '$', '036', 100, false, gettext('Australian Dollar')],
    ['AWG', 'ƒ', '', '533', 100, false, gettext('Aruban Florin')],
    ['AZN', 'ман', '', '944', 100, false, gettext('Azerbaijanian Manat')],
    ['BAM', 'KM', '', '977', 100, false, gettext('Convertible Mark')],
    ['BBD', '$', '', '052', 100, false, gettext('Barbados Dollar')],
    ['BDT', '', '', '050', 100, false, gettext('Taka')],
    ['BGN', 'лв', '', '975', 100, false, gettext('Bulgarian Lev')],
    ['BHD', '', '', '048', 1000, false, gettext('Bahraini Dinar')],
    ['BIF', '', '', '108', 1, false, gettext('Burundi Franc')],
    ['BMD', '$', '', '060', 100, false, gettext('Bermudian Dollar')],
    ['BND', '$', '', '096', 100, false, gettext('Brunei Dollar')],
    ['BOB', '$b', '', '068', 100, false, gettext('Boliviano')],
    ['BOV', '', '', '984', 100, false, gettext('Mvdol')],
    ['BRL', 'R$', 'R$', '986', 100, false, gettext('Brazilian Real')],
    ['BSD', '$', '', '044', 100, false, gettext('Bahamian Dollar')],
    ['BTN', '', '', '064', 100, false, gettext('Ngultrum')],
    ['BWP', 'P', '', '072', 100, false, gettext('Pula')],
    ['BYR', 'p.', '', '974', 1, false, gettext('Belarussian Ruble')],
    ['BZD', 'BZ$', '$', '084', 100, false, gettext('Belize Dollar')],
    ['CAD', 'CA$', '$', '124', 100, false, gettext('Canadian Dollar')],
    ['CDF', '', '', '976', 100, false, gettext('Congolese Franc')],
    ['CHE', '', '', '947', 100, false, gettext('WIR Euro')],
    ['CHF', 'Fr', 'Fr', '756', 100, true, gettext('Swiss Franc')],
    ['CHW', '', '', '948', 100, false, gettext('WIR Franc')],
    ['CLF', '', '', '990', 1, false, gettext('Unidad de Fomento')],
    ['CLP', '$', '', '152', 1, false, gettext('Chilean Peso')],
    ['CNY', '¥', '', '156', 100, false, gettext('Yuan Renminbi')],
    ['COP', '$', '', '170', 100, false, gettext('Colombian Peso')],
    ['COU', '', '', '970', 100, false, gettext('Unidad de Valor Real')],
    ['CRC', '₡', '', '188', 100, false, gettext('Costa Rican Colon')],
    ['CUC', '', '', '931', 100, false, gettext('Peso Convertible')],
    ['CUP', '₱', '', '192', 100, false, gettext('Cuban Peso')],
    ['CVE', '', '', '132', 100, false, gettext('Cape Verde Escudo')],
    ['CZK', 'Kč', 'Kč', '203', 100, false, gettext('Czech Koruna')],
    ['DJF', '', '', '262', 1, false, gettext('Djibouti Franc')],
    ['DKK', 'Dkr', 'kr', '208', 100, true, gettext('Danish Krone')],
    ['DOP', 'RD$', '', '214', 100, false, gettext('Dominican Peso')],
    ['DZD', '', '', '012', 100, false, gettext('Algerian Dinar')],
    ['EGP', '', '', '818', 100, false, gettext('Egyptian Pound')],
    ['ERN', '', '', '232', 100, false, gettext('Nakfa')],
    ['ETB', '', '', '230', 100, false, gettext('Ethiopian Birr')],
    ['EUR', '€', '€', '978', 100, false, gettext('Euro')],
    ['FJD', '$', '', '242', 100, false, gettext('Fiji Dollar')],
    ['FKP', '£', '', '238', 100, false, gettext('Falkland Islands Pound')],
    ['GBP', '£', '£', '826', 100, false, gettext('Pound Sterling')],
    ['GEL', '', '', '981', 100, false, gettext('Lari')],
    ['GHS', '', '', '936', 100, false, gettext('Ghana Cedi')],
    ['GIP', '£', '', '292', 100, false, gettext('Gibraltar Pound')],
    ['GMD', '', '', '270', 100, false, gettext('Dalasi')],
    ['GNF', '', '', '324', 1, false, gettext('Guinea Franc')],
    ['GTQ', 'Q', '', '320', 100, false, gettext('Quetzal')],
    ['GYD', '$', '', '328', 100, false, gettext('Guyana Dollar')],
    ['HKD', 'HK$', '$', '344', 100, false, gettext('Hong Kong Dollar')],
    ['HNL', 'L', '', '340', 100, false, gettext('Lempira')],
    ['HRK', 'kn', '', '191', 100, false, gettext('Croatian Kuna')],
    ['HTG', '', '', '332', 100, false, gettext('Gourde')],
    ['HUF', 'Ft', 'FT', '348', 100, false, gettext('Forint')],
    ['IDR', 'Rp', '', '360', 100, false, gettext('Rupiah')],
    ['ILS', '₪', '₪', '376', 100, false, gettext('New Israeli Sheqel')],
    ['INR', '₹', '', '356', 100, false, gettext('Indian Rupee')],
    ['IQD', '', '', '368', 1000, false, gettext('Iraqi Dinar')],
    ['IRR', '\ufdfc', '', '364', 100, false, gettext('Iranian Rial')],
    ['ISK', 'kr', '', '352', 1, false, gettext('Iceland Krona')],
    ['JMD', 'J$', '', '388', 100, false, gettext('Jamaican Dollar')],
    ['JOD', '', '', '400', 1000, false, gettext('Jordanian Dinar')],
    ['JPY', '\u00a5', '\u00a5', '392', 1, false, gettext('Yen')],
    ['KES', '', '', '404', 100, false, gettext('Kenyan Shilling')],
    ['KGS', 'лв', '', '417', 100, false, gettext('Som')],
    ['KHR', '៛', '', '116', 100, false, gettext('Riel')],
    ['KMF', '', '', '174', 1, false, gettext('Comoro Franc')],
    ['KPW', '₩', '', '408', 100, false, gettext('North Korean Won')],
    ['KRW', '', '', '410', 1, false, gettext('Won')],
    ['KWD', '', '', '414', 1000, false, gettext('Kuwaiti Dinar')],
    ['KYD', '$', '', '136', 100, false, gettext('Cayman Islands Dollar')],
    ['KZT', 'лв', '', '398', 100, false, gettext('Tenge')],
    ['LAK', '₭', '', '418', 100, false, gettext('Kip')],
    ['LBP', '£', '', '422', 100, false, gettext('Lebanese Pound')],
    ['LKR', '₨', '', '144', 100, false, gettext('Sri Lanka Rupee')],
    ['LRD', '$', '', '430', 100, false, gettext('Liberian Dollar')],
    ['LSL', '', '', '426', 100, false, gettext('Loti')],
    ['LTL', 'Lt', '', '440', 100, false, gettext('Lithuanian Litas')],
    ['LYD', '', '', '434', 1000, false, gettext('Libyan Dinar')],
    ['MAD', '', '', '504', 100, false, gettext('Moroccan Dirham')],
    ['MDL', '', '', '498', 100, false, gettext('Moldovan Leu')],
    ['MGA', '', '', '969', 5, false, gettext('Malagasy Ariary')],
    ['MKD', 'ден', '', '807', 100, false, gettext('Denar')],
    ['MMK', '', '', '104', 100, false, gettext('Kyat')],
    ['MNT', '$', '', '496', 100, false, gettext('Tugrik')],
    ['MOP', '', '', '446', 100, false, gettext('Pataca')],
    ['MRO', '', '', '478', 5, false, gettext('Ouguiya')],
    ['MUR', '₨', '', '480', 100, false, gettext('Mauritius Rupee')],
    ['MVR', '', '', '462', 100, false, gettext('Rufiyaa')],
    ['MWK', '', '', '454', 100, false, gettext('Kwacha')],
    ['MXN', 'Mex$', '$', '484', 100, false, gettext('Mexican Peso')],
    [
        'MXV',
        '',
        '',
        '979',
        100,
        false,
        gettext('Mexican Unidad de Inversion (UDI]'),
    ],
    ['MYR', 'RM', 'RM', '458', 100, false, gettext('Malaysian Ringgit')],
    ['MZN', 'MT', '', '943', 100, false, gettext('Mozambique Metical')],
    ['NAD', '$', '', '516', 100, false, gettext('Namibia Dollar')],
    ['NGN', '₦', '', '566', 100, false, gettext('Naira')],
    ['NIO', 'C$', '', '558', 100, false, gettext('Cordoba Oro')],
    ['NOK', 'Nkr', 'kr', '578', 100, true, gettext('Norwegian Krone')],
    ['NPR', '₨', '', '524', 100, false, gettext('Nepalese Rupee')],
    ['NZD', 'NZ$', '$', '554', 100, false, gettext('New Zealand Dollar')],
    ['OMR', '﷼', '', '512', 1000, false, gettext('Rial Omani')],
    ['PAB', 'B/.', '', '590', 100, false, gettext('Balboa')],
    ['PEN', 'S/.', '', '604', 100, false, gettext('Nuevo Sol')],
    ['PGK', '', '', '598', 100, false, gettext('Kina')],
    ['PHP', '₱', '₱', '608', 100, false, gettext('Philippine Peso')],
    ['PKR', '₨', '', '586', 100, false, gettext('Pakistan Rupee')],
    ['PLN', 'zł', 'zł', '985', 100, true, gettext('Zloty')],
    ['PYG', 'Gs', '', '600', 1, false, gettext('Guarani')],
    ['QAR', '﷼', '', '634', 100, false, gettext('Qatari Rial')],
    ['RON', 'lei', '', '946', 100, false, gettext('New Romanian Leu')],
    ['RSD', 'Дин.', '', '941', 100, false, gettext('Serbian Dinar')],
    ['RUB', 'руб', '', '643', 100, false, gettext('Russian Ruble')],
    ['RWF', '', '', '646', 1, false, gettext('Rwanda Franc')],
    ['SAR', '﷼', '', '682', 100, false, gettext('Saudi Riyal')],
    ['SBD', '$', '', '090', 100, false, gettext('Solomon Islands Dollar')],
    ['SCR', '₨', '', '690', 100, false, gettext('Seychelles Rupee')],
    ['SDG', '', '', '938', 100, false, gettext('Sudanese Pound')],
    ['SEK', 'kr', 'kr', '752', 100, true, gettext('Swedish Krona')],
    ['SGD', 'SGD$', 'SGD$', '702', 100, false, gettext('Singapore Dollar')],
    ['SHP', '£', '', '654', 100, false, gettext('Saint Helena Pound')],
    ['SLL', '', '', '694', 100, false, gettext('Leone')],
    ['SOS', 'S', '', '706', 100, false, gettext('Somali Shilling')],
    ['SRD', '$', '', '968', 100, false, gettext('Surinam Dollar')],
    ['SSP', '', '', '728', 100, false, gettext('South Sudanese Pound')],
    ['STD', '', '', '678', 100, false, gettext('Dobra')],
    ['SVC', '', '', '222', 100, false, gettext('El Salvador Colon')],
    ['SYP', '£', '', '760', 100, false, gettext('Syrian Pound')],
    ['SZL', '', '', '748', 100, false, gettext('Lilangeni')],
    ['THB', '฿', '฿', '764', 100, false, gettext('Baht')],
    ['TJS', '', '', '972', 100, false, gettext('Somoni')],
    ['TMT', '', '', '934', 100, false, gettext('Turkmenistan New Manat')],
    ['TND', '', '', '788', 1000, false, gettext('Tunisian Dinar')],
    ['TOP', '', '', '776', 100, false, gettext("Pa'anga")],
    ['TRY', '₺', '', '949', 100, false, gettext('Turkish Lira')],
    [
        'TTD',
        'TT$',
        '',
        '780',
        100,
        false,
        gettext('Trinidad and Tobago Dollar'),
    ],
    ['TWD', 'NT$', '$', '901', 100, false, gettext('New Taiwan Dollar')],
    ['TZS', '', '', '834', 100, false, gettext('Tanzanian Shilling')],
    ['UAH', '₴', '', '980', 100, false, gettext('Hryvnia')],
    ['UGX', '', '', '800', 1, false, gettext('Uganda Shilling')],
    ['USD', '$', '$', '840', 100, false, gettext('US Dollar')],
    [
        'UYI',
        '',
        '',
        '940',
        1,
        false,
        gettext('Uruguay Peso en Unidades Indexadas (URUIURUI]'),
    ],
    ['UYU', '$U', '', '858', 100, false, gettext('Peso Uruguayo')],
    ['UZS', 'лв', '', '860', 100, false, gettext('Uzbekistan Sum')],
    ['VEF', 'Bs', '', '937', 100, false, gettext('Bolivar')],
    ['VND', '₫', '', '704', 1, false, gettext('Dong')],
    ['VUV', '', '', '548', 1, false, gettext('Vatu')],
    ['WST', '', '', '882', 100, false, gettext('Tala')],
    ['XAF', '', '', '950', 1, false, gettext('CFA Franc BEAC')],
    ['XCD', '$', '', '951', 100, false, gettext('East Caribbean Dollar')],
    ['XOF', '', '', '952', 1, false, gettext('CFA Franc BCEAO')],
    ['XPF', '', '', '953', 1, false, gettext('CFP Franc')],
    ['YER', '﷼', '', '886', 100, false, gettext('Yemeni Rial')],
    ['ZAR', 'R', '', '710', 100, false, gettext('Rand')],
    ['ZMW', '', '', '967', 100, false, gettext('Zambian Kwacha')],
    ['ZWL', '', '', '932', 100, false, gettext('Zimbabwe Dollar')],
    ['XBT', '', '', '', 100000000, false, gettext('Bitcoin')],
];

/**
 * @typedef {{code: string, symbol: string, shortSymbol: string, name: string, numericCode: string, divisor: number, isSuffix: boolean }} CurrencyInfo
 */
/**
 * @type Object.<string, CurrencyInfo>
 */
export const CURRENCY_MAP = keyBy(
    RAW_CURRENCY_INFO.map((currencyTuple) => {
        var [
            code,
            symbol,
            shortSymbol,
            numericCode,
            divisor,
            isSuffix,
            name,
        ] = currencyTuple;

        return {
            code,
            symbol,
            shortSymbol,
            name,
            numericCode,
            divisor,
            isSuffix,
        };
    }),
    'code',
);

export const SYMBOL_PLACEHOLDER = '\u00A4';

export const PRECEDING_SPACE_PLACEHOLDER = '\u00A0\u00A4';
export const FOLLOWING_SPACE_PLACEHOLDER = '\u00A4\u00A0';
export const DECIMAL_REGEX = /0.00/;
export const THOUSANDS_REGEX = /#.##/;
export const THOUSANDS_GLOBAL_REGEX = /#/g;
export const THOUSANDS_GROUP_GLOBAL_REGEX = /(\d{3})(?=\d)/g;
export const ZERO_GLOBAL_REGEX = /0/g;
export const BRACKET_REGEX = /\((.*)\)/;

export const FORMAT_NUMBER_OPTIONS = ['decimal', 'thousand', 'precision'];

export const DEFAULT_THOUSANDS_NOTATION = ',';
export const DEFAULT_DECIMAL_NOTATION = '.';
export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_NUMBER_SETTINGS = {
    precision: 0,
    grouping: 3,
    thousand: ',',
    decimal: '.',
};
export const DEFAULT_CURRENCY_SETTINGS = {
    symbol: '$',
    format: '%s%v',
    decimal: '.',
    thousand: ',',
    precision: 2,
    grouping: 3,
};

export const DEFAULT_CURRENCY_FORMAT = '#,##0.00';
export const US_CURRENCY_FORMAT = '¤#,##0.00';
export const DE_CURRENCY_FORMAT = '#.##0,00 ¤';

export const CURRENCY_SYMBOL_IS_SUFFIX_FOR_LOCALE = {
    [AT_LOCALE]: true,
    [DE_LOCALE]: true,
    [ES_LOCALE]: true,
    [FR_BE_LOCALE]: true,
    [FR_CA_LOCALE]: true,
    [FR_LOCALE]: true,
    [IT_LOCALE]: true,
};
