
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Data mapping for the Mailchimp App`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Field`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Value`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Mailchimp eCommerce Stores`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_organization.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_organization.name`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`currency_code`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_first_event.currency`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`list_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`selected Mailchimp list`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Mailchimp eCommerce Products`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`title`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.start.uppercase(ddd, MMM Do) - eb_event.name`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`image`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.logo.original.url`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`URL`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.url`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`vendor`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.category - eb_event.subcategory`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`price`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.min_ticket_price - eb_event.max_ticket_price`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Mailchimp eCommerce Product Variants`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_ticket_class.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_ticket_class.name`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`price`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_ticket_class.price`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Mailchimp eCommerce Product Customers`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Mailchimp_member_subscriber_hash`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`first_name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.first_name`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`last_name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.last_name`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`email_address`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.email`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`opt_in_status`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`true if opt-in`}{`*`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="strong" components={components} parentName="td">{`Mailchimp eCommerce Product Orders`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`customer_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Mailchimp_member_subscriber_hash`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`store_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.organization_id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`order_total`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.costs.gross.value`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`currency_code`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.currency`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`line.item_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`line.product_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`line.product_variant_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.ticket_class.id`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`line.quantity`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`1`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`line.price`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_event.ticket_class.price`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`line.discount`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`0`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`foreign_processed_time`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`eb_order.created`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` All buyers are opt-in prior to February 26, 2020. After February 26,
2020, only buyers who opt-in to receive marketing communication during Event
checkout will be synced to Mailchimp.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Mailchimp Audience Members`}</MDXTag>
<MDXTag name="p" components={components}>{`Mailchimp Members are created based on the email address that was used to place
the Eventbrite Order.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Mailchimp Audience Member Tags`}</MDXTag>
<MDXTag name="p" components={components}>{`Member Tags are created for the following Eventbrite fields:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`event.category.name`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`event.subcategory.name`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`event.format.name`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`"Event: " + event.name.text`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`"Organizer: " + event.organizer.name`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`"Venue: " + event.venue.name`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`"Artist: " + artist.name`}</MDXTag>
</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Mailchimp app product page',
            link: '/apps/mailchimp',
        },
    ]}
/>
           </MDXTag>
  }
}
  