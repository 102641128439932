
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Embedding a Checkout Widget`}</MDXTag>
<MDXTag name="p" components={components}>{`A ticket checkout flow may involve the following steps `}<MDXTag name="inlineCode" components={components} parentName="p">{`checkout`}</MDXTag>{` ->
`}<MDXTag name="inlineCode" components={components} parentName="p">{`lookup order`}</MDXTag>{` -> `}<MDXTag name="inlineCode" components={components} parentName="p">{`cancel/refund order`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`The first step in this flow is checkout. Using the Eventbrite API, you can embed
a ticket checkout widget in your app or website. This is done by including HTML
code with a Javascript call and the appropriate attributes. The Javascript call
is`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/static/widgets/eb_widgets.js`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`and the attributes are`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Attribute`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Type`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`widgetType`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Value must be `}<MDXTag name="inlineCode" components={components} parentName="td">{`checkout`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`eventId`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Event ID.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`modal`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`boolean`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`True = Render the widget.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`modalTriggerElementId`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Target HTML element for the widget.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`onOrderComplete`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`function`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Callback when an Order is completed.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`iFrameContainerId`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`string`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`(Optional) Target HTML element for the widget.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`iFrameContainerHeight`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`integer`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`(Optional) Widget height in pixels. Default is 425px.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`iFrameAutoAdapt`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`integer`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`(Optional) Choose the widget's viewport percentage (between 75-100).`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating a Popup Checkout Widget`}</MDXTag>
<MDXTag name="p" components={components}>{`You can display your checkout as a modal popup, meaning that the checkout flow
is hidden from display until the user makes a click. Using the following HTML
code, a Buy Tickets button is embedded on the app or website page, and when
clicked shows the checkout flow.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-html"}}>{`<button id="example-widget-trigger" type="button">Buy Tickets</button>

<script src="https://www.eventbrite.com/static/widgets/eb_widgets.js"></script>

<script type="text/javascript">
    var exampleCallback = function () {
        console.log('Order complete!');
    };

    window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: '52766401728',
        modal: true,
        modalTriggerElementId: 'example-widget-trigger',
        onOrderComplete: exampleCallback,
    });
</script>
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Creating an Non-Modal Checkout`}</MDXTag>
<MDXTag name="p" components={components}>{`If you want your users to checkout without having to click on anything first,
you can create a non-modal checkout. For example, the following HTML code embeds
the checkout widget on your app or website page.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-html"}}>{`<div id="example-widget-trigger"></div>

<script src="https://www.eventbrite.com/static/widgets/eb_widgets.js"></script>

<script type="text/javascript">
    var exampleCallback = function () {
        console.log('Order complete!');
    };

    window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: '52766401728',
        iframeContainerId: 'example-widget-trigger',
        iframeContainerHeight: 425,
        onOrderComplete: exampleCallback,
    });
</script>
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`FAQ`}</MDXTag>
<MDXTag name="p" components={components}>{`The following are some common questions on embedding a checkout widget.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Can the colors and layout of the checkout widget be customized?`}</MDXTag>{` \
No. But you can customize the button the user clicks to start the checkout flow,
such as the Buy Tickets button.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Can I choose which page is first displayed in the checkout flow (for example,
I want to skip the default first page, i.e. the ticket selection page)?`}</MDXTag>{` \
No. But you can implement a seperate button for each ticket type, and that button
can contain a unique description.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`What’s the best configuration for mobile devices?`}</MDXTag>{` \
For mobile devices use a modal button with a popup checkout flow. The button makes
use of the full screen in mobile sizes to provide optimal layout.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Can I choose the language (i.e. other than English) displayed in the checkout
flow?`}</MDXTag>{` \
The checkout flow language cannot be changed through tooling, instead update the
appropriate Event fields for the country and lanaguage you want.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Can a promo code be passed to the checkout flow and automatically applied?`}</MDXTag>{` \
Yes. You need to use the key `}<MDXTag name="inlineCode" components={components} parentName="p">{`promoCode`}</MDXTag>{` when calling `}<MDXTag name="inlineCode" components={components} parentName="p">{`createWidget`}</MDXTag>{`. For example:
`}<MDXTag name="inlineCode" components={components} parentName="p">{`"promoCode": "mypromocode"`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Can I track sales through Google Analytics or Facebook pixels?`}</MDXTag>{` \
Yes. You need to set up sales tracking using your App Management page.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Can I embed the checkout flow on my WordPress website?`}</MDXTag>{` \
Yes. For more information, see `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/support/articles/en_US/How_To/how-to-add-eventbrite-s-embedded-checkout-to-your-wordpress-org-site?lg=en_US"}}>{`How to add Eventbrite’s Embedded Checkout to your WordPress.org site`}</MDXTag>{`.`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Cancelling and Refunding Orders',
            link: '/platform/docs/cancelling-and-refunding-orders',
        },
        {
            title: 'Finding Information on an Order',
            link: '/platform/docs/order-lookup',
        },
    ]}
/>
           </MDXTag>
  }
}
  