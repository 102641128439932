import React from 'react';
import classNames from 'classnames';

import { COLLAPSIBLE_PANE_LAYOUT_PROP_TYPES } from './constants';

import './collapsible_pane_layout.scss';

export default class CollapsiblePaneLayout extends React.PureComponent {
    static propTypes = COLLAPSIBLE_PANE_LAYOUT_PROP_TYPES;

    render() {
        const { content, isPaneOpen, pane: paneContent } = this.props;

        const contentClasses = classNames(
            'eds-collapsible-pane-layout__content',
            {
                'eds-collapsible-pane-layout__content--has-pane': paneContent,
            },
        );

        let pane = null;

        if (paneContent) {
            const paneClasses = classNames(
                'eds-collapsible-pane-layout__pane',
                {
                    'eds-collapsible-pane-layout__pane--open': isPaneOpen,
                },
            );

            pane = (
                <div
                    className={paneClasses}
                    data-spec="eds-collapsible-pane-layout-pane"
                >
                    {paneContent}
                </div>
            );
        }

        return (
            <div className="eds-collapsible-pane-layout">
                <div className={contentClasses}>{content}</div>
                {pane}
            </div>
        );
    }
}
