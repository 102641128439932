
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Rate Limits`}</MDXTag>
<MDXTag name="p" components={components}>{`By default, Eventbrite enforces a rate limit on API calls for all integrated
applications.`}</MDXTag>
<MDXTag name="p" components={components}>{`The default limit is:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`2,000 calls/hour`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`48,000 calls/day`}</MDXTag>
</MDXTag>
<NextSteps
    steps={[
        {
            title: 'App OAuth Flow',
            link: '/platform/docs/app-oauth-flow',
        },
        {
            title: 'Embedding a Checkout Flow',
            link: '/platform/docs/embedded-checkout',
        },
    ]}
/>
           </MDXTag>
  }
}
  