import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eventbrite/i18n';
import { Icon } from '@eb/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { LoadingOverlay } from '@eb/eds-loading-overlay';
import { Modal } from '@eb/eds-modal';

import { TYPE_SIMPLE } from '@eb/eds-modal';
import {
    DIALOG_MESSAGE_PROPTYPE,
    DIALOG_TITLE_PROPTYPE,
    DIALOG_TITLE_ID,
    DIALOG_MESSAGE_ID,
} from './constants';
import { ICON_TYPE_PROP_TYPE } from '@eb/eds-icon';

import './dialog.scss';

const HeaderIcon = ({ headerIconType }) => {
    let component = null;

    if (headerIconType) {
        component = (
            <div
                className="eds-dialog__icon eds-align--center"
                data-spec="eds-dialog__icon"
            >
                <Icon type={headerIconType} size="small" hasHalo={true} />
            </div>
        );
    }

    return component;
};

const TitleHeader = ({ title }) => {
    let component = null;

    if (title) {
        component = (
            <h2
                className="
                    eds-dialog__title
                    eds-l-mar-top-3
                    eds-l-pad-hor-5
                    eds-align--center
                    eds-text--center
                    eds-text-hs
                    eds-text-weight--light"
                data-spec="eds-dialog__title"
                id={DIALOG_TITLE_ID}
            >
                {title}
            </h2>
        );
    }

    return component;
};

const MessageParagraph = ({ message }) => {
    const className = classNames(
        'eds-dialog__message',
        'eds-l-mar-top-3',
        'eds-l-mar-bot-4',
        'eds-text-bm',
        'eds-text-color--grey-600',
        'eds-text--center',
    );

    let component = null;

    if (message) {
        component = (
            <p className={className} id={DIALOG_MESSAGE_ID}>
                {message}
            </p>
        );
    }

    return component;
};

const ButtonBar = ({
    primaryIsDisabled,
    primaryType,
    primaryText,
    onPrimaryAction,
    secondaryIsDisabled,
    secondaryType,
    secondaryText,
    onSecondaryAction,
    title,
}) => {
    const buttonBarClasses = classNames(
        'eds-dialog__button-bar',
        'eds-align--center',
        {
            'eds-dialog__button-bar--with-title': title,
        },
    );

    let secondaryButton = null;

    if (secondaryText) {
        secondaryButton = (
            <div className="eds-dialog__secondary-button">
                <Button
                    disabled={secondaryIsDisabled}
                    onClick={onSecondaryAction}
                    style={secondaryType}
                    data-spec="eds-dialog__secondary-button"
                >
                    {secondaryText}
                </Button>
            </div>
        );
    }

    let component = null;

    if (primaryText) {
        component = (
            <div className={buttonBarClasses}>
                {secondaryButton}
                <div className="eds-dialog__primary-button">
                    <Button
                        disabled={primaryIsDisabled}
                        onClick={onPrimaryAction}
                        style={primaryType}
                        data-spec="eds-dialog__primary-button"
                    >
                        {primaryText}
                    </Button>
                </div>
            </div>
        );
    }

    return component;
};

/**
 * A Dialog is a small window that communicates information to the user and prompts them for a response.
 */
export default class Dialog extends React.PureComponent {
    static propTypes = {
        /**
         * The type of icon displayed in the center of the modal
         */
        headerIconType: ICON_TYPE_PROP_TYPE,
        /**
         * The illustration displayed inside the dialog
         */
        illustration: PropTypes.node,
        /**
         * Is the dialog closable?
         */
        isClosable: PropTypes.bool,
        /**
         * Should the dialog show the loading overlay?
         */
        isLoading: PropTypes.bool,
        /**
         * Is the dialog currently shown?
         */
        isShown: PropTypes.bool,
        /**
         * The message body of a dialog
         */
        message: DIALOG_MESSAGE_PROPTYPE.isRequired,
        /**
         * A function with the desired behavior on close
         */
        onClose: PropTypes.func,
        /**
         * The function that should be triggered on primary button click
         */
        onPrimaryAction: PropTypes.func,
        /**
         * The function that should be triggered on secondary button click
         */
        onSecondaryAction: PropTypes.func,
        /**
         * Whether the primary button should be disabled
         */
        primaryIsDisabled: PropTypes.bool,
        /**
         * The text that should be shown across the primary button
         */
        primaryText: translationPropType,
        /**
         * The type of button that should be shown for primary button
         */
        primaryType: PropTypes.string,
        /**
         * Whether the secondary button should be disabled
         */
        secondaryIsDisabled: PropTypes.bool,
        /**
         * The text that should be shown across the secondary button
         */
        secondaryText: translationPropType,
        /**
         * The type of button that should be shown for secondary button
         */
        secondaryType: PropTypes.string,
        /**
         * The title on top of the message
         */
        title: DIALOG_TITLE_PROPTYPE,
    };

    render() {
        const {
            headerIconType,
            illustration,
            isLoading,
            isShown,
            message,
            onClose,
            onPrimaryAction,
            onSecondaryAction,
            primaryIsDisabled,
            primaryType,
            primaryText,
            secondaryIsDisabled,
            secondaryType,
            secondaryText,
            title,
        } = this.props;

        const className = classNames('eds-dialog', {
            'eds-dialog--illustration': illustration,
        });

        return (
            <Modal
                type={TYPE_SIMPLE}
                illustration={illustration}
                isShown={isShown}
                onClose={onClose}
                noMinHeight={true}
                dangerouslySetContainerClassName={{ __className: className }}
                ariaLabelledby={DIALOG_TITLE_ID}
                ariaDescribedby={DIALOG_MESSAGE_ID}
            >
                <LoadingOverlay size="large-chunky" isShown={isLoading} />
                <HeaderIcon headerIconType={headerIconType} />
                <TitleHeader title={title} />
                <MessageParagraph message={message} title={title} />
                <ButtonBar
                    primaryIsDisabled={primaryIsDisabled}
                    primaryType={primaryType}
                    primaryText={primaryText}
                    onPrimaryAction={onPrimaryAction}
                    secondaryIsDisabled={secondaryIsDisabled}
                    secondaryType={secondaryType}
                    secondaryText={secondaryText}
                    onSecondaryAction={onSecondaryAction}
                    onClose={onClose}
                    title={title}
                />
            </Modal>
        );
    }
}
