import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@eventbrite/eds-button';
import './footerPromo.scss';

export default class FooterPromo extends PureComponent {
    static propTypes = {
        /*
        Heading text
        */
        heading: PropTypes.string,
        /*
        Subheading text
        */
        subHeading: PropTypes.string,
        /*
        Button text
        */
        btnText: PropTypes.string,
        /*
        Url for button link
        */
        btnLink: PropTypes.string,
    };

    render() {
        const { heading, subHeading, btnText, btnLink } = this.props;

        return (
            <div className="footer-promo eds-l-pad-hor-10 eds-l-sn-pad-hor-5 eds-l-sm-pad-hor-5 eds-l-sw-pad-hor-5  eds-l-pad-top-20 eds-l-pad-bot-20">
                <div className="eds-g-group marketing-container">
                    <div className="eds-g-cell-12-12">
                        <h1 className="eds-text-hxl">
                            {heading}
                            <span className="eds-text-hl">{subHeading}</span>
                        </h1>
                        <div className="eds-text-bs eds-text-color--white eds-l-mar-top-4">
                            <Button type="link" href={btnLink}>
                                {btnText}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
