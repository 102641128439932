
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`App Approval Process`}</MDXTag>
<MDXTag name="h2" components={components}>{`1. Review Partner Program Requirements`}</MDXTag>
<MDXTag name="p" components={components}>{`To begin the application process,
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/l/partner-program/"}}>{`review the partner program`}</MDXTag>{` information, qualifications,
and requirements.`}</MDXTag>
<MDXTag name="h2" components={components}>{`2. Submit App for Review`}</MDXTag>
<MDXTag name="h3" components={components}>{`Add Directory Listing information`}</MDXTag>
<MDXTag name="p" components={components}>{`After creating your API Key, you can go to Directory Listing tab in
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/account-settings/apps"}}>{`App Management`}</MDXTag>{`. There, you'll be able to send the
details of your app, including an icon and banner image to show in the
Eventbrite App Marketplace. In this tab, you will also be able to select your
app's category, event category and type of service. You can choose to save your
changes as a draft or add additional languages.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Adding Languages`}</MDXTag>
<MDXTag name="p" components={components}>{`Upon saving your app's basic information, you will be able to add the supported
languages and your app's details in those languages. In this section, you will
add a URL to "Get Started", a short description, an optional long description
and other components like screenshots and support information that will help
event organizers understand the value of your app.`}</MDXTag>
<MDXTag name="h3" components={components}>{`App Submission`}</MDXTag>
<MDXTag name="p" components={components}>{`Once your app is ready to be in the Eventbrite App Marketplace, submit it to
review by clicking the "Submit for Review" button. That will change your app's
status from `}<MDXTag name="inlineCode" components={components} parentName="p">{`Unsaved`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="p">{`Draft`}</MDXTag>{` to `}<MDXTag name="inlineCode" components={components} parentName="p">{`Eventbrite Pending Action`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are three statuses which your app directory listing can be set to after
review: `}<MDXTag name="inlineCode" components={components} parentName="p">{`Developer pending action`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`Approved`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`Rejected`}</MDXTag>{`. You will receive
an email when there is a status update, and it will contain the information
regarding the new status.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Name`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Eventbrite pending action`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app submission will be reviewed by Eventbrite`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Approved`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app is approved and is now listed in the Eventbrite App Marketplace`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Rejected`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app submission wasn't approved`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Developer pending action`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app submission has issues that must be addressed and resubmitted before being approved`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`App Extension`}</MDXTag>
<MDXTag name="p" components={components}>{`To show the "Install" button in on your app's product page, you will need to
complete the "App Extension" step. In `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/account-settings/apps"}}>{`App Management`}</MDXTag>{`,
go to the App Extension tab to find a URL field — this is the app iframe's URL.
Add your app's URL and save to submit for review.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are two statuses which your app extension can be set to after review:
`}<MDXTag name="inlineCode" components={components} parentName="p">{`Approved`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`Declined`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Name`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Pending review`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app extension submission will be reviewed by Eventbrite`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Approved`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app extension is approved and can now be installed`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><MDXTag name="inlineCode" components={components} parentName="td">{`Declined`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Your app extension submission wasn't approved`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<NextSteps
    steps={[
        {
            title: 'App OAuth Flow',
            link: '/platform/docs/app-oauth-flow',
        },
    ]}
/>
           </MDXTag>
  }
}
  