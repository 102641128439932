import * as Sentry from '@sentry/react';
import { CaptureContext, Context, Severity } from '@sentry/types';

const metaDataToContext = (metaData?: Context): CaptureContext => {
    if (!metaData) return {};
    return {
        contexts: {
            metaData,
        },
    };
};

const error = (exception: any, metaData?: Context) => {
    return Sentry.captureException(exception, metaDataToContext(metaData));
};

const warn = (exception: any, metaData: Context = {}) => {
    return Sentry.captureMessage(exception, {
        ...metaDataToContext(metaData),
        level: Severity.Warning,
    });
};

const info = (exception: any, metaData: Context = {}) => {
    return Sentry.captureMessage(exception, {
        ...metaDataToContext(metaData),
        level: Severity.Info,
    });
};

// Create a logger object that abstracts out error, warn, and info functions
// and supports the native Sentry functionality
export const logger = {
    ...Sentry,
    error,
    warn,
    info,
};

export default logger;
