import { Icon } from '@eb/eds-icon';
import { ChevronDownChunky } from '@eb/eds-iconography';
import { Button } from '@eventbrite/eds-button';
import React, { useEffect, useRef, useState } from 'react';
import { useScrollWatcher } from './hooks';

export interface BarButtonProps {
    onClick(): void;
    title?: string;
}

export const BarButton: React.FunctionComponent<BarButtonProps> = (props) => {
    const { onClick, title } = props;
    const [isSticky, setIsSticky] = useState(false);
    const [height, setHeight] = useState(36);
    const buttonRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (buttonRef && buttonRef.current)
            setHeight(buttonRef.current.offsetHeight);
    }, [buttonRef]);

    useScrollWatcher(
        (pos) => {
            const shouldStick = pos > height;
            if (isSticky !== shouldStick) {
                setIsSticky(shouldStick);
            }
        },
        {
            deps: [isSticky, height],
            interval: 10,
            selector: '#edsModalContentChildren',
        },
    );

    return (
        <Button
            data-testid="modal-close-bar-button"
            style="none"
            title={title}
            onClick={onClick}
        >
            <div
                ref={buttonRef}
                className={`eds-modal__bar-button ${
                    isSticky ? `eds-modal__bar-button--sticky` : ''
                }`}
            >
                <Icon size="medium" type={<ChevronDownChunky />} />
            </div>
        </Button>
    );
};
