import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { makePropType } from '@eventbrite/eds-utils';

import { ICON_TYPE_PROP_TYPE } from '@eb/eds-icon';

const DIALOG_MESSAGE_TYPE = (props, propName, componentName) => {
    if (props.message > 32) {
        return new Error(
            `Invalid prop ${propName} suppied to ${componentName}. content must be 32 characters or less.`,
        );
    }

    return null;
};

const DIALOG_TITLE_TYPE = (props, propName, componentName) => {
    if (props.title > 180) {
        return new Error(
            `Invalid prop ${propName} suppied to ${componentName}. content must be 180 characters or less.`,
        );
    }

    return null;
};

export const DIALOG_MESSAGE_PROPTYPE = makePropType(DIALOG_MESSAGE_TYPE);

export const DIALOG_TITLE_PROPTYPE = makePropType(DIALOG_TITLE_TYPE);

export const DIALOG_PROP_TYPES = {
    /**
     * The type of icon displayed in the center of the modal
     */
    headerIconType: ICON_TYPE_PROP_TYPE,
    /**
     * The illustration displayed inside the dialog
     */
    illustration: PropTypes.node,
    /**
     * Is the dialog closable?
     */
    isClosable: PropTypes.bool,
    /**
     * Should the dialog show the loading overlay?
     */
    isLoading: PropTypes.bool,
    /**
     * Is the dialog currently shown?
     */
    isShown: PropTypes.bool,
    /**
     * The message body of a dialog
     */
    message: DIALOG_MESSAGE_PROPTYPE.isRequired,
    /**
     * A function with the desired behavior on close
     */
    onClose: PropTypes.func,
    /**
     * The function that should be triggered on primary button click
     */
    onPrimaryAction: PropTypes.func,
    /**
     * The function that should be triggered on secondary button click
     */
    onSecondaryAction: PropTypes.func,
    /**
     * The text that should be shown across the primary button
     */
    primaryText: translationPropType,
    /**
     * The type of button that should be shown for primary button
     */
    primaryType: PropTypes.string,
    /**
     * The text that should be shown across the secondary button
     */
    secondaryText: translationPropType,
    /**
     * The type of button that should be shown for secondary button
     */
    secondaryType: PropTypes.string,
    /**
     * The title on top of the message
     */
    title: DIALOG_TITLE_PROPTYPE,
};

export const DIALOG_TITLE_ID = 'eds-dialog-content-title';

export const DIALOG_MESSAGE_ID = 'eds-dialog-content-message';

export const DIALOG_OPTIONS_PROP_TYPES = PropTypes.shape(DIALOG_PROP_TYPES);
