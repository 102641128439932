import PropTypes from 'prop-types';
import { flow, filter, map } from 'lodash/fp';
import { COLORS_PROP_TYPE } from '@eventbrite/eds-color';
import { SVG_PROP_TYPE } from '@eb/eds-vector-image';

import { SIZES_JSON } from '@eb/eds-iconography';

import { Check } from '@eb/eds-iconography';
import { CheckChunky } from '@eb/eds-iconography';

export const SIZES = flow(
    filter((size) => size.startsWith('icon-') && !size.includes('halo')),
    map((size) => size.replace('icon-', '')),
)(Object.keys(SIZES_JSON));

export const ICON_TYPE_PROP_TYPE = SVG_PROP_TYPE;
export const ICON_SIZE_PROP_TYPE = PropTypes.oneOf(SIZES);
export const COLOR_PROP_TYPE = COLORS_PROP_TYPE;

export const STROKE_COLOR_ICONS = [Check, CheckChunky];
