
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Creating Online Event Page`}</MDXTag>
<MDXTag name="p" components={components}>{`The Online Event Page (previously known as Digital Links Page) is a feature that
allows online event organizers to create a separate landing page for attendees
of that event - and can include information pertinent to that event, such as
webinar links, documents, etc. The Online Event Page is similar to the event
description in that it is a page that is built using structured content. An
added bonus of the Online Event Page is that Eventbrite will automatically drive
attendees to this page, reducing the amount of email reminders that you must
send about the start of your online event.`}</MDXTag>
<MDXTag name="p" components={components}>{`From a product standpoint, creation of the Online Event Page by the event
creator is accessible via the create flow for online events via Digital Links
tab. Attendees can access the Online Event Page from
www.eventbrite.com/x/EVENT_ID. Once the Online Event Page is created by an event
organizer, attendees of the event will automatically receive access to the
Online Event Page in their order confirmation email and in reminder emails
leading up to the event.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Structured Content`}</MDXTag>
<MDXTag name="p" components={components}>{`Digital Links utilizes the concept of 'structured content' and 'modules' to
allow event creators to create a rich multi media page for their attendees.`}</MDXTag>
<MDXTag name="p" components={components}>{`Structured content works as an insert only system, so any time you want to
update module(s), you will need to resubmit all of the content you had
previously submitted plus any changes. Structured content also works as a
versioned system, so you will need to increment with a new version every time.`}</MDXTag>
<MDXTag name="p" components={components}>{`If you would like to learn more overall about structured content and the three
basic modules, please read
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/event-description"}}>{`Event Description`}</MDXTag>{`. This article is an
expansion on that article - both how to create structured content specifically
for digital links as well as modules that are available specifically for digital
links.`}</MDXTag>
<MDXTag name="p" components={components}>{`For more in depth about the api calls themselves, please reference
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content"}}>{`Structured Content API Reference`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Tickets restrictions`}</MDXTag>
<MDXTag name="p" components={components}>{`You can use this functionality to make some of your online content available
only to specific ticket types. It's available inside the Online Event Page and
can be set via the API for each type of online content module (example, a text
module).`}</MDXTag>
<MDXTag name="p" components={components}>{`As mentioned, to add those restrictions to the modules, you need first to create
a couple of ticket classes. If you are using the UI, this means going to the
step called "Tickets" that will use the following API (example:
`}<MDXTag name="inlineCode" components={components} parentName="p">{`/api/v3/events/{your event id}/ticket_classes/`}</MDXTag>{`). Once you create a new ticket
class, you can directly copy that ticket class's ID in the obtained response
from the API. You can access the ticket class id `}<MDXTag name="inlineCode" components={components} parentName="p">{`ticket_classes[0].id`}</MDXTag>{`. It will
return a numeric ID like this 176412629.`}</MDXTag>
<MDXTag name="p" components={components}>{`You can now use the structure content endpoint to assign that ticket class ID to
a Online Event Page Module. Once you do this, we will restrict the module to the
customers who bought any of the allowed ticket_classes. By default, if you don't
specify the list of available ticket_classes, every ticket holder will be able
to see a module.`}</MDXTag>
<MDXTag name="p" components={components}>{`Example: Let's see this in action.`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">{`Call the following endpoint for a given eventId
/api/v3/events/{eventId}/structured_content/6/`}</MDXTag>
<MDXTag name="li" components={components} parentName="ol">{`In the payload, you can add the display_restrictions:`}</MDXTag>
</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
  "modules": [
    {
      "type": "text",
      "timed_display": null,
      "display_restrictions": [
        {
          "restriction_type": "ticket_class",
          "permitted_value": "176412629"
        },
        {
          "restriction_type": "ticket_class",
          "permitted_value": "176412633"
        }
      ],
      "data": {
        "body": {
          "type": "text",
          "text": "<p>sdfsdf</p>",
          "alignment": "left"
        }
      }
    }
  ],
  "publish": true,
  "purpose": "digital_content"
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`As you can see, we are passing a list of restrictions inside
`}<MDXTag name="inlineCode" components={components} parentName="p">{`display_restrictions.`}</MDXTag>{` In our case, we are using "ticket_class" as the key, and
a ticket class id as the value.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Basic Modules`}</MDXTag>
<MDXTag name="p" components={components}>{`For more information on the basic modules available, please read
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/event-description"}}>{`this article`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Digital Links Modules`}</MDXTag>
<MDXTag name="p" components={components}>{`The digital links specific modules available are:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#webinar-module"}}>{`Webinar Module`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#livestream-module"}}>{`Livesteam Module`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#external-file-module"}}>{`External File Module`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Important note`}</MDXTag>{` If you use the above modules for Event Description structured
content, they WILL NOT APPEAR on your Event Listing page. Only the three basic
ones (text, image, and video) appear on the Event Listing Page. The modules
listed above are only for the digital links feature. As such, you can use all
six (text, image, video, webinar, livestream, and external file) for the Online
Event Page.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Webinar Module`}</MDXTag>
<MDXTag name="p" components={components}>{`The webinar module supports nine major webinar platforms (but can support others
as well):`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`GoToMeeting`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`GoToWebinar`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`GoToTraining`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Hubspot`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Vimeo`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Webex`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Youtube/Hangouts`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Zoho`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Zoom`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`If you would like to add a custom preview image for the webinar module (it is
optional), you will first need to upload an image to the Eventbrite media
service. Then you will submit the image_id as part of the webinar module data.`}</MDXTag>
<MDXTag name="p" components={components}>{`To upload an image to the Eventbrite media service, please follow the steps
located at `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/image-upload"}}>{`Image Upload`}</MDXTag>{`. In the `}<MDXTag name="strong" components={components} parentName="p">{`Retrieving the
Upload Token`}</MDXTag>{` section, you will need to replace `}<MDXTag name="inlineCode" components={components} parentName="p">{`type=image-event-logo`}</MDXTag>{` with
`}<MDXTag name="inlineCode" components={components} parentName="p">{`type=image-structured-content`}</MDXTag>{`. You will need to do the same with `}<MDXTag name="strong" components={components} parentName="p">{`Uploading
the Image File to the Specified URL`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`This module also accepts a title and a instructions field, both are optional.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for webinar module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
     "data":{
        "webinar_url":{
           "text":"my webinar",
           "url":"http://zoom.us/1234"
        },
        "image":{
           "type":"image",
           "image_id":"63131771"
        },
        "title":{
           "text": "Optional Title",
        },
        "instructions":{
           "text": "Instructions to join the webinar",
        }
     },
     "type": "webinar"
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET response format for webinar module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
  "id":"32551553",
  "type":"webinar",
  "data":{
      "image":{
         "edge_color_set":true,
         "edge_color":"#d0c9bd",
         "corner_style":"rectangular",
         "url":"https://img-dev.evbuc.com/3765",
         "original":{
            "url":"https://img-dev.evbuc.com/3765",
            "width":1200,
            "height":650
         },
         "image_id":"63131771",
         "crop_mask":{
            "width":1148,
            "top_left":{
               "y":2,
               "x":26
            },
            "height":646
         },
         "aspect_ratio":"1.7",
         "id":"63131771"
      },
      "webinar_url":{
         "url":"http://zoom.us/1234",
         "text":"my webinar"
      },
      "title":{
         "text": "Optional Title",
      },
      "instructions":{
         "text": "Instructions to join the webinar",
      }
  },
}
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`Livestream Module`}</MDXTag>
<MDXTag name="p" components={components}>{`The livestream module is pretty much exactly the same as the webinar module -
the differentiation exists for user purposes. Please see webinar module above
for further explanation on how to submit a custom preview image as well as types
of livestreams accepted.`}</MDXTag>
<MDXTag name="p" components={components}>{`This module also accepts a title and a instructions field, both are optional.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for livestream module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
     "data":{
        "livestream_url":{
           "text":"my livestream",
           "url":"http://zoom.us/1234"
        },
        "image":{
           "type":"image",
           "image_id":"63131771"
        },
        "title":{
           "text": "Optional Title",
        },
        "instructions":{
           "text": "Instructions to join the livestream",
        }
     },
     "type": "livestream"
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET response format for livestream module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
  "id":"32551553",
  "type":"livestream",
  "data":{
      "image":{
         "edge_color_set":true,
         "edge_color":"#d0c9bd",
         "corner_style":"rectangular",
         "url":"https://img-dev.evbuc.com/3765",
         "original":{
            "url":"https://img-dev.evbuc.com/3765",
            "width":1200,
            "height":650
         },
         "image_id":"63131771",
         "crop_mask":{
            "width":1148,
            "top_left":{
               "y":2,
               "x":26
            },
            "height":646
         },
         "aspect_ratio":"1.7",
         "id":"63131771"
      },
      "livestream_url":{
         "url":"http://zoom.us/1234",
         "text":"my livestream"
      },
      "title":{
         "text": "Optional Title",
      },
      "instructions":{
         "text": "Instructions to join the livestream",
      }
  },
}
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`External File Module`}</MDXTag>
<MDXTag name="p" components={components}>{`If you have a link to an externally hosted file, document, or presentation, you
can use this module.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for file module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
  "type":"file",
  "data":{
      "file_url":{
          "text":"Presentation",
          "url":"https://docs.eventbrite.com/document"
      }
  }
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET response format for file module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "id":"32551565",
    "type":"file",
    "data":{
        "file_url":{
           "url":"https://docs.eventbrite.com/document",
           "text":"Presentation"
        }
    },
}
`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Timed Display`}</MDXTag>
<MDXTag name="p" components={components}>{`With the Online Event Page, we introduce the idea of an organizer being able to
set times for when to show/hide modules. Let's say you want to only display a
webinar link 15 minutes before the start of the event and hidden after, but want
other information to always be shown on the Online Event Page.`}</MDXTag>
<MDXTag name="p" components={components}>{`With a particular module, you can set absolute start and absolute end datetime
to say "hey, this is when the module should be shown and then hidden". You can
also just send in absolute start time.`}</MDXTag>
<MDXTag name="p" components={components}>{`With
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#retrieve-latest-published-version-of-structured-content-by-event-id"}}>{`Retrieve Latest Published Version of Structured Content`}</MDXTag>{`,
which is the public facing api that powers the attendee page, the modules that
have timed display that fall outside of start/end times will not be returned in
the api response. If you want to see all of the modules with their timed
display, use
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#retrieve-latest-working-version-of-structured-content-by-event-id"}}>{`Retrieve Latest Working Version of Structured Content`}</MDXTag>{`
as that is meant to be an event organizer only api.`}</MDXTag>
<MDXTag name="p" components={components}>{`This works with all modules - text, image, video, webinar, livestream, and file.
But please read the note below:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Important Note`}</MDXTag>{` This feature is not available for event description. It is
only meant to be used in conjunction with Digital Links.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for file module with absolute timed display`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
  "type":"file",
  "data":{
      "file_url":{
          "text":"Presentation",
          "url":"https://docs.eventbrite.com/document"
      }
  },
  "timed_display":{
    "absolute_start_utc": "2020-02-21T23:30:00Z",
    "absolute_end_utc": "2020-02-22T01:30:00Z"
  }
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET format for file module with absolute timed display`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "id":"32551567",
    "type":"file",
    "data":{
        "file_url":{
           "url":"https://docs.eventbrite.com/document",
           "text":"Presentation"
        }
    },
    "timed_display":{
        "absolute_start_utc":"2020-02-21T23:30:00Z",
        "absolute_end_utc":"2020-02-22T01:30:00Z"
    }
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`There is a different way of setting up timed display for repeating events.
Because only one digital links structured content page is created per event
(including all children events), there needs to be a way to say "hey, make this
module show relative to the start of the event, including all children events".`}</MDXTag>
<MDXTag name="p" components={components}>{`As opposed to absolute time, relative time is in minutes. Relative start
represents the minutes before an event starts, and relative end is minutes after
an event ends. The module will be shown in between that time period. Similar to
absolute time, you can also just set relative start without an end.`}</MDXTag>
<MDXTag name="p" components={components}>{`You cannot have both relative time and absolute time for a module.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for file module with relative timed display`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
  "type":"file",
  "data":{
      "file_url":{
          "text":"Presentation",
          "url":"https://docs.eventbrite.com/document"
      }
  },
  "timed_display":{
    "relative_start": "30",
    "relative_end": "60"
  }
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET format for file module with relative timed display`}</MDXTag>
<MDXTag name="p" components={components}>{`For the get of relative timed display (specifically, with the public facing
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#retrieve-latest-published-version-of-structured-content-by-event-id"}}>{`Retrieve Latest Published Version of Structured Content`}</MDXTag>{`)
you will need to send in parameters of `}<MDXTag name="inlineCode" components={components} parentName="p">{`start_date_utc`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`end_date_utc`}</MDXTag>{`. The
start and end datetime should be that of the specific child event that you want
the digital links for.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/structured_content/?purpose=digital_content&start_date_utc=2020-02-21T23:30:00Z&end_date_utc=2020-02-22T01:30:00Z \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h5" components={components}>{`Module response format (not the entire response)`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "id":"32551567",
    "type":"file",
    "data":{
        "file_url":{
           "url":"https://docs.eventbrite.com/document",
           "text":"Presentation"
        }
    },
    "timed_display":{
        "relative_start":"30",
        "relative_end":"60"
    }
}
`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Notifications with Timed Display`}</MDXTag>
<MDXTag name="p" components={components}>{`If an event organizer wants the attendee to be notified when the module is
available, you can set that up, on a module by module basis.`}</MDXTag>
<MDXTag name="p" components={components}>{`Notifications can be used with either relative or absolute timed displays.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for file module with relative timed display and notifications`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "type":"file",
    "data":{
        "file_url":{
           "url":"https://docs.eventbrite.com/document",
           "text":"Presentation"
        }
    },
    "timed_display":{
        "relative_start":"30",
        "relative_end":"60",
        "should_notify": true
    }
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET format for file module with relative timed display and notifications`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "id":"32551567",
    "type":"file",
    "data":{
        "file_url":{
           "url":"https://docs.eventbrite.com/document",
           "text":"Presentation"
        }
    },
    "timed_display":{
        "relative_start":"30",
        "relative_end":"60",
        "should_notify": true
    }
}
`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Creating and Updating Structured Content for Digital Links`}</MDXTag>
<MDXTag name="p" components={components}>{`Related api reference:
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#set-structured-content-by-event-id-and-version"}}>{`Set Structured Content by Event Id and Version`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Structured content works as an insert only system, so any time you want to
update module(s), you will need to resubmit all of the content you had
previously submitted plus any changes. Structured content also works as a
versioned system, so you will need to increment with a new version every time.
The first time you submit, you will want to submit with `}<MDXTag name="inlineCode" components={components} parentName="p">{`version_number=1`}</MDXTag>{` and
every time after, increment the current `}<MDXTag name="inlineCode" components={components} parentName="p">{`page_version_number`}</MDXTag>{` every time after.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Note:`}</MDXTag>{` in order to make the api work correctly, you MUST send in `}<MDXTag name="inlineCode" components={components} parentName="p">{`publish=true`}</MDXTag>{`
every time. If you are not seeing your most current updates, it is probably
because you forgot to send in `}<MDXTag name="inlineCode" components={components} parentName="p">{`publish=true`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`2nd Note:`}</MDXTag>{` It is `}<MDXTag name="strong" components={components} parentName="p">{`NECESSARY`}</MDXTag>{` you set `}<MDXTag name="inlineCode" components={components} parentName="p">{`purpose=digital_content`}</MDXTag>{` to ensure that
the structured content is set for the digital content page (as there are other
purposes that structured content is used for).`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/structured_content/{version_number} \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
       "modules":[
          {
             "type":"text",
             "data":{
                "body":{
                   "type":"text",
                   "text":"<h2>Hello World</h2><ul><li>Hello Northern Hemisphere</li><li>Hello Southern Hempisphere</li></ul><p></p><p>It's a <em>beautiful</em> day today!</p><aside><em></em></aside>",
                   "alignment":"left"
                }
             }
          },
          {
             "type":"image",
             "data":{
                "image":{
                   "type":"image",
                   "image_id":"8561955"
                }
             }
          },
          {
             "type":"video",
             "data":{
                "video":{
                   "url":"https://www.youtube.com/watch?v=-pAz5X7ZW4",
                   "display_size":"large"
                }
             }
          },
          {
         "data":{
            "livestream_url":{
               "text":"my livestream",
               "url":"http://zoom.us/1234"
            },
         },
         "timed_display":{
        "absolute_start": "2020-02-21T23:30:00Z",
       },
         "type": "livestream"
      }
       ],
       "publish":true,
       "purpose":"digital_content"
    }'
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="p" components={components}>{`See below `}<MDXTag name="a" components={components} parentName="p" props={{"href":"#getting-structured-content"}}>{`Getting Structured Content`}</MDXTag>{` for an
example of the api response.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Getting Structured Content`}</MDXTag>
<MDXTag name="p" components={components}>{`Related api reference:
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#retrieve-latest-published-version-of-structured-content-by-event-id"}}>{`Retrieve Latest Published Version of Structured Content`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Note:`}</MDXTag>{` It is `}<MDXTag name="strong" components={components} parentName="p">{`NECESSARY`}</MDXTag>{` you set `}<MDXTag name="inlineCode" components={components} parentName="p">{`purpose=digital_content`}</MDXTag>{` to ensure that the
structured content is for the digital content page (as there are other purposes
that structured content is used for).`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`2nd Note:`}</MDXTag>{` If you have set relative timed display, you will need to send in
parameters of `}<MDXTag name="inlineCode" components={components} parentName="p">{`start_date_utc`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`end_date_utc`}</MDXTag>{`. The start and end datetime
should be that of the specific child event that you want the digital links for.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`3rd Note:`}</MDXTag>{` `}<MDXTag name="inlineCode" components={components} parentName="p">{`Retrieve Latest Published Version of Structured Content`}</MDXTag>{` is the
public facing api that gets used on the attendee page, as such, it will not
return 'hidden modules'. If you, as the event organizer, want to see all of your
modules including the ones hidden by timed display, make sure to use
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#retrieve-latest-working-version-of-structured-content-by-event-id"}}>{`Retrieve Latest Working Version of Structured Content`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/structured_content/?purpose=digital_content \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
   "resource_uris":{
      "self":"https://www.eventbriteapi.com/v3/events/83068416871/structured_content/15/",
      "add_module":"https://www.eventbriteapi.com/v3/events/83068416871/structured_content/15/module/",
      "publish":"https://www.eventbriteapi.com/v3/events/83068416871/structured_content/15/publish/",
      "preview_module":"https://www.eventbriteapi.com/v3/events/structured_content/module/preview/"
   },
   "page_version_number":"1",
   "purpose":"listing",
   "modules":[
      {
         "resource_uris":{
            "self":"module/29665960/"
         },
         "id":"29665960",
         "type":"text",
         "layout":null,
         "data":{
            "body":{
               "text":"<h2>Hello World<\/h2><ul><li>Hello Northern Hemisphere<\/li><li>Hello Southern Hempisphere<\/li><\/ul><p><\/p><p>It's a <em>beautiful<\/em> day today!<\/p><aside><em><\/em><\/aside>",
               "alignment":"left"
            }
         },
         "semantic_purpose":null,
         "timed_display":null
      },
      {
         "resource_uris":{
            "self":"module/29665962/"
         },
         "id":"29665962",
         "type":"image",
         "layout":null,
         "data":{
            "image":{
               "edge_color_set":true,
               "edge_color":"#77634e",
               "corner_style":"rectangular",
               "url":"https://img.evbuc.com/etc",
               "id":"85619535",
               "image_id":"85619535",
               "crop_mask":{

               },
               "aspect_ratio":"1.442386831275720164609053498",
               "original":{
                  "url":"https://img.evbuc.com/etc",
                  "width":2804,
                  "height":1944
               }
            }
         },
         "semantic_purpose":null,
         "timed_display":null
      },
      {
         "resource_uris":{
            "self":"module/29665964/"
         },
         "id":"29665964",
         "type":"video",
         "layout":null,
         "data":{
            "video":{
               "url":"https://www.youtube.com/watch?v=-pAz5X7ZW4",
               "display_size":"large",
               "thumbnail_url":"https://i.ytimg.com/vi/-pAz5X7ZW4/sddefault.jpg",
               "embed_url":"https://youtube.com/embed/-pAz5X7ZW4/"
            }
         },
         "semantic_purpose":null,
         "timed_display":null
      },
      {
    "id":"32551553",
    "type":"livestream",
    "data":{
        "livestream_url":{
           "url":"http://zoom.us/1234",
           "text":"my livestream"
        }
    },
    "timed_display":{
      "absolute_start": "2020-02-21T23:30:00Z",
    }
    }
   ],
   "pagination":{
      "object_count":3,
      "page_number":1,
      "page_size":50,
      "page_count":1
   }
}
`}</MDXTag></MDXTag>
           </MDXTag>
  }
}
  