import React, { PureComponent } from 'react';
import CopyApiKeyField from '../../containers/ConnectedCopyApiKeyField';

export default class HeroBannerContent extends PureComponent {
    render() {
        return (
            <section className="eds-g-cell eds-g-cell-12-12 eds-g-cell-mn-7-12">
                <h1 className="eds-text-hxl">
                    Welcome aboard!
                    <span className="eds-text-hl eds-l-pad-top-4">
                        Use your API key to create amazing things
                    </span>
                </h1>

                <div className="eds-g-cell-11-12 eds-g-cell-mn-6-12 eds-l-mar-bot-12 eds-l-mar-top-6 hero-banner__copy-input">
                    <CopyApiKeyField userData={this.props.userData} />
                </div>
            </section>
        );
    }
}
