
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Exploring the API`}</MDXTag>
<MDXTag name="p" components={components}>{`The Eventbrite API includes an API Explorer, a tool that helps you explore and debug Eventbrite endpoints. Detailed information on an endpoint is displayed in the API Explorer Web browser.`}</MDXTag>
<MDXTag name="p" components={components}>{`Using the following two endpoints, examples of using the API Explorer are provided: \
`}<MDXTag name="inlineCode" components={components} parentName="p">{`/users/me/`}</MDXTag>{` Shows information about the current user (i.e. you). \
`}<MDXTag name="inlineCode" components={components} parentName="p">{`/users/me/events`}</MDXTag>{` Displays a list of Events you can manage.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Note: All available Eventbrite endpoints are described in the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api"}}>{`API Reference`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Example 1: Exploring the users/me/ Endpoint`}</MDXTag>
<MDXTag name="p" components={components}>{`To retrieve information about the current user, go to \
`}<a href="https://www.eventbriteapi.com/v3/users/me/?token=MYTOKEN" target="_blank">{`https://www.eventbriteapi.com/v3/users/me/?token=MYTOKEN`}</a></MDXTag>
<MDXTag name="p" components={components}>{`Make sure you replace MYTOKEN with your personal OAUTH token.`}</MDXTag>
<MDXTag name="p" components={components}>{`The initial response returned from `}<MDXTag name="inlineCode" components={components} parentName="p">{`/users/me/`}</MDXTag>{` is a single User object of the current user (i.e. you), displaying the following information:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Details on the requested parameters.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Token and user identification.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Headers and status code of the response.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Full JSON response, with the syntax highlighted and with clickable links to other endpoints if they’re included in the response.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The response looks something like`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  "emails": [
    {
      "email": "andrewgodwin@eventbrite.com",
      "verified": false,
      "primary": true
    }
  ],
  "id": 85329145958,
  "name": "Andrew Godwin",
  "first_name": "Andrew",
  "last_name": "Godwin"
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`which is a paginated response of your User information.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Example 2: Exploring the users/me/owned_events Endpoint`}</MDXTag>
<MDXTag name="p" components={components}>{`Before trying this example, you must have created at least one Eventbrite Event (for information on creating an Event, refer to `}<a href="http://www.eventbrite.com/create" target="_blank">{`Create an Event`}</a>{`).`}</MDXTag>
<MDXTag name="p" components={components}>{`To retrieve information about the Events you manage, point your browser to \
`}<a ref="https://www.eventbriteapi.com/v3/users/me/events/?token=MYTOKEN" target="_blank">{`https://www.eventbriteapi.com/v3/users/me/events/?token=MYTOKEN`}</a>{`
Make sure you replace MYTOKEN with your personal OAUTH token.`}</MDXTag>
<MDXTag name="p" components={components}>{`The response is similar to:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  "pagination": {
    "object_count": 1,
    "page_number": 1,
    "page_size": 50,
    "page_count": 1
  },
  "events": [
    {
      "resource_uri": "https://www.eventbriteapi.com/v3/events/10067458038/",
      "name": {
        "text": "Test Event 1",
        "html": "Test Event 1"
      },
      "description": {
        "text": null,
        "html": null
      },
      "organizer": {
        "description": {
          "text": "An organizer",
          "html": "<P>An organizer</P>"
        },
        "id": 5867211989,
        "name": "Andrew's Amazing Conferences"
      },
      "venue": null,
      "ticket_classes": [
        {
          "id": 22717678,
          "name": "Standard Entry",
          "cost": "54.11",
          "fee": "3.97",
          "currency": "USD",
          "free": false,
          "minimum_quantity": 1,
          "maximum_quantity": null,
          "quantity_total": 100,
          "quantity_sold": 1,
          "sales_start": null,
          "sales_end": "2014-02-22T18:00:00Z"
        }
      ],
      "id": 10067458038,
      "url": "http://www.eventbrite.com/e/test-event-1-tickets-10067458038",
      "start": "2014-02-22T19:00:00Z",
      "end": "2014-02-22T22:00:00Z",
      "created": "2014-01-13T06:25:19Z",
      "changed": "2014-01-14T03:15:50Z",
      "timezone": "America/Los_Angeles",
      "capacity": 100,
      "categories": [],
      "status": "live"
    }
  ]
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`which is a paginated response of all your Events.`}</MDXTag>
<NextSteps
  steps={[
      {
        title: "Authenticating Your Access to the Eventbrite API",
        link: "/platform/docs/authentication",
      },
      {
        title: "Creating an Eventbrite Event",
        link: "/platform/docs/create-events",
      }
    ]}
/>
           </MDXTag>
  }
}
  