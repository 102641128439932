import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { makePropType } from '@eventbrite/eds-utils';
import { SIZES as BUTTON_SIZES } from '@eventbrite/eds-button';
import { ICON_PROP_TYPE } from '@eb/eds-icon-button';
import { NOTIFICATION_OPTIONS_PROP_TYPES } from '@eb/eds-notification';

// modal types
export const TYPE_SIMPLE = 'simple';
export const TYPE_APERTURE = 'aperture';
export const TYPE_TAKEOVER = 'takeover';
export const TYPE_COMPLEX = 'complex';

export const MODAL_TITLE_ID = 'eds-modal-title';

export const TYPES = [TYPE_SIMPLE, TYPE_APERTURE, TYPE_TAKEOVER, TYPE_COMPLEX];

// Checks that the prop has the correct length
const propLengthCheck = (props, propName, componentName, length = 140) => {
    if (props[propName] > length) {
        return new Error(
            `Invalid prop ${propName} suppied to ${componentName}. content must be ${length} characters or less.`,
        );
    }

    return null;
};

const MODAL_MESSAGE_TYPE = (props, propName, componentName) =>
    propLengthCheck(props, propName, componentName, 32);

const MODAL_TITLE_TYPE = (props, propName, componentName) =>
    propLengthCheck(props, propName, componentName, 180);

export const MODAL_MESSAGE_PROPTYPE = makePropType(MODAL_MESSAGE_TYPE);
export const MODAL_TITLE_PROPTYPE = makePropType(MODAL_TITLE_TYPE);
export const MODAL_CHILDREN_PROPTYPE = PropTypes.node;

export const MODAL_PROP_TYPES = {
    children: MODAL_CHILDREN_PROPTYPE.isRequired,
    /**
     * The type of modal that should be shown
     */
    type: PropTypes.oneOf(TYPES),
    /**
     * A function with the desired behavior on mount (componentDidMount)
     */
    onMount: PropTypes.func,
    /**
     * Is the dialog currently shown?
     */
    isShown: PropTypes.bool,
    /**
     * Removes min height for simple Modals
     */
    noMinHeight: PropTypes.bool,
    /**
     * Is the dialog closable?
     */
    isClosable: PropTypes.bool,
    /**
     * Removes padding on the modal container
     */
    noPadding: PropTypes.bool,
    /**
     * Add a class to the modals wrapper <dialog>
     */
    dangerouslySetContainerClassName: PropTypes.shape({
        __className: PropTypes.string,
    }),
};

export const MODAL_BODY_PROP_TYPES = {
    children: MODAL_CHILDREN_PROPTYPE.isRequired,
    /**
     * A function with the desired behavior on close
     */
    onClose: PropTypes.func,
    /**
     * The icon to use for the secondary header action
     */
    headerActionIconType: ICON_PROP_TYPE,
    /**
     * The title for the secondary header action icon
     */
    headerActionTitle: translationPropType,
    /**
     * A function with the desired behavior on clicking the secondary header action icon
     */
    onHeaderAction: PropTypes.func,
    /**
     * The title displayed above the content
     */
    title: MODAL_TITLE_PROPTYPE,
    /**
     * The title displayed above the content
     */
    secondaryTitle: PropTypes.node,
    /**
     * The illustration displayed inside the modal
     */
    illustration: PropTypes.node,
    /**
     * The type of button that should be shown for primary button
     */
    primaryType: PropTypes.string,
    /**
     * The text that should be shown across the primary button
     */
    primaryText: PropTypes.node,
    /**
     * The size of the primary button
     */
    primarySize: PropTypes.oneOf([...BUTTON_SIZES]),
    /**
     * Whether the primary button should be disabled
     */
    primaryIsDisabled: PropTypes.bool,
    /**
     * The function that should be triggered on primary button click
     */
    onPrimaryAction: PropTypes.func,
    /**
     * The type of button that should be shown for secondary button
     */
    secondaryType: PropTypes.string,
    /**
     * The text that should be shown across the secondary button
     */
    secondaryText: PropTypes.node,
    /**
     * Whether the secondary button should be disabled
     */
    secondaryIsDisabled: PropTypes.bool,
    /**
     * The function that should be triggered on secondary button click
     */
    onSecondaryAction: PropTypes.func,
    /**
     * The node that will act as a tertiary action
     */
    tertiaryNode: PropTypes.node,
    /**
     * The function that should be triggered on tertiary node click
     */
    onTertiaryAction: PropTypes.func,
    /**
     * A message to show above the button bar
     */
    buttonBarMessage: translationPropType,
    /**
     * True if the modal body should be empty, i.e. not include the header, footer, or body containers
     */
    modalBodyIsEmpty: PropTypes.bool,
    /**
     * Disables the ability to close the modal by clicking the outside overlay
     */
    disableCloseOnClickOutside: PropTypes.bool,
    /**
     * Used to render notifications inside the modal header.
     */
    notificationOptions: PropTypes.oneOfType([
        NOTIFICATION_OPTIONS_PROP_TYPES,
        PropTypes.arrayOf(NOTIFICATION_OPTIONS_PROP_TYPES),
    ]),
    /**
     * Changes the role attribute to dialog
     */
    roleIsDialog: PropTypes.bool,
};

export const COLLAPSIBLE_PANE_LAYOUT_PROP_TYPES = {
    /**
     * The content to display next to the pane
     */
    content: PropTypes.node,
    /**
     * The content to display inside the pane, if any
     */
    pane: PropTypes.node,
    /**
     * Whether pane is open or closed
     */
    isPaneOpen: PropTypes.bool,
};
