
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Intro to Authentication`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#difference-between-authentication-and-authorization"}}>{`So, what’s the difference between authentication and authorization?`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#what-is-oauth"}}>{`And what's OAuth?`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#getting-started-with-authentication"}}>{`Getting started with authentication`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#best-practices"}}>{`Best practices`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Already know what authentication is? Skip to `}<MDXTag name="a" components={components} parentName="th" props={{"href":"#getting-started-with-authentication"}}>{`Getting Started with Authentication`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="p" components={components}>{`Because the Eventbrite API provides entry points into the secure parts of the
Eventbrite platform, the protection of the data is an integral part of the API
and the system as a whole. Authentication and authorization work as a team to
secure the Eventbrite API.`}</MDXTag>
<MDXTag name="p" components={components}><a name="difference-between-authentication-and-authorization"></a></MDXTag>
<MDXTag name="h2" components={components}>{`So, what’s the difference between authentication and authorization?`}</MDXTag>
<MDXTag name="p" components={components}>{`Imagine you’re in line to see your favorite band in concert.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Authentication verifies your identity`}</MDXTag>{`, just like the security guard checking
your ID at the door to make sure it matches the name on your ticket.`}</MDXTag>
<MDXTag name="p" components={components}>{`OAuth does this by providing you with a public or private token. Every request
to the Eventbrite platform must be authenticated; no unauthorized access is
allowed. For more on OAuth, see the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"#what-is-oauth"}}>{`And what's OAuth?`}</MDXTag>{` section.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Authorization verifies your privileges`}</MDXTag>{`, just like the usher who scans your
ticket and walks you to the section that you’re allowed to access, whether it's
general admission, the front row, or a backstage to meet the band.`}</MDXTag>
<MDXTag name="p" components={components}>{`OAuth does this by generating a private token that's tied to an account or user.
Each time you request something, you'll send this token to the server so that it
knows whom the request came from and what that request is allowed to do.`}</MDXTag>
<MDXTag name="p" components={components}>{`Authorization privileges might include the data, files, and pages you can view,
edit, download, or delete.`}</MDXTag>
<MDXTag name="p" components={components}>{`Once you have your token, you’ll use the same one every time you access the API.`}</MDXTag>
<MDXTag name="p" components={components}><a name="what-is-oauth"></a></MDXTag>
<MDXTag name="h2" components={components}>{`And what's OAuth?`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://oauth.net/2/"}}>{`OAuth 2`}</MDXTag>{` (Open Authorization) is a security technology
used for token-based authorization on the Internet. An OAuth token can also be
called a private token.`}</MDXTag>
<MDXTag name="p" components={components}>{`OAuth 2 replaced OAuth1 and is currently the most recent version of OAuth and
the leading authorization solution on the Internet.`}</MDXTag>
<MDXTag name="p" components={components}>{`All Eventbrite API requests must be authenticated with a valid OAuth token.`}</MDXTag>
<MDXTag name="p" components={components}><a name="getting-started-with-authentication"></a></MDXTag>
<MDXTag name="h2" components={components}>{`Getting started with authentication`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol"><MDXTag name="a" components={components} parentName="li" props={{"href":"#get-a-private-token"}}>{`Get a Private Token`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ol"><MDXTag name="a" components={components} parentName="li" props={{"href":"#authorize-your-users"}}>{`(For App Partners) Authorize your Users`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ol"><MDXTag name="a" components={components} parentName="li" props={{"href":"#authenticate-api-requests"}}>{`Authenticate API Requests`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="p" components={components}><a name="get-a-private-token"></a></MDXTag>
<MDXTag name="h3" components={components}>{`1. Get a Private Token`}</MDXTag>
<MDXTag name="p" components={components}>{`a. Log in to your Eventbrite account and visit
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/platform/api-keys"}}>{`your API Keys page`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`b. Copy your private token.`}</MDXTag>
<MDXTag name="p" components={components}><a name="authorize-your-users"></a></MDXTag>
<MDXTag name="h3" components={components}>{`2. (For App Partners) Authorize your Users`}</MDXTag>
<MDXTag name="p" components={components}>{`Note: These steps enable you to perform API requests on behalf of other users.
To perform API requests on your own behalf, skip to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#authenticate-api-requests"}}>{`Authenticate API Requests`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Authorize Users`}</MDXTag>
<MDXTag name="p" components={components}>{`What You'll Need:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`API Key`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Client Secret`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Redirect URI`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Note: To find this information, visit
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/account-settings/apps"}}>{`your API Key Management page`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`The Eventbrite API uses `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://oauth.net/2/"}}>{`OAuth 2.0`}</MDXTag>{` for authorization.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are two ways to authorize users: Server-side and client-side. We strongly
recommend handling authorization on the server side for security reasons.`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Server-Side Authorization (Recommended)`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a. Redirect users to our authorization URL, while including your API key and
redirect URI as query parameters:
`}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/authorize?response_type=code&client_id=YOUR_API_KEY&redirect_uri=YOUR_REDIRECT_URI`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`When the user authorizes your app, your redirect URI will receive a request
from our authorization server with your access code included as a query
parameter.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`Here's an example of the URI you will be redirected to (with the access code
included as a query parameter):
`}<MDXTag name="inlineCode" components={components} parentName="p">{`http://localhost:8080/oauth/redirect?code=YOUR_ACCESS_CODE`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b. Send a POST request to `}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/token`}</MDXTag>{` that
specifies the grant type and includes your access code, client secret, and
API key. This data should be sent as part of your request header.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`Here's an example of a POST request using cURL:`}</MDXTag>
<MDXTag name="pre" components={components} parentName="li"><MDXTag name="code" components={components} parentName="pre" props={{}}>{`curl --request POST \
--url 'https://www.eventbrite.com/oauth/token' \
--header 'content-type: application/x-www-form-urlencoded' \
--data grant_type=authorization_code \
--data 'client_id=API_KEY \
--data client_secret=CLIENT_SECRET \
--data code=ACCESS_CODE \
--data 'redirect_uri=REDIRECT_URI'
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`The server will verify the access code and call your redirect URI. The
user's private token will be available in the JSON response. Use this
private token to make API requests on behalf of this user.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Client-Side Authorization`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a. Redirect users to our authorization URL, while including your API key and
redirect URI as query parameters:
`}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=YOUR_API_KEY&redirect_uri=YOUR_REDIRECT_URI`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`When the user authorizes your app, your redirect URI will receive a request
with the private token included as a query parameter.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Next up: Follow the steps in
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#authenticate-api-requests"}}>{`Authenticate API Requests`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}><a name="authenticate-api-requests"></a></MDXTag>
<MDXTag name="h3" components={components}>{`3. Authenticate API Requests`}</MDXTag>
<MDXTag name="p" components={components}>{`To authenticate API requests, you'll need to include either your private token
or your user's private token.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are two ways of including your token in an API request:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Authorization Header`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`Include the following in your Authorization header (replacing MYTOKEN with
your token):`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`{ Authorization: Bearer MYTOKEN }`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`Query Parameter Authentication`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`Include the following at the end of the URL (replacing MYTOKEN with your
token):`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`/v3/users/me/?token=MYTOKEN`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`For every user you would like to perform API requests on behalf of, repeat
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#authorize-your-users"}}>{`(For App Partners) Authorize your Users`}</MDXTag>{` and
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#authenticate-api-requests"}}>{`Authenticate API Requests`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}><a name="best-practices"></a></MDXTag>
<MDXTag name="h2" components={components}>{`Best practices`}</MDXTag>
<MDXTag name="p" components={components}>{`These best practices ensure that your authentication and access to the
Eventbrite API is successful and secure.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Do not use your private token directly in client-side code.`}</MDXTag>
<MDXTag name="p" components={components}>{`Before you make your application publicly available, ensure that your
client-side code does not contain private tokens or any other private
information.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Delete unneeded API keys`}</MDXTag>
<MDXTag name="p" components={components}>{`To minimize your exposure to attack, delete any private tokens that you no
longer need.`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Creating an Eventbrite Event',
            link: '/platform/docs/create-events',
        },
        {
            title: 'Working with Orders',
            link: '/platform/docs/orders',
        },
    ]}
/>
           </MDXTag>
  }
}
  