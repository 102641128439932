
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Including an Image with Your Event Listing`}</MDXTag>
<MDXTag name="p" components={components}>{`You can include a main image with your Event listing, for example to provide
branding or further information on the Event, by uploading the image.`}</MDXTag>
<MDXTag name="p" components={components}>{`Including an image using the Eventbrite API involves the following steps:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`1. Retrieve the upload instructions and an upload token from the API.

2. Upload the image file to the URL specified in the upload instructions.

3. When the upload has finished, notify the API by sending the upload token.
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`Before including an image with your Event listing, you need to authenticate your
access to the Eventbrite API. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Retrieving the Upload Token`}</MDXTag>
<MDXTag name="p" components={components}>{`To retrieve image upload token, go to the following URL:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbriteapi.com/v3/media/upload/?type=image-event-logo&token=PERSONAL_OAUTH_TOKEN`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Example Response;`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "upload_method": "POST",
    "upload_token": "WA1HZ2ZTZULGNXBBTQ9BN3LR...",
    "upload_url": "https://s3.amazonaws.com/eventbrite-uploader-incoming-prod/",
    "upload_data": {
        "AWSAccessKeyId": "AKIWAII7XBQ45Z756AUX",
        "bucket": "eventbrite-uploader-incoming-prod",
        "acl": "private",
        "key": "3a0d45ffcfcf4d1bc0df0ee05ba34948",
        "signature": "Bpogk9jowmy/h2E9/Hkdzxz4Wok=",
        "policy": "BYEJJ25XRAKPB25IJWIRIDOXRBIZDLJPXZJBWMVVN..."
    },
    "file_parameter_name": "file"
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The returned attributes have the following meaning`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Attribute`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`upload_data`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST data that should be included in the POST that uploads the image file.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`upload_url`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`URL to which the image file should be upload.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`upload_method`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Method (always POST) that should be used to upload the image file.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`file_parameter_name`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST field that the image file should be included in (handled using HTTP multipart upload).`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`upload_token`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Token that is used in step 3 of the process of including an image file. Make note of the returned token.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Uploading the Image File to the Specified URL`}</MDXTag>
<MDXTag name="p" components={components}>{`To upload the image, you can use the following request`}</MDXTag>
<MDXTag name="p" components={components}>{`Example request:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-python"}}>{`    import requests, urllib

    OAUTH_TOKEN = 'your-token-here'
    MEDIA_UPLOAD_URL = 'https://www.eventbriteapi.com/v3/media/upload/'

    def upload_file(filename):
        instructions_url = MEDIA_UPLOAD_URL + '?' + urllib.urlencode({
            'type': 'image-event-logo',
            'token': OAUTH_TOKEN
        })
        data = requests.get(instructions_url).json()
        post_args = data['upload_data']
        response = requests.post(data['upload_url'],
            data = post_args,
            files = {
                data['file_parameter_name']: open(filename)
            }
        )
        return response, data['upload_token']

    response, upload_token = upload_file('~/Pictures/test-image.jpg')
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Notifying the API by Sending the Upload Token`}</MDXTag>
<MDXTag name="p" components={components}>{`Now that your image file has been successfully uploaded, you need to notify the
API of the upload. To do so, specify the upload token you got in step 1 (i.e.
the value of the `}<MDXTag name="inlineCode" components={components} parentName="p">{`upload_token`}</MDXTag>{` attribute), and include it as the
`}<MDXTag name="inlineCode" components={components} parentName="p">{`post-media-upload`}</MDXTag>{` endpoint. You will also need to include a crop_mask to avoid
defaulting to the old Eventbrite listing page layout. The crop mask needs to be
set to a ratio of 2:1.`}</MDXTag>
<MDXTag name="p" components={components}>{`Example request:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-python"}}>{`    notify_url = MEDIA_UPLOAD_URL + '?' + urllib.urlencode({
        'token': OAUTH_TOKEN
    })

    image_data = {'upload_token': upload_token, 'crop_mask': {'top_left': {'y':1, 'x':1}, 'width':1280, 'height':640}}

    response = requests.post(notify_url, json=image_data)
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`An `}<MDXTag name="inlineCode" components={components} parentName="p">{`image`}</MDXTag>{` representing the image you have uploaded is returned.`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Getting Information on an Event',
            link: '/platform/docs/events',
        },
        {
            title: 'Embedding a Checkout Flow',
            link: '/platform/docs/embedded-checkout',
        },
    ]}
/>
           </MDXTag>
  }
}
  