
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h1" components={components}>{`Contacting Eventbrite Support for Help`}</MDXTag>
<MDXTag name="p" components={components}>{`This documentation and the Eventbrite API Reference provide information on using
the API to send and receive data, and also find information, on everything
involved in the process of creating, managing and conducting your Events.`}</MDXTag>
<MDXTag name="p" components={components}>{`However, if you have additional or specific questions, join the
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://groups.google.com/forum/#!forum/eventbrite-api"}}>{`Eventbrite API Google Group`}</MDXTag>{`.
This Eventbrite community is a developer-to-developer support resource where you
can search and post about problems and solutions you encounter when working with
the Eventbrite API.`}</MDXTag>
<MDXTag name="p" components={components}>{`From time to time you may also see Eventbrite engineers jumping in to answer
questions, as well as see changes and communications posted by the Eventbrite
team.`}</MDXTag>
<MDXTag name="p" components={components}>{`Our community guidelines are simple:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Be kind to each other.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Keep posts relevant. Your posts should relate to the subject dictated by the
topic.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Do your best to avoid duplicate questions (e.g., please search first).`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`When posting questions to the group, please include the following information:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Endpoint you called. Include the entire path. Be sure to redact your OAuth
token as the group is a public page.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Response you expected from the call.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Response you received. Include the error message if you received one. If
your response was missing data, please include the ID of the object you were
expecting to see, but did not.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Steps you have already taken to debug the issue.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Overall goal you are trying to accomplish with your integration. This
information provides the community an opportunity to suggest the best way to
integrate with the API if your current usage isn't as efficient as it could
be.`}</MDXTag>
</MDXTag>
           </MDXTag>
  }
}
  