
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Getting Information on Events`}</MDXTag>
<MDXTag name="p" components={components}>{`You can get detailed information about an Event using the expansion feature and
specific endpoints of the API. This information is helpful when working with an
Event. The first step in getting information on an Event is to identify the
Event ID.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`You must first authenticate your access to the Eventbrite API before getting
information on an Event. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Finding an Event ID`}</MDXTag>
<MDXTag name="p" components={components}>{`There are two ways to find an Event ID:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`From the Eventbrite website.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Using an API GET request.`}</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Using the Eventbrite Website to Find an Event ID`}</MDXTag>
<MDXTag name="p" components={components}>{`An Event URL includes the Event ID. To view the Event URL of an Event you have
created:`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`Make sure you are logged into your Eventbrite account.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`Click the Profile icon on the top-right and choose Manage Events from the
drop down menu.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`Click on the Event of interest.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li">{`In the URL field at the top, you see something like
`}<MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/myevent?eid=123456789`}</MDXTag>{`. The number after eid= is
the Event ID. In this example, the Event ID is `}<MDXTag name="inlineCode" components={components} parentName="p">{`123456789`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Using the Eventbrite API to Find an Event ID`}</MDXTag>
<MDXTag name="p" components={components}>{`There are several ways to find Event IDs with the API. The simplest API call is
below, which returns a list of Events that belong to the organization.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/organizations/{organization_id}/events/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`You will get a list of Events and a pagination object in return. For each Event
object, the `}<MDXTag name="inlineCode" components={components} parentName="p">{`id`}</MDXTag>{` attribute is the `}<MDXTag name="inlineCode" components={components} parentName="p">{`event_id`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Obtaining your Organization ID`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/users/me/organizations/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`You will get a list of Organizations and a pagination object in return. For each Organization
object, the `}<MDXTag name="inlineCode" components={components} parentName="p">{`id`}</MDXTag>{` attribute is the `}<MDXTag name="inlineCode" components={components} parentName="p">{`organization_id`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Using Expansions to Get Information on an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`To limit the number of calls to the API, and the size of the responses, by
default data returned on an Event is short and shows only basic information
(subset) about the object. However, you can obtain additional and expanded
information on certain Event object elements by using expansions.`}</MDXTag>
<MDXTag name="p" components={components}>{`The endpoint expansion is appended as a parameter to the GET call. For example,
if you want to expand the Ticket Classes information on the Event, you will call
the below`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/?expand=ticket_classes \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The Ticket Classes object (an array of Ticket Class) attached in the Event
object is returned.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  ...
  "ticket_classes": [
        {
            "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/ticket_classes/12345/",
            "variant_id": null,
            "name": "test",
            "description": null,
            "donation": false,
            "free": true,
            "minimum_quantity": 1,
            "maximum_quantity": null,
            "maximum_quantity_per_order": 10,
            "maximum_quantity_per_order_without_pending": null,
            "on_sale_status": "AVAILABLE",
            "quantity_total": 100,
            "quantity_sold": 23,
            "sales_start": "2018-11-21T21:25:00Z",
            "sales_end": "2019-06-01T04:00:00Z",
            "hidden": false,
            "include_fee": false,
            "split_fee": false,
            "hide_description": true,
            "auto_hide": false,
            "variants": [],
            "has_pdf_ticket": true,
            "sales_channels": [
                "online",
                "atd"
            ],
            "delivery_methods": [
                "electronic"
            ],
            "event_id": "12345",
            "id": "12345"
        },
        ...
    ],
  ...
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The most commonly used Event endpoint expansions are:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Expansion`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Source`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Description`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`venue`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`venue_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://www.eventbriteapi.com/platform/api#/reference/venue"}}>{`Venue`}</MDXTag>{` Detailed Venue information including address. See example below.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`organizer`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`organizer_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://www.eventbriteapi.com/platform/api#/reference/organizer"}}>{`Organizer`}</MDXTag>{` Information on the Event sponsor.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`format`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`format_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://www.eventbriteapi.com/platform/api#/reference/formats"}}>{`Format`}</MDXTag>{`. Event type, for example conference, seminar, concert.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`category`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`category_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://www.eventbriteapi.com/platform/api#/reference/categories"}}>{`Category`}</MDXTag>{`. Event class, such as Music. See example below.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`subcategory`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`subcategory_id`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event `}<MDXTag name="a" components={components} parentName="td" props={{"href":"https://www.eventbriteapi.com/platform/api#/reference/subcategories"}}>{`Subcategory`}</MDXTag>{`. Event subclass, for example Classical.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`bookmark_info`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`bookmark_info`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Visitor to the Eventbrite website has saved the Event as a bookmark. Returns false if there are no bookmarks. If there are bookmarks, returns a a dictionary specifying the number of visitors who have bookmarked the Event as a count object like `}<MDXTag name="inlineCode" components={components} parentName="td">{`{count:3}`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`refund_policy`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`refund_policy`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event refund policy.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`ticket_classes`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`ticket_classes`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Information on the Event Ticket Classes. See the page Working With Ticket Classes and Ticket Groups for information and an example of using this specific expansion.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`ticket_availability`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`ticket_availability`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Number of Event Ticket Classes unsold and available for registration (free) or purchase. See example below.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`external_ticketing`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`external_ticketing`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`External ticketing data for the Event.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`music_properties`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`music_properties`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Properties for a music Event.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`publish_settings`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`publish_settings`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Event publish settings.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`guestlist_metrics`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`guestlist_metrics`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Metrics of Event guestlists.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Getting Information on Event Venue`}</MDXTag>
<MDXTag name="p" components={components}>{`To get specific information on an Event Venue (location), use the expansion by
adding the `}<MDXTag name="inlineCode" components={components} parentName="p">{`expand=venue`}</MDXTag>{` parameter in the request URL.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/?expand=venue \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The Venue object attached in the Event object is returned.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  ...
  "venue": {
    "name": "Madison Square Garden",
    "age_restriction": "AGE_RESTRICTION_ALL_AGES",
    "capacity": 100,
    "id": "3003",
    "address": {
      "address_1": "",
      "address_2": "",
      "city": "",
      "region": "",
      "postal_code": "",
      "country": "",
      "latitude": 0,
      "longitude": 0,
      "localized_address_display": "",
      "localized_area_display": "",
      "localized_multi_line_address_display": []
    },
    "resource_uri": "https://www.eventbriteapi.com/v3/venues/3003/"
  },
  ...
}
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`Getting Information on Event Category`}</MDXTag>
<MDXTag name="p" components={components}>{`To find out the category of an Event (for example, music), use the expansion by
adding `}<MDXTag name="inlineCode" components={components} parentName="p">{`expand=category`}</MDXTag>{` parameter in the request URL.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/?expand=category \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The Category object attached in the Event object is returned.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  ...
  "category": {
    "id": "103",
    "resource_uri": "https://www.eventbriteapi.com/v3/categories/103/",
    "name": "Music",
    "name_localized": "Music",
    "short_name": "Music",
    "short_name_localized": "Music",
  },
  ...
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Getting Information on Event Ticket Availability`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the expansion to identify the number of tickets remaining (unsold) for an
Event, by adding the `}<MDXTag name="inlineCode" components={components} parentName="p">{`expand=ticket_availability`}</MDXTag>{` parameter in the request URL.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/?expand=ticket_availability \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The Ticket Availability object attached in the Event object is returned.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
  ...
  "ticket_availability": {
        "has_available_tickets": true,
        "minimum_ticket_price": {
            "currency": "USD",
            "value": 100,
            "major_value": "1.00",
            "display": "1.00 USD"
        },
        "maximum_ticket_price": {
            "currency": "USD",
            "value": 100,
            "major_value": "1.00",
            "display": "1.00 USD"
        },
        "is_sold_out": false,
        "start_sales_date": {
            "timezone": "America/Vancouver",
            "local": "2018-11-26T00:00:00",
            "utc": "2018-11-26T08:00:00Z"
        }
    },
  ...
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Using Orther Endpoints to Get Information on a list of Events`}</MDXTag>
<MDXTag name="p" components={components}>{`Several endpoints return information on a list of Events.`}</MDXTag>
<MDXTag name="h3" components={components}>{`List your Events by Status`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the `}<MDXTag name="inlineCode" components={components} parentName="p">{`status`}</MDXTag>{` parameter to get a list of the organization's Events with the status of
interest. The `}<MDXTag name="inlineCode" components={components} parentName="p">{`status`}</MDXTag>{` fields are:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`draft`}</MDXTag>{` - Preliminary form of a possible future Event.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`live`}</MDXTag>{` - Tickets can be obtained for the Event.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`started`}</MDXTag>{` - Start date of the Event.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`ended`}</MDXTag>{` - End date of the Event.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`canceled`}</MDXTag>{` - Canceled Events.`}</MDXTag>
</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/organizations/{organization_id}/events/?status=live \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`List your Events by Date`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the `}<MDXTag name="inlineCode" components={components} parentName="p">{`time_filter`}</MDXTag>{` parameter to get a list of the organization's Events with the start date
of interest. The `}<MDXTag name="inlineCode" components={components} parentName="p">{`time_filter`}</MDXTag>{` fields are:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`past`}</MDXTag>{` - Return past Events.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`current_future`}</MDXTag>{` - Return current and future Events.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="inlineCode" components={components} parentName="li">{`all`}</MDXTag>{` - Return all Events.`}</MDXTag>
</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/organizations/{organization_id}/events/?time_filter=current_future \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}>{`List your Events By Venue`}</MDXTag>
<MDXTag name="p" components={components}>{`You can return a list of all Events at a specific Venue by using this call`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/venues/{venue_id}/events/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Working With Ticket Classes and Ticket Groups',
            link: '/platform/docs/ticket-classes',
        },
        {
            title: 'Creating Ticket Classes in Tiered Events',
            link: '/platform/docs/ticket-classes#tiered_events',
        },
    ]}
/>
           </MDXTag>
  }
}
  