
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Understanding Organizations`}</MDXTag>
<MDXTag name="p" components={components}>{`An Organization is an Eventbrite object representing a business structure (like
a financial entity, or taxID) in which Events are owned, created and managed.
Organizations are owned by one User and can have multiple Members, and are used
to group Events, Venues, Members, Permissions and other entities.`}</MDXTag>
<MDXTag name="p" components={components}>{`The new Organization architecture was introduced in the first half of 2018, to
better support Users with more complex needs. This includes the ability for one
individual (the Organization owner) to grant and manage the permissions of other
Eventbrite Users (other members of the Organization). For example, a music
promoter may run an annual festival and multiple music venues. Now with a single
Organization account, the music promoter can granularly manage which employees,
teams or outside parties (i.e. other Eventbrite Users) have access to specific
Events, can take action on those Events, and view associated data.`}</MDXTag>
<MDXTag name="p" components={components}>{`In addition, Organizations make the experience of using integrated applications
more seamless, since your application can access information across
Organizations. Previously, Users had to maintain multiple connections to your
application through multiple Eventbrite accounts, for example for separate
Venues.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To work with Organizations, you must authenticate your access to the Eventbrite
API. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h4" components={components}>{`List Your Organizations`}</MDXTag>
<MDXTag name="p" components={components}>{`Every User has a least one Organization.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/organization/list-your-organizations/list-your-organizations"}}>{`/v3/users/me/organizations/`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` A list of all the Organizations to which you belong is returned. If
you don't belong to any Organizations, your `}<MDXTag name="inlineCode" components={components} parentName="p">{`user_id`}</MDXTag>{` is your default
Organization ID.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Changes to the Permission Model`}</MDXTag>
<MDXTag name="p" components={components}>{`Permissions are now defined, assigned and managed within the Organization
object. Users, and their associated Eventbrite accounts, belong to at least one
and possibly multiple organizations. This model provides a lot more flexibility
in managing permissions, since a permission can be granted once and used for
Events in all the User's Organizations.`}</MDXTag>
<MDXTag name="p" components={components}>{`The Organization object returns information on which Organizations a User
belongs to (is a member of) and what permissions that User has within each
Organization.`}</MDXTag>
<MDXTag name="h4" components={components}>{`List Members by Organization ID`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/organizations-members/list/list-members-of-an-organization"}}>{`/v3/organizations/{organization_id}/members/`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`List Roles by Organization ID`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/organization-roles/list/list-roles-by-organization"}}>{`/v3/organizations/{organization_id}/roles/`}</MDXTag></MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` The new Organization architecture does not impact or change the
OAuth authentication process.`}</MDXTag>
</MDXTag>
<MDXTag name="h3" components={components}>{`Migrating to the New Architecture`}</MDXTag>
<MDXTag name="p" components={components}>{`With the introduction of the Organization object, many of the Eventbrite API
endpoints have necessarily changed to indicate the Organization, rather than the
User, of an API call. For example, the endpoint previously used to list a User's
Events`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>{` /v3/users/{user_id}/events/ — deprecated`}</MDXTag>
<MDXTag name="p" components={components}>{`is now replaced by the endpoint to list an Organization's Events`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`GET`}</MDXTag>
<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Events must be created on New Create to be compatible for Organization and
Permissions.`}</MDXTag>
<MDXTag name="p" components={components}>{`Currently, all Eventbrite Music creators are on Organizations. Access to
Organizations for new Eventbrite users started to roll out on January
28th, 2020. Throughout 2020, all users will be migrated to the Organizations and
Permissions model. Access to several APIs will no longer be usable on June 1st,
2020, as they are incompatible with Organizations & Permissions.`}</MDXTag>
<MDXTag name="p" components={components}>{`Here is the list of APIs that are incompatible with Organizations and
Permissions:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Legacy`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`New`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/checkout_settings/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/checkout_settings/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/organizers/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/organizers/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/webhooks/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/webhooks/list/list-webhook-by-organization-id"}}>{`/v3/organizations/{organization_id}/webhooks/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/assortment/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/assortment/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/contact_lists/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/contact_lists/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/contact_lists/{contact_list_id}/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/currencies/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/currencies/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/discounts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/discount/list/search-discounts-by-organization"}}>{`/v3/organizations/{organization_id}/discounts/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/event_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/event_groups/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/managed_events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/organizers/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/organizers/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/organizers/default/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/organizers/default/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/owned_events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/owned_event_attendees/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/attendee/list/list-attendees-by-organization"}}>{`/v3/organizations/{organization_id}/attendees/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/owned_event_orders/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/order/organization-orders/list-orders-by-organization"}}>{`/v3/organizations/{organization_id}/orders/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/search_owned_event_orders/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/orders/search/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/ticket_classes/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/ticket_classes/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/ticket_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/ticket-group/list/list-ticket-groups-by-organization"}}>{`/v3/organizations/{organization_id}/ticket_groups/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/tracking_beacons/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/tracking_beacons/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/venues/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/venue/list/list-venues-by-organization"}}>{`/v3/organizations/{organization_id}/venues/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/assortment/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/assortment/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/contact_lists/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/contact_lists/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/contact_lists/{contact_list_id}/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/event_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/event_groups/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/checkout_settings/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/checkout_settings/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/discounts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/discount/create/create-a-discount"}}>{`/v3/organizations/{organization_id}/discounts/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/create/create-an-event"}}>{`/v3/organizatons/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/organizers/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/organizers/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/series/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/create/create-an-event"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/ticket_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/ticket-group/create/create-a-ticket-group"}}>{`/v3/organizations/{organization_id}/ticket_groups/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/venues/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/venue/create/create-a-venue"}}>{`/v3/organizations/{organization_id}/venues/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/webhooks/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/webhooks/create/create-webhooks-by-organization-id"}}>{`/v3/organizations/{organization_id}/webhooks/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`DELETE /v3/users/{user_id}/discounts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`DELETE /v3/organizations/{organization_id}/discounts/`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` There are a few new APIs that are missing links because the
documentation is currently private.`}</MDXTag>
</MDXTag>
<NextSteps
    steps={[
        {
            title: 'Authenticating Your Access to the Eventbrite API',
            link: '/platform/docs/authentication',
        },
        {
            title: 'Using Questions',
            link: '/platform/docs/questions',
        },
    ]}
/>
           </MDXTag>
  }
}
  