import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';
import { ICON_TYPE_PROP_TYPE, ICON_SIZE_PROP_TYPE } from '@eb/eds-icon';
import {
    PAYMENT_TYPE_PROP_TYPE,
    PAYMENT_SIZE_PROP_TYPE,
} from '@eb/eds-payment-icon';
import { TYPES as BUTTON_TYPES } from '@eventbrite/eds-button';

export const TEXT_ITEM_PROP_TYPE_SHAPE = PropTypes.shape({
    value: PropTypes.string.isRequired,

    buttonType: PropTypes.oneOf(BUTTON_TYPES),
    content: PropTypes.node,
    iconType: PropTypes.oneOfType([
        ICON_TYPE_PROP_TYPE,
        PAYMENT_TYPE_PROP_TYPE,
    ]),
    iconSize: PropTypes.oneOfType([
        ICON_SIZE_PROP_TYPE,
        PAYMENT_SIZE_PROP_TYPE,
    ]),
    imageAlt: translationPropType,
    imageUrl: PropTypes.string,
    isSquareImage: PropTypes.bool,
    path: PropTypes.string,
    renderContainer: PropTypes.func,
    secondaryContent: translationPropType,
    showDivider: PropTypes.bool,
    dividerVertSpacing: PropTypes.number,
    tertiaryContent: translationPropType,
});

export const TEXT_ITEMS_PROP_TYPE = PropTypes.arrayOf(
    TEXT_ITEM_PROP_TYPE_SHAPE,
);

export const TEXT_ITEMS_CHARACTER_LENGTH_MAP = {
    secondary: 88,
    tertiary: 88,
};
