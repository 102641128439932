import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eventbrite/i18n';

import { ProgressIndicator } from '@eb/eds-progress-indicator';

import {
    SHAPE_CIRCULAR,
    SIZE_SMALL_THIN,
    STYLE_GRADIENT,
} from '@eb/eds-progress-indicator';
import {
    IMAGE_LOADER_STATUSES,
    VERTICAL_ALIGNMENTS_PROP_TYPE,
} from './constants';

const ExtraContent = ({ children }) => {
    let component = null;

    if (children) {
        component = (
            <div
                className="eds-list-item__aside eds-list-item__extra-content eds-l-pad-right-4"
                data-spec="list-item-extra-content"
            >
                {children}
            </div>
        );
    }

    return component;
};

export default class ItemImage extends PureComponent {
    static propTypes = {
        /**
         * Contents of icon list item
         */
        children: PropTypes.node.isRequired,
        /**
         * Generic content for the left side
         */
        extraContent: PropTypes.node,
        /**
         * Vertical alignment of children items
         */
        verticalAlignment: VERTICAL_ALIGNMENTS_PROP_TYPE,
        /**
         * URL of image to be displayed with list item content
         */
        imageUrl: PropTypes.string,
        /**
         * Alt text for image to be displayed with list item content
         */
        imageAlt: translationPropType,
        /**
         * Optional square shape image - note you must pass in an imageUrl
         * for an image that is either 1:1 or wider than it is tall.
         */
        isSquareImage: PropTypes.bool,
        /**
         * Whether or not the item should use a progress indicator while the image is loading
         */
        progressIndicator: PropTypes.bool,
    };

    static defaultProps = {
        verticalAlignment: 'middle',
        progressIndicator: false,
        isSquareImage: false,
    };

    state = {
        imageStatus: IMAGE_LOADER_STATUSES.LOADING_STATE,
    };

    _handleImageLoaded = () =>
        this.setState({ imageStatus: IMAGE_LOADER_STATUSES.LOADED_STATE });

    _handleImageError = () =>
        this.setState({ imageStatus: IMAGE_LOADER_STATUSES.FAILED_STATE });

    render() {
        const {
            children,
            extraContent,
            imageAlt,
            imageUrl,
            isSquareImage,
            progressIndicator,
            verticalAlignment,
        } = this.props;

        let itemImageComponent;
        let progressIndicatorComponent = null;
        const { imageStatus } = this.state;

        const className = classNames('eds-list-item', {
            [`eds-list-item--align-${verticalAlignment}`]: verticalAlignment,
        });

        const classNameGraphic = classNames(
            'eds-list-item__aside',
            'eds-list-item__graphic',
            {
                'eds-list-item__graphic--square': isSquareImage,
            },
        );

        const classNameImage = classNames({
            'eds-show':
                imageStatus === IMAGE_LOADER_STATUSES.LOADED_STATE ||
                imageStatus === IMAGE_LOADER_STATUSES.FAILED_STATE,
            'eds-hide': imageStatus === IMAGE_LOADER_STATUSES.LOADING_STATE,
        });

        if (progressIndicator) {
            /**
             * In the case of  the failed load, the progress indicator should be unrendered
             * and the img tag should should the alt image.
             */
            if (imageStatus === IMAGE_LOADER_STATUSES.LOADING_STATE) {
                progressIndicatorComponent = (
                    <div className="eds-list-item__graphic-indicator eds-l-mar-all-3">
                        <ProgressIndicator
                            shape={SHAPE_CIRCULAR}
                            size={SIZE_SMALL_THIN}
                            style={STYLE_GRADIENT}
                        />
                    </div>
                );
            }

            itemImageComponent = (
                <img
                    src={imageUrl}
                    alt={imageAlt}
                    className={classNameImage}
                    onLoad={this._handleImageLoaded}
                    onError={this._handleImageError}
                    data-spec="list-item-graphic-image--progress-indicator-img"
                />
            );
        } else {
            itemImageComponent = (
                <img
                    src={imageUrl}
                    alt={imageAlt}
                    data-spec="list-item-graphic-image"
                />
            );
        }

        return (
            <div className={className} data-spec="eds-list-item">
                <ExtraContent>{extraContent}</ExtraContent>
                <span
                    className={classNameGraphic}
                    data-spec="list-item-graphic"
                >
                    {progressIndicatorComponent}
                    {itemImageComponent}
                </span>
                <div
                    className="eds-list-item__contents"
                    data-spec="eds-list-item-contents"
                >
                    {children}
                </div>
            </div>
        );
    }
}
