import _, { flatten, values } from 'lodash';
import { flow, map } from 'lodash/fp';
import { EXPAND_LINK, ROUTER_LINK } from '@eb/eds-nav-list-item';
import { PRIMARY_NAV_MAP } from '../constants/nav';

// Isolates the last token in a path
// ex: /input-field => input-field
// ex: admin/eds/input-field => input-field
const getLastTokenInPath = (path) => _.last(path.split('/'));

// Formats a token for use in a path
// ex: Input Field => input-field
// ex: Structure HOCs => structure-hocs
export const formatTokenForPath = (token) =>
    _.flow(_.toLower, _.kebabCase)(token);

// Creates a mapping of each Nav category to a flattened array
// of paths based off of each items kebab-cased label that correspond with that category
const NAV_ROUTING_MAP = _.keys(PRIMARY_NAV_MAP).reduce(
    (memo, category) => ({
        ...memo,
        [category]: flow(
            values,
            flatten,
            map('children'),
            flatten,
            map((item) => `/${formatTokenForPath(item.link)}`),
        )(PRIMARY_NAV_MAP[category]),
    }),
    {},
);

/**
 * Takes a path and performs a reverse lookup to find the key against the site map
 *
 * @param {String} path ex: '/input-field'
 * @returns {String} the corresponding key in PRIMARY_NAV_MAP
 */
export const findKeyForPath = (path) => {
    if (_.includes(path, 'docs')) {
        const strippedPath = `/${getLastTokenInPath(path)}`;
        const key = _.findKey(
            NAV_ROUTING_MAP,
            (paths) =>
                paths.includes(strippedPath) ||
                _.keys(NAV_ROUTING_MAP).includes(strippedPath),
        );

        return key;
    }
    return false;
};

/**
 * Takes a path and checks the site map for a match to a Content category and
 * returns the corresponding Navigation menu to display for the Primary Navigation
 *
 * @summary Function with e.preventDefault() & returns new state obj {focusPosition}
 * @param {String} path ex: '/input-field'
 * @returns {Array} array of objects consumed by router to populate the Primary Navigation for a given path
 */
export const getPrimaryNavigationList = (path) => {
    const navCategory = findKeyForPath(path) || getLastTokenInPath(path);

    return PRIMARY_NAV_MAP[navCategory] || [];
};

/**
 * Takes a navigation list, a current path, and a base path and returns an object
 * for the primary side nav. Removes single nesting, and highlights default item.
 *
 * @param {Array} primaryNavigationList
 * @param {String} currentPath ex:`/structure`
 * @param {String} basePath ex:`/`
 * @returns {Object} object that contains the page title and the navigation items
 */
export const getNavInfo = (
    primaryNavigationList,
    currentPath,
    basePath = '/',
) => {
    let pageTitle;

    let needDefaultItem = true;

    if (_.isEmpty(primaryNavigationList)) {
        return {
            navItems: [],
            pageTitle: 'Platform | Eventbrite',
        };
    }

    const navItems = primaryNavigationList.map(({ title, children }) => {
        const itemsChildren = children.map((childItem) => {
            const id = formatTokenForPath(childItem.link);

            let path = `${basePath}${id}`;
            const active = path === currentPath;

            // NOTE: This is a side-effect w/in the nested map in order to set
            // pageTitle based on the currentPath. Otherwise we'd have to do
            // this double loop again just to re-find the current component
            // to get its label.
            if (active) {
                pageTitle = childItem.label;
            }

            //checks to see if you've only just navigated to this page or not
            if (getLastTokenInPath(currentPath) === id) {
                needDefaultItem = false;
            }

            if (childItem.link) {
                path = `${basePath}${childItem.link}`;
            }

            let type = ROUTER_LINK;

            if (childItem.href) {
                path = childItem.href;
                type = childItem.type;
            }

            return {
                id,
                path,
                active,
                type,
                content: childItem.label,
            };
        });

        //removes single nested items

        let item = {
            content: title,
            active: false,
            items: itemsChildren,
        };

        if (itemsChildren.length === 1) {
            item = {
                ...item,
                type: _.head(itemsChildren).type,
                path: _.head(itemsChildren).path,
            };

            //sets childless items to active to show highlighting
            if (
                getLastTokenInPath(currentPath) ===
                getLastTokenInPath(item.path)
            ) {
                item.active = true;
            }

            item.items = undefined;
        } else {
            // if this item has more than one child, it sets it to an Expand Link allowing
            // you to open and close the lists without being automatically taken to the first
            // child component
            item = {
                ...item,
                type: EXPAND_LINK,
            };
        }

        return item;
    });

    // Sets the first item as active the current path does not match any of the primary nav
    // items to show highlighting
    if (needDefaultItem) {
        navItems[0].active = true;
    }

    return {
        navItems,
        pageTitle,
    };
};
