import PropTypes from 'prop-types';

import { DIALOG_PROP_TYPES, DIALOG_MESSAGE_PROPTYPE } from '@eb/eds-dialog';
import { MODAL_PROP_TYPES, MODAL_CHILDREN_PROPTYPE } from '@eb/eds-modal';
import { NOTIFICATION_OPTIONS_PROP_TYPES } from '@eb/eds-notification';

export const NOTIFICATION_POSITION_TOP = 'top';
export const NOTIFICATION_POSITION_TOAST = 'toast';
export const NOTIFICATION_POSITIONS = [
    NOTIFICATION_POSITION_TOP,
    NOTIFICATION_POSITION_TOAST,
];

export const OVERLAY_OPTIONS_PROP_TYPES = PropTypes.shape({
    ...MODAL_PROP_TYPES,
    ...DIALOG_PROP_TYPES,
    kind: PropTypes.oneOf(['dialog', 'modal']),
    children: MODAL_CHILDREN_PROPTYPE,
    message: DIALOG_MESSAGE_PROPTYPE,
});

export const BOTTOM_BAR_OPTIONS_PROP_TYPE = PropTypes.shape({
    /**
     * The content to include in the bottom bar
     */
    barContent: PropTypes.node,
    /**
     * Whether or not the bottom bar is shown
     */
    isShown: PropTypes.bool,
});

export const FOCUS_DRAWER_OPTIONS_PROP_TYPES = PropTypes.shape({
    /**
     * The content for the focus drawer
     */
    content: PropTypes.node.isRequired,
    /**
     * The title for the focus drawer
     */
    title: PropTypes.node,
    /**
     * Whether or not to hide the button that will close the focus drawer
     */
    hideClose: PropTypes.bool,
    /**
     * Function to be invoked when the focus drawer is closed.
     */
    onClose: PropTypes.func,
    /**
     * Whether or not the focus drawer should be shown
     */
    isShown: PropTypes.bool,
    /**
     * Configuration options for the bottom bar w/in the focus drawer
     */
    bottomBarOptions: BOTTOM_BAR_OPTIONS_PROP_TYPE,
    /**
     * Configuration options for the notification w/in the focus drawer
     */
    notificationOptions: NOTIFICATION_OPTIONS_PROP_TYPES,
    /**
     * Callback triggered if the user clicks outside of the focus drawer
     */
    onClickOutside: PropTypes.func,
    /**
     * Always include the focus drawer component
     */
    alwaysInclude: PropTypes.bool,
});

export const STRUCTURE_MAIN_CONTAINER_CLASSNAME =
    'eds-structure__main-container';
