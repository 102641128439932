
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`About the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`The Eventbrite REST API enables communication between Eventbrite and your
application or website using HTTP requests. Transmitted request and response
data content is formatted as JSON (although we provide a GET alternative for
requests), using UTF-8 encoding and URL encoded values.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Base URL`}</MDXTag>
<MDXTag name="p" components={components}>{`All URLs referenced in the API documentation have the following base`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbriteapi.com/v3`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`HTTP requests to the Eventbrite API are protected by requiring authentication.
To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h1" components={components}>{`API Terminology`}</MDXTag>
<MDXTag name="p" components={components}>{`Knowledge of the following terms can help you successfully use the features and
capabilities of the Eventbrite Platform.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Absorbed Fees`}</MDXTag>
<MDXTag name="p" components={components}>{`Eventbrite transaction fees included (absorbed) in the displayed ticket price;
the credit card processing fees and taxes are not split out from the base ticket
price.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Attendee`}</MDXTag>
<MDXTag name="p" components={components}>{`Individual holding a ticket to, and planning to attend, an Event.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Attendee Team`}</MDXTag>
<MDXTag name="p" components={components}>{`A grouping of Event attendees, for example a baseball team in a baseball
tournament.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Bookmark`}</MDXTag>
<MDXTag name="p" components={components}>{`A visitor to the Eventbrite website has saved the Event as a bookmark.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Capacity`}</MDXTag>
<MDXTag name="p" components={components}>{`Maximum number of tickets that can be sold for an Event.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Category`}</MDXTag>
<MDXTag name="p" components={components}>{`The Event class, for example Music.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Endpoint`}</MDXTag>
<MDXTag name="p" components={components}>{`A URL that makes up part of the Eventbrite API; each resource is exposed at an
API endpoint. These endpoints are used to integrate Eventbrite with other
applications by accepting web requests.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Expansion`}</MDXTag>
<MDXTag name="p" components={components}>{`To limit the number of calls to the API, and the size of the responses, usually
responses are short and display only a basic representation (subset) of the
resource. However, you can obtain additional expanded information on certain
elements by using an expansion.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Format`}</MDXTag>
<MDXTag name="p" components={components}>{`Type of event, for example conference, seminar, concert.`}</MDXTag>
<MDXTag name="h2" components={components}>{`HTTP Verbs (Methods)`}</MDXTag>
<MDXTag name="p" components={components}>{`The Eventbrite API uses the following HTTP verbs (methods) appropriate for each
action.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`HTTP Verb`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Description`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Example Action`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`GET`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Retrieve a resource`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`List all Events by Venue`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`POST`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Create or update a resource`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Create or an Event`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}><MDXTag name="inlineCode" components={components} parentName="td">{`DELETE`}</MDXTag></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Delete a resource`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`Delete an Event`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components}>{`Object`}</MDXTag>
<MDXTag name="p" components={components}>{`An object in the Eventbrite API that represents a business noun, like Event or
Attendee. Also referred to as a resource.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Order Owner`}</MDXTag>
<MDXTag name="p" components={components}>{`Individual purchasing an Event ticket. Not necessarily an Attendee, as the Order
Owner may be purchasing tickets for someone else.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Organization`}</MDXTag>
<MDXTag name="p" components={components}>{`An object representing a business structure (like a Marketing department) in
which Events are created and managed. Organizations are owned by one User and
can have multiple members.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Organizer`}</MDXTag>
<MDXTag name="p" components={components}>{`Object representing the public facing entity organizing the Event. Organizer
does not have an Eventbrite account and does not own Events.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Pagination`}</MDXTag>
<MDXTag name="p" components={components}>{`Process of splitting up results into manageable chunks. All API endpoints that
return results are paginated. These results are listed in a defined order in
groups of 50, with the first page shown. To view additional result pages, you
must specify in the request the page you want to see.`}</MDXTag>
<MDXTag name="h2" components={components}>{`REST`}</MDXTag>
<MDXTag name="p" components={components}>{`A software architectural style. REST defines a set of constraints used for
creating web services and manipulating resources. The Eventbrite API uses REST.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Subcategory`}</MDXTag>
<MDXTag name="p" components={components}>{`The Event subclass, for example Classical.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Ticket Class`}</MDXTag>
<MDXTag name="p" components={components}>{`A ticket type. Typically, multiple different types of tickets for an Event are
available. There are three Eventbrite Ticket Classes: Free, Paid and Donation.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Ticket Price`}</MDXTag>
<MDXTag name="p" components={components}>{`The total ticket price is made up of the Ticket Base Price + Eventbrite Fee +
Credit Card Fee + Tax.`}</MDXTag>
<MDXTag name="h2" components={components}>{`User`}</MDXTag>
<MDXTag name="p" components={components}>{`Has an Eventbrite account, is a member of one or more Organizations, and may
have the necessary permissions to create and manage Events.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Viewport`}</MDXTag>
<MDXTag name="p" components={components}>{`A rectangular section of a map, that is currently rendered, showing the Event
location.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Visitor`}</MDXTag>
<MDXTag name="p" components={components}>{`A person viewing an Event on the Eventbrite website, but they have not yet
become an Order Owner by purchasing tickets.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Authenticating Your Access to the Eventbrite API',
            link: '/platform/docs/authentication',
        },
        {
            title: 'Exploring Endpoints Using the API Explorer',
            link: '/platform/docs/api-explorer',
        },
    ]}
/>
           </MDXTag>
  }
}
  