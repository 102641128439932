import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './fixedBottomBarLayout.scss';

export default class FixedBottomBarLayout extends PureComponent {
    static propTypes = {
        /**
         * The main content
         */
        children: PropTypes.node.isRequired,
        /**
         * The content to include in the bottom bar
         */
        barContent: PropTypes.node,
        /**
         * Whether or not the bottom bar is positioned absolute or fixed
         * When Structure hasIndependentScrolling is true, the bottom bar needs absolute positioning.
         * For bottom bar to work without hasIndependentScrolling, it needs fixed positioning.
         */
        useFixedPositioning: PropTypes.bool,
        /**
         * Whether or not the bottom bar is shown
         */
        isShown: PropTypes.bool,
    };

    static defaultProps = {
        useFixedPositioning: false,
        isShown: false,
    };

    render() {
        const {
            children,
            barContent,
            useFixedPositioning,
            isShown,
        } = this.props;
        const className = classNames('eds-fixed-bottom-bar-layout', {
            'eds-fixed-bottom-bar-layout--absolute': !useFixedPositioning,
            'eds-fixed-bottom-bar-layout--shown': isShown,
        });

        return (
            <div className={className} data-spec="fixed-bottom-bar-layout">
                <div className="eds-fixed-bottom-bar-layout__content">
                    {children}
                </div>
                <div className="eds-fixed-bottom-bar-layout__bar">
                    {barContent}
                </div>
            </div>
        );
    }
}
