
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Integrate with Eventbrite Manage`}</MDXTag>
<MDXTag name="h2" components={components}>{`How to Receive the OAuth Token Inside Your App`}</MDXTag>
<MDXTag name="p" components={components}>{`Installed apps are displayed inside an iframe on Eventbrite. The iframe URL will
include an `}<MDXTag name="inlineCode" components={components} parentName="p">{`?esr=<ESR>`}</MDXTag>{` query parameter. The ESR is a
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://jwt.io/"}}>{`JSON Web Token`}</MDXTag>{` with encoded data for the current `}<MDXTag name="inlineCode" components={components} parentName="p">{`user_id`}</MDXTag>{`,
their `}<MDXTag name="inlineCode" components={components} parentName="p">{`auth_token`}</MDXTag>{`, an optional `}<MDXTag name="inlineCode" components={components} parentName="p">{`event_id`}</MDXTag>{` for the event they will be managing
and is then signed with the client secret.`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Note: The key is called `}<MDXTag name="inlineCode" components={components} parentName="p">{`auth_token`}</MDXTag>{`.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`You can decode the JSON Web Token using a npm package, such as
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.npmjs.com/package/jsonwebtoken"}}>{`jsonwebtoken`}</MDXTag>{`. In the example
below, we are using the `}<MDXTag name="inlineCode" components={components} parentName="p">{`verify`}</MDXTag>{` method to decode the ESR query parameter
passing our `}<MDXTag name="inlineCode" components={components} parentName="p">{`CLIENT_SECRET`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-javascript"}}>{`try {
    var decodedToken = jwt.verify(query.esr, CLIENT_SECRET);
} catch (e) {
    // handle error
}
`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Iframe Sizing`}</MDXTag>
<MDXTag name="p" components={components}>{`To operate as an iframe within Eventbrite, your app must support a javascript
API that coordinates events between your code and Eventbrite. This helps handle
cases like resizing the window or other changes reflected in the container page.`}</MDXTag>
<MDXTag name="p" components={components}>{`Your app is expected to load this javascript and trigger events which inform the
parent iframe container when your app is ready or needs updating.`}</MDXTag>
<MDXTag name="p" components={components}>{`You can load it with the following snippet:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`<script src="https://ebmedia.eventbrite.com/s3-build/26869-rc2015-01-26-a5d995d/django/js/src/plugins/api.js"></script>
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Once your app is ready, it should call `}<MDXTag name="inlineCode" components={components} parentName="p">{`init()`}</MDXTag>{` to signal to the container:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-javascript"}}>{`EB.FrameAPI.init();
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This informs the container that your app is ready and rendered so it can
properly size the iframe and show it to the user.`}</MDXTag>
<MDXTag name="p" components={components}>{`Later, if you change your content and require a re-render, you should call
`}<MDXTag name="inlineCode" components={components} parentName="p">{`EB.FrameAPI.resize()`}</MDXTag>{` which informs the container of a need to attempt to
resize the iframe.`}</MDXTag>
<MDXTag name="p" components={components}>{`Likewise, your app can be informed when the container is attempting to resize
(due to the user changing sizes or orientations for example) by listening for
events by passing a callback to `}<MDXTag name="inlineCode" components={components} parentName="p">{`init()`}</MDXTag>{`:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-javascript"}}>{`EB.FrameAPI.init({
    callback: function(event) {
        ...
    }
})
`}</MDXTag></MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`Note: If your app performs client-side rendering that modifies the layout, you
must trigger `}<MDXTag name="inlineCode" components={components} parentName="p">{`resize()`}</MDXTag>{` after to allow the container to keep your iframe sized
correctly.`}</MDXTag>
</MDXTag>
<NextSteps
    steps={[
        {
            title: 'App Product Page',
            link: '/platform/docs/app-product-page',
        },
    ]}
/>
           </MDXTag>
  }
}
  