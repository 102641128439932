
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Obtaining Attendee Information`}</MDXTag>
<MDXTag name="p" components={components}>{`An Attendee is an individual holding a ticket to, and planning to attend or
having attended, an Event. The model is one Attendee per ticket.`}</MDXTag>
<MDXTag name="p" components={components}>{`The Eventbrite API allows you to obtain information on Attendees. This
information can be used, for example, to manage your Attendees in a workflow
other than the Eventbrite website.`}</MDXTag>
<MDXTag name="p" components={components}>{`To retrieve information on Attendees of an Event, you need the ID of both the
Attendee and Event of interest. To find an Event ID, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/event"}}>{`Getting Information on an Event`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`Authenticating your access to the Eventbrite API is required to obtain Attendee
information. To learn how, refer to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Retrieving Information on a Single Attendee of an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`To retrieve information on a single Attendee of an Event, use the following
request`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/attendees/{attendee_id}/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Information returned includes whether the Attendee checked into the Event. The
`}<MDXTag name="inlineCode" components={components} parentName="p">{`checked-in`}</MDXTag>{` attribute shows `}<MDXTag name="inlineCode" components={components} parentName="p">{`true`}</MDXTag>{` = Attendee has checked in, `}<MDXTag name="inlineCode" components={components} parentName="p">{`false`}</MDXTag>{` =
Attendee has not checked in. The `}<MDXTag name="inlineCode" components={components} parentName="p">{`status`}</MDXTag>{` attribute provides the actual value
used for the `}<MDXTag name="inlineCode" components={components} parentName="p">{`checked-in`}</MDXTag>{` attribute, for example `}<MDXTag name="inlineCode" components={components} parentName="p">{`"Checked In"`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`An example response of a single Attendee Object`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "team": null,
    "costs": {
        "base_price": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "eventbrite_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "gross": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "payment_fee": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        },
        "tax": {
            "display": "CA\$0.00",
            "currency": "CAD",
            "value": 0,
            "major_value": "0.00"
        }
    },
    "resource_uri": "https://www.eventbriteapi.com/v3/events/12345/attendees/12345/",
    "id": "12345",
    "changed": "2018-11-02T22:36:48Z",
    "created": "2018-11-02T19:07:24Z",
    "quantity": 1,
    "variant_id": null,
    "profile": {
        "first_name": "Kennedy",
        "last_name": "Singleton",
        "email": "kennedy@gmail.com",
        "name": "Kennedy Singleton",
        "addresses": {
            "home": {},
            "ship": {},
            "work": {},
            "bill": {},
            "fulfillment": {}
        }
    },
    "barcodes": [
        {
            "status": "used",
            "barcode": "1234567435234",
            "created": "2018-11-02T19:07:24Z",
            "changed": "2018-11-02T22:36:48Z",
            "checkin_type": 0,
            "checkin_method": "search",
            "is_printed": false
        }
    ],
    "answers": [],
    "checked_in": true,
    "cancelled": false,
    "refunded": false,
    "affiliate": null,
    "guestlist_id": null,
    "invited_by": null,
    "status": "Checked In",
    "ticket_class_name": "General Admission",
    "delivery_method": "electronic",
    "event_id": "12345",
    "order_id": "123123123",
    "ticket_class_id": "123123123"
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Information on all Attendees of an Event`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the following request to get information on all Attendees of an Event.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/attendees/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`This API call returns an array of Attendee objects as a paginated response, for
example`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "pagination": {
        "object_count": 6,
        "page_number": 1,
        "page_size": 50,
        "page_count": 1,
        "has_more_items": false
    },
    "attendees": [...]
}
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Obtaining Information on All Attendees of an Order`}</MDXTag>
<MDXTag name="p" components={components}>{`Since an Order Owner can purchase additional Event tickets for someone else, an
Order can be made up of multiple tickets with multiple Attendees. You can obtain
information on all the Attendees of an Order by using the following GET request
URL. You must know the ID of the Order of interest; refer to the Working with
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/orders"}}>{`Orders`}</MDXTag>{` topic for details on listing the Orders of an
Event.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/orders/{order_id}/attendees/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Information on Attendee Status`}</MDXTag>
<MDXTag name="p" components={components}>{`You can further filter Attendee data by status. Status can be `}<MDXTag name="inlineCode" components={components} parentName="p">{`attending`}</MDXTag>{` (i.e.
checked into the Event), `}<MDXTag name="inlineCode" components={components} parentName="p">{`not_attending`}</MDXTag>{` (i.e. not checked into the Event), or
`}<MDXTag name="inlineCode" components={components} parentName="p">{`unpaid`}</MDXTag>{`. For example, to get details on all Attendees who have checked into the
Event, use the call`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/attendees?status=attending \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Understanding Organizations',
            link: '/platform/docs/organizations',
        },
        {
            title: 'Working with Orders',
            link: '/platform/docs/orders',
        },
    ]}
/>
           </MDXTag>
  }
}
  