import headerBgImg from '../static/images/home/crowd-illustration.svg';
import ticketingBg from '../static/images/home/ticketing/ticketing_sp1_1@2x.png';
import ticketingFeature from '../static/images/home/ticketing/ticketing_sp1_2@2x.png';
import reportingBg from '../static/images/home/reporting/reporting_sp2_1@2x.png';
import reportingFeature from '../static/images/home/reporting/reporting_sp2_2@2x.png';
import searchBg from '../static/images/home/search/search_sp3_1@2x.png';
import searchFeature from '../static/images/home/search/search_sp3_2@2x.png';
import searchFg from '../static/images/home/search/search_sp3_3@2x.png';
import developBg from '../static/images/home/develop/radial.svg';
import developBit from '../static/images/home/develop/bandsintown_logo.svg';
import developEb from '../static/images/home/develop/eventbritecircle_logo.svg';
import developFb from '../static/images/home/develop/facebook_logo.svg';
import developFun from '../static/images/home/develop/funbox_logo.svg';
import developMc from '../static/images/home/develop/mailchimp_logo.svg';
import developRad from '../static/images/home/develop/radario_logo.svg';
import developSpot from '../static/images/home/develop/spotify_logo.svg';
import developTone from '../static/images/home/develop/toneden_logo.svg';
import logo1 from '../static/images/home/logo-bar/wanderlust_logo@2x.png';
import logo2 from '../static/images/home/logo-bar/mailchimp_logo@2x.png';
import logo3 from '../static/images/home/logo-bar/facebook_logo@2x.png';
import logo4 from '../static/images/home/logo-bar/spotify_logo@2x.png';
import logo5 from '../static/images/home/logo-bar/milkstreet_logo@2x.png';

export const HEROBG = {
    url: headerBgImg,
    size: '627px 456px',
    pos: 'right bottom -1px',
};

// Animations for landing page features
const TICKETING_ANIMATION = [
    {
        start: 'self',
        duration: '300px',
        startOffset: '25vh',
        properties: [
            {
                startValue: 0,
                endValue: 1,
                property: 'opacity',
            },
        ],
    },
];

const SEARCH_ANIMATION = [
    {
        start: 'self',
        duration: '150vh',
        properties: [
            {
                startValue: 270,
                endValue: 315,
                property: 'rotate',
            },
        ],
    },
];

const ROTATE_CW = [
    {
        start: '.develop-parallax',
        duration: '150vh',
        properties: [
            {
                startValue: 1,
                endValue: 135,
                property: 'rotate',
            },
        ],
    },
];

const ROTATE_COUNTER_CW = [
    {
        start: '.develop-parallax',
        duration: '150vh',
        properties: [
            {
                startValue: 1,
                endValue: -135,
                property: 'rotate',
            },
        ],
    },
];

// Landing page feature details
export const HOMEFEATURES = {
    ticketing: {
        heading: 'Add ticketing to your app or website',
        description:
            'Collect registrations and sell tickets directly on your website with a custom payment form or embed a checkout experience onto your website.',
        linkUrl: '/platform/docs/embedded-checkout',
        linkText: 'Add a checkout flow',
        images: [ticketingBg, ticketingFeature],
        animation: TICKETING_ANIMATION,
    },
    reporting: {
        heading: 'Create custom reports and analytics',
        description:
            'View and manage all your Eventbrite data, attendees, orders, and more. Build customized reports, CRM integrations, and tools to better manage your events.',
        linkUrl: '/platform/docs/events',
        linkText: 'Build custom reports',
        images: [reportingBg, reportingFeature],
        animation: TICKETING_ANIMATION,
    },
    search: {
        heading: 'Bring events to your application',
        description:
            'Access Eventbrite’s database of public events and share them with your audience. Filter events by location or category and pull event data on to your website or app.',
        linkUrl: '/platform/docs/by-location',
        linkText: 'Show events on your site',
        images: [searchBg, searchFeature, searchFg],
        animation: SEARCH_ANIMATION,
    },
    develop: {
        heading: 'Build apps for 700,000 event creators',
        description:
            'Integrate your app with Eventbrite, the ticketing and event technology solution trusted by more than 700,000 event creators.',
        linkUrl: '/l/partner-program/',
        linkText: 'Partner with Eventbrite',
        images: [
            developBg,
            developEb,
            developSpot,
            developFb,
            developBit,
            developFun,
            developTone,
            developRad,
            developMc,
        ],
        animation: [ROTATE_CW, ROTATE_COUNTER_CW],
    },
};

export const LOGOBARLOGOS = [
    {
        name: 'Wanderlust',
        img: logo1,
        isVisibleMobile: false,
    },
    {
        name: 'Mailchimp',
        img: logo2,
        isVisibleMobile: true,
    },
    {
        name: 'Facebook',
        img: logo3,
        isVisibleMobile: true,
    },
    {
        name: 'Spotify',
        img: logo4,
        isVisibleMobile: true,
    },
    {
        name: 'Milk Street',
        img: logo5,
        isVisibleMobile: false,
    },
];

export const LOGOBARTITLE = {
    __html:
        'Trusted by the world&rsquo;s most innovative companies &mdash; big and small',
};
