
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h1" components={components}>{`Searching Events by Category - Deprecated`}</MDXTag>
<MDXTag name="p" components={components}>{`Access to the Eventbrite
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event-search"}}>{`Event Search API`}</MDXTag>{` will be shut down at
11:59 pm PT on December 12th, 2019.`}</MDXTag>
<MDXTag name="p" components={components}>{`For more information, refer to the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/changelog"}}>{`changelog`}</MDXTag>{`.`}</MDXTag>
           </MDXTag>
  }
}
  