
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Managing App Reviews`}</MDXTag>
<MDXTag name="h2" components={components}>{`Overview`}</MDXTag>
<MDXTag name="p" components={components}>{`After an event creator installs your app, they can leave a review for it. Once
your app receives 5 reviews, your app’s rating and reviews will be publicly
displayed on your app product page.`}</MDXTag>
<MDXTag name="p" components={components}>{`Positive reviews can encourage customers to use your app and are also an
important part of getting recommended or featured in the Eventbrite App
Marketplace.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Asking for ratings & reviews`}</MDXTag>
<MDXTag name="p" components={components}>{`We recommend asking for reviews when users are most likely to feel satisfaction
with your app, such as when they’ve completed an action or a task.`}</MDXTag>
<MDXTag name="p" components={components}>{`When asking customers to leave a review, best practice is to use neutral
language and avoid explicitly asking for a good review. For example: “We value
your feedback and are passionate about improving your experience with our app.
Let us know how we’re doing.”`}</MDXTag>
<MDXTag name="p" components={components}>{`You can also send users directly to the review model by including this query
parameter in the link to your app product page.`}</MDXTag>
<MDXTag name="p" components={components}>{`For example:`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`https://www.eventbrite.com/apps/APP_SLUG/?open_review_modal=true`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` Make sure to replace APP_SLUG with the slug of your app product page.`}</MDXTag>
<MDXTag name="p" components={components}>{`Ensure that your support contact information is easy to find and added to your
app product page in the Eventbrite App Marketplace. This gives users a direct
way to contact you if they encounter difficulties, and gives you an opportunity
to resolve negative experiences that may otherwise lead to poor reviews.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Replying to reviews`}</MDXTag>
<MDXTag name="p" components={components}>{`Addressing feedback directly on your Eventbrite App Marketplace product page can
help to build better relationships with your app users and improve your app’s
rating.`}</MDXTag>
<MDXTag name="p" components={components}>{`When an event creator leaves a review you’ll be notified by email and have the
option to respond. The ideal response is concise and clearly addresses the event
creator’s feedback. Keep your responses respectful and use language that’s
consistent with the voice of your brand. When possible, we recommend
personalizing your replies rather than using generic or templated responses.`}</MDXTag>
<MDXTag name="p" components={components}>{`Whether it’s helping solve a problem in a negative review, thanking positive
reviews, or responding with an update to a feature request or bug, the ability
to reply to reviews allows for a two-way conversation between you and the event
creators who have rated and left feedback for your app.`}</MDXTag>
<NextSteps
    steps={[
        {
            title: 'App Product Page',
            link: '/platform/docs/app-product-page',
        },
    ]}
/>
           </MDXTag>
  }
}
  