
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Creating an Event Description`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Note`}</MDXTag>{`: The event description used to live on `}<MDXTag name="inlineCode" components={components} parentName="p">{`event.description`}</MDXTag>{`, but
`}<MDXTag name="inlineCode" components={components} parentName="p">{`event.description`}</MDXTag>{` will be deprecated soon.`}</MDXTag>
<MDXTag name="p" components={components}>{`The event description is comprised of a summary + the content in Structured
Content.`}</MDXTag>
<MDXTag name="p" components={components}>{`Please use `}<MDXTag name="inlineCode" components={components} parentName="p">{`event.summary`}</MDXTag>{` to create a shortened summary of your event. Use the
concept of 'structured content' and 'modules' to create rich multi media event
descriptions. Please see
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event-description"}}>{`Event Description API Reference`}</MDXTag>{`
and
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content"}}>{`Structured Content API Reference`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`Structured content works as an insert only system, so any time you want to
update module(s), you will need to resubmit all of the content you had
previously submitted plus any changes. Structured content also works as a
versioned system, so you will need to increment with a new version every time.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Important note:`}</MDXTag>{` At Eventbrite, we consider the event description to be both
the event summary (aka `}<MDXTag name="inlineCode" components={components} parentName="p">{`event.summary`}</MDXTag>{`) + an event's structured content. If you
just make an api call only to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#retrieve-structured-content"}}>{`Retrieve Structured Content`}</MDXTag>{`, that will not be
the event description, as it will be missing the event summary. If you make an
api call to
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#retrieve-event-description-as-html"}}>{`Retrieve Event Description as HTML`}</MDXTag>{`, that
will give you the entire event description (summary + content in Structured
Content).`}</MDXTag>
<MDXTag name="p" components={components}>{`This entire tutorial deals with the api side that powers the Event Details
portion of our Event Create product, as well as the individual Event Listing.
For more indepth explanation about the api endpoints themselves, please see
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event-description"}}>{`Event Description API Reference`}</MDXTag>{`
and
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content"}}>{`Structured Content API Reference`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Modules in Structured Content`}</MDXTag>
<MDXTag name="p" components={components}>{`The three basic modules available are:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#text-module"}}>{`Text Module`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#image-module"}}>{`Image Module`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"#video-module"}}>{`Video Module`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Note: on GET requests, each module comes with some extra properties that for the
most part will remain null - properties such as `}<MDXTag name="inlineCode" components={components} parentName="p">{`layout`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`timed_display`}</MDXTag>{`, and
`}<MDXTag name="inlineCode" components={components} parentName="p">{`semantic_purpose`}</MDXTag>{`. You can safely ignore those in the context of the event
description.`}</MDXTag>
<MDXTag name="p" components={components}>{`For more in-depth information regarding modules and their properties, please
refer to the structured content api documentation.`}</MDXTag>
<MDXTag name="p" components={components}>{`Also note that below are just examples of the data structures for the modules
themselves, not how to actually the whole api call. For that, please jump to
section
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"#creating-and-updating-structured-content"}}>{`Creating and Updating Structured Content`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><h3 id="text-module">{`Text Module`}</h3>{`
The text module accepts both plain text and html as strings.  If you are submitting HTML, the only accepted tags are:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`caption: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<aside>`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`quote: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<blockquote>`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`lists: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<ul>`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`<ol>`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`<li>`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`headers: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<h1>`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`<h2>`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`<h3>`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`paragraph: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<p>`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`bold & emphasis: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<b>`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="li">{`<em>`}</MDXTag></MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`link: `}<MDXTag name="inlineCode" components={components} parentName="li">{`<a>`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for text module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "data": {
        "body": {
            "alignment": "left",
             "text": "<h2>Hello World</h2><ul><li>Hello Northern Hemisphere</li><li>Hello Southern Hempisphere</li></ul><p></p><p>It&#x27;s a <em>beautiful</em> day today!</p>"
        },
        "type": "text",
    },
    "type": "text"
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET response format for text module`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "data": {
        "body": {
            "alignment": "left",
             "text": "<h2>Hello World</h2><ul><li>Hello Northern Hemisphere</li><li>Hello Southern Hempisphere</li></ul><p></p><p>It&#x27;s a <em>beautiful</em> day today!</p>"
        },
    },
    "type": "text",
    "id": "12345"
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><h3 id="image-module">{`Image Module`}</h3>{`
In order to use the image module, you will first need to upload an image to the Eventbrite
media service. Then you will submit the image_id as part of the image module data.`}</MDXTag>
<MDXTag name="p" components={components}>{`To upload an image to the Eventbrite media service, please follow the steps
located at `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/image-upload"}}>{`Image Upload`}</MDXTag>{`. In the `}<MDXTag name="strong" components={components} parentName="p">{`Retrieving the
Upload Token`}</MDXTag>{` section, you will need to replace `}<MDXTag name="inlineCode" components={components} parentName="p">{`type=image-event-logo`}</MDXTag>{` with
`}<MDXTag name="inlineCode" components={components} parentName="p">{`type=image-structured-content`}</MDXTag>{`. You will need to do the same with `}<MDXTag name="strong" components={components} parentName="p">{`Uploading
the Image File to the Specified URL`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format with image_id`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "data": {
        "image": {
            "type": "image",
            "image_id": "8561955"
        }
    },
    "type": "image"
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET response format with image_id`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "data": {
        "image": {
            "aspect_ratio": "1.67"
            "corner_style": "rectangular"
            "crop_mask": {}
            "edge_color": "#77634e"
            "edge_color_set": true
            "id": "85619535"
            "image_id": "85619535"
            "original": {
                "url": "https://img.evbuc.com/etc",
                "width": 604,
                "height": 453
            },
            "url": "https://img.evbuc.com/etc"
        },
    "id": "29657266",
    "type": "image",
}
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><h3 id="video-module">{`Video Module`}</h3>{`
Note: currently we only support YouTube and Vimeo videos.`}</MDXTag>
<MDXTag name="p" components={components}>{`To create a video module, send in a valid YouTube or Vimeo link. You will
receive back an embedded url and thumbnail url.`}</MDXTag>
<MDXTag name="h4" components={components}>{`POST format for video`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "data": {
        "video": {
            "url": "https://www.youtube.com/watch?v=-pAz5X7ZW4",
            "display_size": "large"
        }
    },
    "type": "video"
}
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`GET response format for video`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "data": {
        "video": {
            "display_size": "large"
            "embed_url": "https://youtube.com/embed/-pAz5X7ZW4/"
            "thumbnail_url": "https://i.ytimg.com/vi/-pAz5X7ZW4/sddefault.jpg"
            "url": "https://www.youtube.com/watch?v=-pAz5XxZW4"
        },
        "id": "29665964"
    }
}
`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Creating and Updating Structured Content`}</MDXTag>
<MDXTag name="p" components={components}>{`Related api reference:
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#set-structured-content-by-event-id-and-version"}}>{`Set Structured Content by Event Id and Version`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Structured content works as an insert only system, so any time you want to
update module(s), you will need to resubmit all of the content you had
previously submitted plus any changes. Structured content also works as a
versioned system, so you will need to increment with a new version every time.
The first time you submit, you will want to submit with `}<MDXTag name="inlineCode" components={components} parentName="p">{`version_number=1`}</MDXTag>{` and
every time after, increment the current `}<MDXTag name="inlineCode" components={components} parentName="p">{`page_version_number`}</MDXTag>{` every time after.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Note:`}</MDXTag>{` in order to make the api work correctly, you MUST send in `}<MDXTag name="inlineCode" components={components} parentName="p">{`publish=true`}</MDXTag>{`
every time. If you are not seeing your most current updates, it is probably
because you forgot to send in `}<MDXTag name="inlineCode" components={components} parentName="p">{`publish=true`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`2nd Note:`}</MDXTag>{` It is adviseable you set `}<MDXTag name="inlineCode" components={components} parentName="p">{`purpose=listing`}</MDXTag>{` to ensure that the
structured content is set for the listing/event description (as there are other
purposes that structured content is used for). No worries if you don't, as the
default in the back end is `}<MDXTag name="inlineCode" components={components} parentName="p">{`listing`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id}/structured_content/{version_number} \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
       "modules":[
          {
             "type":"text",
             "data":{
                "body":{
                   "type":"text",
                   "text":"<h2>Hello World</h2><ul><li>Hello Northern Hemisphere</li><li>Hello Southern Hempisphere</li></ul><p></p><p>It's a <em>beautiful</em> day today!</p><aside><em></em></aside>",
                   "alignment":"left"
                }
             }
          },
          {
             "type":"image",
             "data":{
                "image":{
                   "type":"image",
                   "image_id":"8561955"
                }
             }
          },
          {
             "type":"video",
             "data":{
                "video":{
                   "url":"https://www.youtube.com/watch?v=-pAz5X7ZW4",
                   "display_size":"large"
                }
             }
          }
       ],
       "publish":true,
       "purpose":"listing"
    }'
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="p" components={components}>{`See `}<MDXTag name="a" components={components} parentName="p" props={{"href":"#retrieve-structured-content"}}>{`Retrieve Structured Content`}</MDXTag>{` for an example
of the api response.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Set Event Summary`}</MDXTag>
<MDXTag name="p" components={components}>{`This is to set the event summary, a shortened explanation of the event, with
some rich HTML. This is NOT considered the event description.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X POST \
  https://www.eventbriteapi.com/v3/events/{event_id} \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
  -H "Accept: application/json"
  -d '{
       "event":{
          "descrption":{
             "html": "<b>I'm the event summary</b>"
          },
      }
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "name": {
        "text": "Hello World Day",
        "html": "Hello World Day"
    },
    "description": {
        "text": "I'm the event summary",
        "html": "<B>I'm the event summary</B>"
    },
    "id": "1234556778",
    "url": "https://www.eventbrite.com/e/hello-world-tickets-1234556778",
    ...basic event info
}
`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`Getting Event Description`}</MDXTag>
<MDXTag name="p" components={components}><h3 id="retrieve-structured-content">{`Retrieve Structured Content`}</h3></MDXTag>
<MDXTag name="p" components={components}>{`Related api reference:
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/structured-content#retrieve-latest-published-version-of-structured-content-by-event-id"}}>{`Retrieve Latest Published Version of Structured Content`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Retrieve structured content with published full list of modules (aka the 'raw'
module list).`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Note:`}</MDXTag>{` It is adviseable you set `}<MDXTag name="inlineCode" components={components} parentName="p">{`purpose=listing`}</MDXTag>{` to ensure that the structured
content is set for the listing/event description (as there are other purposes
that structured content is used for). No worries if you don't, as the default in
the back end is `}<MDXTag name="inlineCode" components={components} parentName="p">{`listing`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/structured_content/?purpose=listing \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
   "resource_uris":{
      "self":"https://www.eventbriteapi.com/v3/events/83068416871/structured_content/15/",
      "add_module":"https://www.eventbriteapi.com/v3/events/83068416871/structured_content/15/module/",
      "publish":"https://www.eventbriteapi.com/v3/events/83068416871/structured_content/15/publish/",
      "preview_module":"https://www.eventbriteapi.com/v3/events/structured_content/module/preview/"
   },
   "page_version_number":"1",
   "purpose":"listing",
   "modules":[
      {
         "resource_uris":{
            "self":"module/29665960/"
         },
         "id":"29665960",
         "type":"text",
         "layout":null,
         "data":{
            "body":{
               "text":"<h2>Hello World<\/h2><ul><li>Hello Northern Hemisphere<\/li><li>Hello Southern Hempisphere<\/li><\/ul><p><\/p><p>It's a <em>beautiful<\/em> day today!<\/p><aside><em><\/em><\/aside>",
               "alignment":"left"
            }
         },
         "semantic_purpose":null,
         "timed_display":null
      },
      {
         "resource_uris":{
            "self":"module/29665962/"
         },
         "id":"29665962",
         "type":"image",
         "layout":null,
         "data":{
            "image":{
               "edge_color_set":true,
               "edge_color":"#77634e",
               "corner_style":"rectangular",
               "url":"https://img.evbuc.com/etc",
               "id":"85619535",
               "image_id":"85619535",
               "crop_mask":{

               },
               "aspect_ratio":"1.442386831275720164609053498",
               "original":{
                  "url":"https://img.evbuc.com/etc",
                  "width":2804,
                  "height":1944
               }
            }
         },
         "semantic_purpose":null,
         "timed_display":null
      },
      {
         "resource_uris":{
            "self":"module/29665964/"
         },
         "id":"29665964",
         "type":"video",
         "layout":null,
         "data":{
            "video":{
               "url":"https://www.youtube.com/watch?v=-pAz5X7ZW4",
               "display_size":"large",
               "thumbnail_url":"https://i.ytimg.com/vi/-pAz5X7ZW4/sddefault.jpg",
               "embed_url":"https://youtube.com/embed/-pAz5X7ZW4/"
            }
         },
         "semantic_purpose":null,
         "timed_display":null
      }
   ],
   "pagination":{
      "object_count":3,
      "page_number":1,
      "page_size":50,
      "page_count":1
   }
}
`}</MDXTag></MDXTag>
<h3 id="retrieve-event-description-as-html">
    <a href="/platform/api#/reference/event-description#retrieve-html-description">
        Retrieve HTML Description
    </a>
</h3>
<MDXTag name="p" components={components}>{`Get the event summary + structured content as HTML.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id}/description/ \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "description": "<div>asdg</div>\n<div style=\"margin-top: 20px\"><div><div style=\"margin:20px 10px;font-size:15px;line-height:22px;font-weight:400;text-align:left;\"><h2>Hello World</h2><ul><li>Hello Northern Hemisphere</li><li>Hello Southern Hempisphere</li></ul><p></p><p>It's a <em>beautiful</em> day today!</p><aside><em></em></aside></div><div style=\"margin:20px 0;line-height:22px;\"><img src=\"https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F856195%2F2486666781%2F1%2Foriginal.20191227-202857?h=2000&amp;w=720&amp;auto=format%2Ccompress&amp;q=75&amp;sharp=10&amp;s=e3242109fb3d7699e4a0c9bb0ca6\"/></div><div style=\"margin:20px 0;line-height:22px;\"><iframe src=\"https://youtube.com/embed/-pAz5Xx7W4/\" frameborder=\"0\" allowfullscreen=\"\"></iframe></div></div></div>\n"
}
`}</MDXTag></MDXTag>
<MDXTag name="h3" components={components}><MDXTag name="a" components={components} parentName="h3" props={{"href":"/platform/api#/reference/event"}}>{`Retrieve Event Summary`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The event summary is part of the basic event info, under `}<MDXTag name="inlineCode" components={components} parentName="p">{`event.description`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/{event_id} \
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="h4" components={components}>{`Response`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{}}>{`{
    "name": {
        "text": "Hello World Day",
        "html": "Hello World Day"
    },
    "description": {
        "text": "I'm the event summary",
        "html": "<B>I'm the event summary</B>"
    },
    "id": "1234556778",
    "url": "https://www.eventbrite.com/e/hello-world-tickets-1234556778",
    ...basic event info
}
`}</MDXTag></MDXTag>
           </MDXTag>
  }
}
  