import React, { PureComponent } from 'react';
import MetaTags from 'react-meta-tags';

import ApiaryEmbed from '../components/ApiaryEmbed';

export default class ApiReference extends PureComponent {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>API Reference | Eventbrite Platform</title>
                </MetaTags>
                <ApiaryEmbed />
            </div>
        );
    }
}
