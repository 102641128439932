
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}><MDXTag name="h1" components={components}>{`Changelog`}</MDXTag>
<MDXTag name="h2" components={components}>{`November, 2020`}</MDXTag>
<MDXTag name="h3" components={components}><MDXTag name="inlineCode" components={components} parentName="h3">{`event.description`}</MDXTag>{` deprecation`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`event.description`}</MDXTag>{` will be deprecated on March 1st, 2021.`}</MDXTag>
<MDXTag name="p" components={components}>{`Please see `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/event-description"}}>{`Event Description documentation`}</MDXTag>{`
to learn how to create an event description. In brief, to create an event
description use `}<MDXTag name="inlineCode" components={components} parentName="p">{`event.summary`}</MDXTag>{` to add a shortened event summary and Structured
Content to add rich content to your event description.`}</MDXTag>
<MDXTag name="p" components={components}>{`The content that historically was found in 'description.html' is now retrieved
from the endpoint:
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event-description/retrieve-full-html-description"}}>{`Retrieve Full HTML Description`}</MDXTag></MDXTag>
<MDXTag name="h2" components={components}>{`August, 2020`}</MDXTag>
<MDXTag name="h4" components={components}>{`Batch Endpoint - End of Life`}</MDXTag>
<MDXTag name="p" components={components}>{`Access to the following APIs will no longer be usable on November 31st, 2020:`}</MDXTag>
<MDXTag name="p" components={components}>{`GET /v3/batch/`}</MDXTag>
<MDXTag name="p" components={components}>{`Users can simply replace the batch request with the individual API calls
contained in the batch payload.`}</MDXTag>
<MDXTag name="h2" components={components}>{`February, 2020`}</MDXTag>
<MDXTag name="h4" components={components}>{`APIs Incompatible with Organizations & Permissions`}</MDXTag>
<MDXTag name="p" components={components}>{`Access to the following APIs will be no longer usable on June 1st, 2020, as they
are incompatible with Organizations & Permissions.`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":"left"}}>{`Legacy`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`New`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/checkout_settings/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/checkout_settings/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/organizers/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/organizers/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/webhooks/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/webhooks/list/list-webhook-by-organization-id"}}>{`/v3/organizations/{organization_id}/webhooks/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/assortment/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/assortment/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/contact_lists/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/contact_lists/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/contact_lists/{contact_list_id}/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/currencies/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/currencies/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/discounts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/discount/list/search-discounts-by-organization"}}>{`/v3/organizations/{organization_id}/discounts/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/event_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/event_groups/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/managed_events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/organizers/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/organizers/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/organizers/default/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/organizers/default/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/owned_events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/list/list-events-by-organization"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/owned_event_attendees/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/attendee/list/list-attendees-by-organization"}}>{`/v3/organizations/{organization_id}/attendees/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/owned_event_orders/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/order/organization-orders/list-orders-by-organization"}}>{`/v3/organizations/{organization_id}/orders/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/search_owned_event_orders/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/orders/search/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/ticket_classes/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/ticket_classes/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/ticket_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/ticket-group/list/list-ticket-groups-by-organization"}}>{`/v3/organizations/{organization_id}/ticket_groups/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/tracking_beacons/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET /v3/organizations/{organization_id}/tracking_beacons/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`GET /v3/users/{user_id}/venues/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`GET `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/venue/list/list-venues-by-organization"}}>{`/v3/organizations/{organization_id}/venues/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/assortment/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/assortment/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/contact_lists/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/contact_lists/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/contact_lists/{contact_list_id}/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/contact_lists/{contact_list_id}/contacts/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/users/{user_id}/event_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/event_groups/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/checkout_settings/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/checkout_settings/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/discounts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/discount/create/create-a-discount"}}>{`/v3/organizations/{organization_id}/discounts/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/events/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/create/create-an-event"}}>{`/v3/organizatons/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/organizers/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST /v3/organizations/{organization_id}/organizers/`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/series/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/event/create/create-an-event"}}>{`/v3/organizations/{organization_id}/events/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/ticket_groups/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/ticket-group/create/create-a-ticket-group"}}>{`/v3/organizations/{organization_id}/ticket_groups/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/venues/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/venue/create/create-a-venue"}}>{`/v3/organizations/{organization_id}/venues/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`POST /v3/webhooks/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`POST `}<MDXTag name="a" components={components} parentName="td" props={{"href":"/platform/api#/reference/webhooks/create/create-webhooks-by-organization-id"}}>{`/v3/organizations/{organization_id}/webhooks/`}</MDXTag></MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":"left"}}>{`DELETE /v3/users/{user_id}/discounts/`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`DELETE /v3/organizations/{organization_id}/discounts/`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote"><MDXTag name="strong" components={components} parentName="p">{`Note:`}</MDXTag>{` There are a few new APIs that are missing links because the
documentation is currently private.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`To learn more, refer to our guide on
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/organizations"}}>{`Organizations`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`January, 2020`}</MDXTag>
<MDXTag name="h4" components={components}>{`Inventory Tier ID Required on Ticket Classes for Tiered Events`}</MDXTag>
<MDXTag name="p" components={components}>{`After `}<MDXTag name="strong" components={components} parentName="p">{`May 7, 2020`}</MDXTag>{`, we will require you to provide an `}<MDXTag name="strong" components={components} parentName="p">{`inventory_tier_id`}</MDXTag>{`
as part of your request for any `}<MDXTag name="strong" components={components} parentName="p">{`ticket_classes`}</MDXTag>{` you are creating or updating
for a tiered event. This change will ensure that ticket classes will never
exceed the defined inventory capacity for their tier. Tiered events allow event
creators to assign tickets to separate inventory tiers.`}</MDXTag>
<MDXTag name="p" components={components}>{`If your integration is not updated by May 7th, responses for tickets for tiered
events will fail with a field error message "ticket_classes.0.inventory_tier_id"
“MISSING”.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Recommended action items`}</MDXTag>
<MDXTag name="p" components={components}>{`To give yourself time to implement the necessary changes before `}<MDXTag name="strong" components={components} parentName="p">{`May 7, 2020`}</MDXTag>{`,
please read our guide on
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/ticket-classes#tiered_events"}}>{`Creating Ticket Classes in Tiered Events`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`December, 2019`}</MDXTag>
<MDXTag name="h3" components={components}>{`Event Search API Shut Down`}</MDXTag>
<MDXTag name="p" components={components}>{`Access to the Eventbirte
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event-search"}}>{`Event Search API`}</MDXTag>{` will be shut down at
11:59 pm PT on Thursday, December 12, 2019.`}</MDXTag>
<MDXTag name="p" components={components}>{`We strongly encourage you to find and remove any code that makes requests to
this Event Search API from your applications in advance.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Why is this happening?`}</MDXTag>
<MDXTag name="p" components={components}>{`We’re removing the Event Search API to further improve the Eventbrite platform
and allow us to support more Creators and their events. Allowing public access
to this particular API was impacting our platform and the high level of service
we strive to provide to our creators and their attendees. We are able to provide
alternative access to retrieve your event data through our Event APIs (see
below).`}</MDXTag>
<MDXTag name="h4" components={components}>{`What is the replacement API?`}</MDXTag>
<MDXTag name="p" components={components}>{`To get Events via our API, please see:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"/platform/api#/reference/event/retrieve-an-event"}}>{`Retrieve an Event by ID`}</MDXTag>{`
— GET /v3/events/:event_id/`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"/platform/api#/reference/event/list/list-events-by-venue"}}>{`List Events by Venue`}</MDXTag>{`
— GET /v3/venues/:venue_id/events/`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="a" components={components} parentName="li" props={{"href":"/platform/api#/reference/event/update/list-events-by-organization"}}>{`List Events by Organization`}</MDXTag>{`
— GET /v3/organizations/:organization_id/events/`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`If you’re retrieving private events on behalf of another user, you can complete
the
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/introduction/authentication/2.-(for-app-partners)-authorize-your-users"}}>{`app authorization flow`}</MDXTag>{`.
If you’re interested in retrieving public events on behalf of many Eventbrite
creators, you can apply to our
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.eventbrite.com/l/distribution-partnerships/"}}>{`distribution partner program`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="h2" components={components}>{`October, 2018`}</MDXTag>
<MDXTag name="h3" components={components}>{`Event Description`}</MDXTag>
<MDXTag name="p" components={components}>{`If the event being retrieved was created using the new version of Create, then
you may notice that the event’s description field is now being used to hold the
event summary. To retrieve your event’s fully-rendered HTML description, you
will need to make an additional API call to retrieve the event's full HTML
description.`}</MDXTag>
<MDXTag name="p" components={components}>{`To learn more, checkout the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/api#/reference/event"}}>{`event endpoint`}</MDXTag></MDXTag>
           </MDXTag>
  }
}
  