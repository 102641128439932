import { useEffect, useState } from 'react';
import {
    addTranslationsAddedListener,
    removeTranslationsAddedListener,
} from './i18n';

export const useTranslationsLoaded = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const updateLoadedState = () => {
            setLoaded(true);
        };

        addTranslationsAddedListener(updateLoadedState);

        return () => {
            removeTranslationsAddedListener(updateLoadedState);
        };
    }, []);

    return loaded;
};
