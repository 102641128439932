import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import pick from 'lodash/pick';
import { HotKeys } from 'react-hotkeys';

import { componentPropType } from '@eventbrite/eds-utils';
import { InputField } from '@eb/eds-input-field';
import { TextList } from '@eb/eds-text-list';
import { Dropdown } from '@eb/eds-containers';
import { TEXT_ITEMS_PROP_TYPE } from '@eb/eds-text-list';

import {
    TABBABLE_INDEX,
    UNTABBABLE_INDEX,
    HIDE_CHILD,
    SHOW_CHILD,
    RESET_STATE,
    ACTIVATE_ITEM,
} from '@eb/eds-hot-keys';
import { ACTION_KEY_MAP } from './hotKeys';

import './autocompleteField.scss';

const DefaultDropdownContentContainer = ({ children }) => (
    <div
        className="eds-autocomplete-field__content"
        data-spec="autocompletefield-dropdown-content"
    >
        {children}
    </div>
);

const getStateWithEnteredValue = (stateObject, enteredValue) => ({
    ...stateObject,
    value: enteredValue,
    enteredValue,
});

const DEFAULT_INPUT_FIELD_PROPS = {
    style: 'basic',
    label: 'autocomplete',
};

const INITIAL_FOCUS_POSITION = -1;
const NAVIGATION_FOCUS_POSITION = 0;

const INITIAL_STATE = {
    /*
     * Boolean, true if searchSuggestions should be shown
     */
    hasFocus: false,
    /*
     * Should be 0 for tab sequence, unless searchSuggestions are shown
     */
    tabIndex: TABBABLE_INDEX,
    /*
     * Set at -1 when no searchSuggestions are shown, 0 when searchSuggestions are shown
     */
    focusPosition: INITIAL_FOCUS_POSITION,
    /*
     * Boolean, true if the input field should receive focus
     */
    shouldFocusInput: false,
    /*
     * String, value that the user enters, saved for toggling into input field
     */
    enteredValue: '',
};

// State when we want to navigate within searchSuggestions
const NAVIGATION_STATE = {
    hasFocus: true,
    tabIndex: UNTABBABLE_INDEX,
    focusPosition: NAVIGATION_FOCUS_POSITION,
    shouldFocusInput: false,
};

// State when we want to show searchSuggestions while typing into input field
const INPUT_STATE = {
    hasFocus: true,
    tabIndex: TABBABLE_INDEX,
    focusPosition: INITIAL_FOCUS_POSITION,
    shouldFocusInput: true,
};

// State when we want to hide searchSuggestions while focusing input field
const FOCUS_STATE = {
    hasFocus: false,
    tabIndex: TABBABLE_INDEX,
    focusPosition: INITIAL_FOCUS_POSITION,
    shouldFocusInput: true,
};

// State when we want to hide searchSuggestions and not focus the input field
const HIDE_SUGGEST_NO_FOCUS_STATE = {
    hasFocus: false,
    tabIndex: TABBABLE_INDEX,
    focusPosition: INITIAL_FOCUS_POSITION,
    shouldFocusInput: false,
};

class AutocompleteField extends React.PureComponent {
    static propTypes = {
        // all the props from an InputField
        ...InputField.propTypes,

        /**
         * The component root element ID (also used to generate child element IDs)
         */
        id: PropTypes.string.isRequired,

        /**
         * The suggestions to offer (may be an empty array)
         */
        suggestions: TEXT_ITEMS_PROP_TYPE.isRequired,

        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

        /**
         * The component class to wrap around the dropdown content.
         * This is enables a customized dropdown design
         * (i.e. - show powered by google places for location)
         *
         * If rendering DropdownContentContainer via portal
         * it is necessary to include `ignore-react-onclickoutside`
         * class on the rendered container. This will allow the
         * rendered container to behave as if it is within the
         * HOC and maintain expected behavior.
         */
        DropdownContentContainer: componentPropType,

        /**
         * Called when an option is selected
         * Passed the value of the selection option
         */
        onSelect: PropTypes.func,
        /**
         * Called for every change in the text input entry field
         * Passed the contents of the input
         */
        onChange: PropTypes.func,
        /**
         * Called when the text input entry is blurred
         * Passed the contents of the input
         */
        onBlur: PropTypes.func,
        /**
         * Called when the text input entry is focused
         * Passed the contents of the input
         */
        onFocus: PropTypes.func,
        /**
         * Called when the text input entry receives an enter key
         * Passed the contents of the input
         */
        onEnterKey: PropTypes.func,
        /**
         * Called when a key is pressed
         * Passed the contents of the input
         */
        onKeyDown: PropTypes.func,
        /**
         * Should this list try to scroll to the selected item?
         * This is a passthrough prop for TextList
         */
        autoscrollToSelected: PropTypes.bool,
        /**
         * A valid CSS selector of the parent scroll container
         * This is required if autoscrollToSelected prop is true
         * This is a passthrough prop for TextList
         */
        scrollContainerSelector: PropTypes.string,
        /**
         * Should we display the dropdown? Called during render when `hasFocus`.
         * This function will receive the current props and should return a bool.
         */
        shouldShowDropdown: PropTypes.func,

        /**
         * Should we focus the input field? Changes the state on `UNSAFE_componentWillReceiveProps`.
         */
        shouldFocusInput: PropTypes.bool,

        /**
         * Should we focus back on the event field after the user has selected an autocomplete
         * result
         */
        shouldFocusInputOnSelect: PropTypes.bool,

        /**
         * Whether or not the search is limited to items in suggestions
         */
        isLimitedSearch: PropTypes.bool,
        /**
         * Flag for the autocomplete to use InputField @deprecated
         */
        isV2: PropTypes.bool,
        /**
         * Boolean to determine if on tentativeItemSelect the value should be prefilled
         * into the input and focus dropped
         */
        preventInputValuePrefill: PropTypes.bool,
        /**
         * Boolean to determine if conflictive aria attributes should not be rendered.
         * Often helpful to increase audit results on pages but allowing to easily switch
         * them on again if a component doesn't behaves as expected.
         */
        shouldRemoveConflictiveAria: PropTypes.bool,
        /**
         * Boolean that determines if clicking outside the dropdown
         * should automatically close or not. Default is false.
         */
        shouldIgnoreOutsideClick: PropTypes.bool,
        /**
         * Boolean that determines if clicking inside the dropdown
         * should automatically close or not. Default is false.
         */
        shouldAllowMultiSelect: PropTypes.bool,
        /**
         * Callback which receives a boolean representing if
         * the dropdown is currently being rendered.
         */
        isRenderingSuggestions: PropTypes.func,
        /**
         * Boolean that determines if the input should display the user entered search value. Default is false.
         * Default behavior is that tentitively selecting a dropdown item displays it's content in the input field
         * Enabling this option will maintain the search value in the input field at all times.
         */
        shouldDisplaySearchValue: PropTypes.bool,
    };

    static defaultProps = {
        DropdownContentContainer: DefaultDropdownContentContainer,
        isLimitedSearch: false,
        preventInputValuePrefill: false,
        shouldRemoveConflictiveAria: false,
        shouldFocusInputOnSelect: true,
        shouldAllowMultiSelect: false,
        shouldDisplaySearchValue: false,
    };

    constructor(props) {
        super(props);
        const value = props.value || props.defaultValue;

        this.state = {
            ...INITIAL_STATE,
            value,
            enteredValue: value || '',
            searchValue: value || '',
        };
    }

    UNSAFE_componentWillReceiveProps({ value, shouldFocusInput }) {
        // When values are passed in via mapDispatchToProps (Redux), setting state here ensures that
        // fields populate as expected in core
        if (value !== undefined) {
            this.setState({
                value,
                shouldFocusInput,
                enteredValue: value,
            });
        }
    }

    hotKeyHandlers = {
        [RESET_STATE]: () =>
            this.setState(({ enteredValue }) =>
                getStateWithEnteredValue(INITIAL_STATE, enteredValue),
            ),
        [SHOW_CHILD]: (e) => {
            e.preventDefault();
            e.stopPropagation();

            const stateToSet = !isEmpty(this.props.suggestions)
                ? NAVIGATION_STATE
                : INITIAL_STATE;

            this.setState({
                ...stateToSet,
                keyboardAction: SHOW_CHILD,
            });
        },
        [ACTIVATE_ITEM]: (e) => {
            const { isLimitedSearch, suggestions } = this.props;

            this._handleEnterKey(this.state.value);

            // e.preventDefault should not be called for non-Limited searches to allow for
            // correct submission when AutocompleteField is wrapped by form (i.e. Event Search)
            if (isLimitedSearch) {
                e.preventDefault();

                if (suggestions.length && suggestions[0].value) {
                    this._handleItemSelect(suggestions[0].value);
                }

                return FOCUS_STATE;
            }

            this._handleItemSelect(this.state.value);

            return {};
        },
    };

    _resetState() {
        this.setState(({ enteredValue }) =>
            getStateWithEnteredValue(INITIAL_STATE, enteredValue),
        );
    }

    _handleInputChange(value) {
        const { onChange } = this.props;

        this.setState({
            ...INPUT_STATE,
            value,
            enteredValue: value,
            searchValue: value,
        });

        if (onChange) {
            onChange(value);
        }
    }

    _handleItemTentativeSelect(value, content) {
        const { preventInputValuePrefill } = this.props;

        let inputDisplayText = value;

        if (preventInputValuePrefill) {
            return;
        }

        // For display purposes, content is prioritized rather
        // than referencing the event hashes or url (i.e. values for event/location search)
        if (typeof content === 'string') {
            inputDisplayText = content;
        }

        this.setState({
            value: inputDisplayText,
            shouldFocusInput: false,
            focusPosition: NAVIGATION_FOCUS_POSITION,
        });
    }

    _handleInputFocus(value) {
        const { onFocus } = this.props;

        if (this.state.shouldFocusInput) {
            this.setState(FOCUS_STATE);
        } else {
            this.setState(INPUT_STATE);
        }

        if (onFocus) {
            onFocus(value);
        }
    }

    _handleInputFocusToggle() {
        this.setState(({ enteredValue }) =>
            getStateWithEnteredValue(INPUT_STATE, enteredValue),
        );
    }

    _handleInputBlur(value) {
        const { onBlur } = this.props;
        const { focusPosition } = this.state;

        const isSomeSuggestionFocused =
            focusPosition === NAVIGATION_FOCUS_POSITION;

        this.setState({ shouldFocusInput: false });

        if (onBlur && !isSomeSuggestionFocused) {
            onBlur(value);
        }
    }

    _handleItemSelect(itemValue) {
        const {
            onSelect,
            shouldFocusInputOnSelect,
            suggestions,
            shouldAllowMultiSelect,
        } = this.props;
        let baseState = HIDE_SUGGEST_NO_FOCUS_STATE;

        if (shouldAllowMultiSelect) {
            // This will allow for clicks within the suggestions dropdown to not cause the dropdown to close
            // Permits multiple clicks within the dropdown when we need to support multiple selections made
            baseState = NAVIGATION_STATE;
        } else if (shouldFocusInputOnSelect) {
            baseState = FOCUS_STATE;
        }

        // For display purposes, content is prioritized rather
        // than referencing the event hashes or url (i.e. values for event/location search)
        const { content } =
            suggestions.find((s) => {
                if (s.content && typeof s.content === 'string')
                    return s.value === itemValue;
                return false;
            }) || {};

        this.setState({
            ...baseState,
            enteredValue: itemValue,
            value: typeof content !== 'undefined' ? content : itemValue,
        });

        if (onSelect) {
            onSelect(itemValue);
        }
    }

    _handleEnterKey(value) {
        const { onEnterKey } = this.props;

        if (onEnterKey) {
            onEnterKey(value);
        }
    }

    _handleClickOutside(e) {
        // If they click the input, we don't want to dismiss the dropdown
        // even though it's technically outside of the dropdown

        if (!e.target.id || e.target.id !== this.props.id) {
            if (!this.props.shouldIgnoreOutsideClick) {
                this._resetState();
            }
        }
    }

    _shouldShowSuggestions() {
        const { hasFocus } = this.state;
        const {
            suggestions,
            shouldShowDropdown,
            isRenderingSuggestions,
        } = this.props;
        let renderSuggestions = false;

        if (!hasFocus) {
            renderSuggestions = false;
        } else if (isFunction(shouldShowDropdown)) {
            renderSuggestions = shouldShowDropdown(this.props);
        } else {
            renderSuggestions = !!suggestions.length;
        }

        if (isRenderingSuggestions) {
            isRenderingSuggestions(renderSuggestions);
        }

        return renderSuggestions;
    }

    _handleInputReset() {
        const { enteredValue, hasFocus } = this.state;

        if (hasFocus) {
            this.setState({ value: enteredValue });
        }
    }

    _handleInputClick() {
        this.setState(INPUT_STATE);
    }

    _renderTextList(
        suggestions,
        listboxId,
        currentValue,
        focusPosition,
        keyboardAction,
        isLimitedSearch,
        autoscrollToSelected,
        scrollContainerSelector,
    ) {
        return (
            <TextList
                id={listboxId}
                items={suggestions}
                selectedItem={currentValue}
                defaultFocusPosition={focusPosition}
                onItemSelect={this._handleItemSelect.bind(this)}
                aria-expanded={true}
                autoscrollToSelected={autoscrollToSelected}
                scrollContainerSelector={scrollContainerSelector}
                preventFocusWrap={true}
                onParentFocus={this._handleInputFocusToggle.bind(this)}
                parentKeyboardAction={keyboardAction}
                shouldFocusFirstItem={isLimitedSearch}
                onItemTentativeSelect={this._handleItemTentativeSelect.bind(
                    this,
                )}
                staticFocusOnTentativeSelect={
                    this.props.preventInputValuePrefill
                }
            />
        );
    }

    render() {
        const {
            id,
            suggestions,
            DropdownContentContainer,
            autoscrollToSelected,
            scrollContainerSelector,
            children,
            isLimitedSearch,
            isV2,
            shouldRemoveConflictiveAria,
            shouldDisplaySearchValue,
        } = this.props;

        const {
            hasFocus,
            tabIndex,
            focusPosition,
            keyboardAction,
            shouldFocusInput,
            value,
            searchValue,
        } = this.state;
        const listboxId = `${id}-listbox`;
        const onClickOutside = this._handleClickOutside.bind(this);
        let dropdown;

        if (this._shouldShowSuggestions()) {
            dropdown = (
                <Dropdown onClickOutside={onClickOutside}>
                    <DropdownContentContainer
                        suggestions={suggestions}
                        value={value}
                        hasFocus={hasFocus}
                    >
                        {this._renderTextList(
                            suggestions,
                            listboxId,
                            value,
                            focusPosition,
                            keyboardAction,
                            isLimitedSearch,
                            autoscrollToSelected,
                            scrollContainerSelector,
                        )}
                    </DropdownContentContainer>
                </Dropdown>
            );
        }

        const InputComponent = InputField;
        const inputProps = {
            ...DEFAULT_INPUT_FIELD_PROPS,
            ...pick(this.props, ...Object.keys(InputField.propTypes)),
        };

        /*
         * Chrome's `autofill` function is conflicting with autocompelte.
         * Documentation points that setting something semantic as
         * `autocomplete="user-selection-from-list"` or
         * `autocomplete="none"` or
         * `autocomplete="nope"` should work
         * but in the end `autocomplete="off"` is the one that seems to be working
         *
         * https://caniuse.com/#search=autocomplete
         * https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
         */

        /*
         * The InputComponent always received an `aria-owns={listboxId}`.
         * Aria-owns work is to bind two elements that can not be related directly by hierarchy.
         * https://www.w3.org/WAI/PF/aria-1.1/states_and_properties#aria-owns
         * Currently this is generating issues with our audit tools and seems to be missing
         * from current implementations of the standard.
         * In order to test this we should be able to toggle this conflictive aria attribute.
         */
        let ariaAttrs = {
            'aria-expanded': hasFocus,
            'aria-autocomplete': 'list',
        };

        if (!shouldRemoveConflictiveAria) {
            ariaAttrs = {
                ...ariaAttrs,
                'aria-owns': listboxId,
            };
        }

        return (
            <HotKeys keyMap={ACTION_KEY_MAP} handlers={this.hotKeyHandlers}>
                <div
                    className="eds-autocomplete-field"
                    data-testid="autocomplete-field-wrapper"
                >
                    <div className="eds-autocomplete-field__dropdown-holder">
                        <InputComponent
                            {...inputProps}
                            {...ariaAttrs}
                            value={
                                shouldDisplaySearchValue ? searchValue : value
                            }
                            id={id}
                            role="combobox"
                            autoComplete="off"
                            onClick={this._handleInputClick.bind(this)}
                            onChange={this._handleInputChange.bind(this)}
                            onFocus={this._handleInputFocus.bind(this)}
                            onBlur={this._handleInputBlur.bind(this)}
                            tabIndex={tabIndex}
                            shouldFocus={shouldFocusInput}
                            onMouseOver={this._handleInputReset.bind(this)}
                        />
                        {dropdown}
                    </div>
                </div>
            </HotKeys>
        );
    }
}

export default AutocompleteField;
