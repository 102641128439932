import { fetchFromApi } from './base';

export const fetchLoggedInUser = () =>
    fetchFromApi('/users/me/', {
        method: 'GET',
    })
        .then((user) => {
            const userInfo = user;

            if (user.image_id !== null) {
                return fetchFromApi(`/media/${user.image_id}/`, {
                    method: 'GET',
                }).then((media) => {
                    userInfo.imageUrl = media.url;
                    return userInfo;
                });
            }

            return userInfo;
        })
        .catch((error) => error);
