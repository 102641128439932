import {
    MOVE_TO_NEXT,
    MOVE_TO_PREV,
    MOVE_TO_FIRST,
    MOVE_TO_LAST,
    ACTIVATE_ITEM,
} from '@eb/eds-hot-keys';

// See https://craig.is/killing/mice for available patterns of declaring keyboard sequences.
export const ACTION_KEY_MAP = {
    // action(str) : keyCombination
    [MOVE_TO_NEXT]: 'down',
    [MOVE_TO_PREV]: 'up',
    [MOVE_TO_FIRST]: 'home',
    [MOVE_TO_LAST]: 'end',
    [ACTIVATE_ITEM]: 'enter',
};
