import React from 'react';
import {
    IndexRedirect,
    IndexRoute,
    Redirect,
    Route,
    browserHistory,
    createMemoryHistory,
} from 'react-router';

import ConnectedBasePage from './containers/ConnectedBasePage';
import ConnectedHomePage from './containers/ConnectedHomePage';
import ConnectedDocsPage from './containers/ConnectedDocsPage';
import ConnectedApiReferencePage from './containers/ConnectedApiReferencePage';
import ConnectedApiKeysPage from './containers/ConnectedApiKeysPage';

export const getHistory = () => {
    const hasBrowserHistory =
        typeof browserHistory !== 'undefined' &&
        process.env.NODE_ENV !== 'test';

    return hasBrowserHistory ? browserHistory : createMemoryHistory();
};

export const getRoutes = () => {
    const path = '/platform';
    const playground = '/admin/spa/playground/xdev/:version';

    return (
        <Route>
            <Route path={path} component={ConnectedBasePage}>
                <Route path="api" component={ConnectedApiReferencePage} />
                <Route path="api-keys" component={ConnectedApiKeysPage} />
                <Route path="docs" component={ConnectedDocsPage}>
                    <IndexRedirect to="introduction" />
                    <Route path=":document" component={ConnectedDocsPage} />
                </Route>
                <IndexRoute component={ConnectedHomePage} />
                <Redirect path="**" to="/404.html" />
            </Route>
            <Route path={playground} component={ConnectedBasePage}>
                <Route path="api" component={ConnectedApiReferencePage} />
                <Route path="api-keys" component={ConnectedApiKeysPage} />
                <Route path="docs" component={ConnectedDocsPage}>
                    <Route path=":document" component={ConnectedDocsPage} />
                </Route>
                <IndexRoute component={ConnectedHomePage} />
                <Redirect path="**" to="/404.html" />
            </Route>
        </Route>
    );
};
