import React from 'react';
import PropTypes from 'prop-types';
import { translationPropType } from '@eventbrite/i18n';

import classNames from 'classnames';
import textEllipsis from 'text-ellipsis';

import { Button } from '@eventbrite/eds-button';
import { ListItem } from '@eb/eds-list-item';

import { TYPES as BUTTON_TYPES } from '@eventbrite/eds-button';
import {
    ICON_TYPE_PROP_TYPE,
    ICON_SIZE_PROP_TYPE,
    COLOR_PROP_TYPE,
} from '@eb/eds-icon';
import {
    PAYMENT_TYPE_PROP_TYPE,
    PAYMENT_SIZE_PROP_TYPE,
} from '@eb/eds-payment-icon';
import { TEXT_ITEMS_CHARACTER_LENGTH_MAP } from './constants';
import { VERTICAL_ALIGNMENTS_PROP_TYPE } from '@eb/eds-list-item';

import './textListItem.scss';

const Content = ({
    content,
    level = 'primary',
    isDisabled,
    ellipsis = true,
}) => {
    if (!content) {
        return null;
    }

    const contentClassName = classNames(
        `eds-text-list-item__content-${level}`,
        {
            'eds-text-list-item__content--disabled': isDisabled,
        },
    );

    let text = content;

    if (ellipsis) {
        text = textEllipsis(content, TEXT_ITEMS_CHARACTER_LENGTH_MAP[level]);
    }

    return <div className={contentClassName}>{text}</div>;
};

export default class TextListItem extends React.PureComponent {
    static propTypes = {
        /**
         * The type of button subcomponent to be used
         */
        buttonType: PropTypes.oneOf(BUTTON_TYPES),
        /**
         * Any child nodes to be displayed that should not have the content styling
         */
        children: PropTypes.node,
        /**
         * The primary content to be displayed
         */
        content: PropTypes.node,
        /**
         * Generic content for the left side
         */
        extraContent: PropTypes.node,
        /**
         * Icon to include in the list item that when specified will wrap the `children` to include the icon
         */
        iconType: PropTypes.oneOfType([
            ICON_TYPE_PROP_TYPE,
            PAYMENT_TYPE_PROP_TYPE,
        ]),
        /**
         * Icons to include in the list item that when specified will wrap the `children` to include the icons
         */
        iconTypes: PropTypes.arrayOf(
            PropTypes.oneOfType([ICON_TYPE_PROP_TYPE, PAYMENT_TYPE_PROP_TYPE]),
        ),
        /**
         * Size of included icon
         */
        iconSize: PropTypes.oneOfType([
            ICON_SIZE_PROP_TYPE,
            PAYMENT_SIZE_PROP_TYPE,
        ]),
        /**
         * Color of included icon
         */
        iconColor: COLOR_PROP_TYPE,
        /**
         * URL of image to be displayed with list item content
         */
        imageUrl: PropTypes.string,
        /**
         * Alt text for image to be displayed with list item content
         */
        imageAlt: translationPropType,
        /**
         * Whether or not the item is disabled
         */
        isDisabled: PropTypes.bool,
        /**
         * Optional square shape image - note you must pass in an imageUrl
         * for an image that is either 1:1 or wider than it is tall.
         */
        isSquareImage: PropTypes.bool,
        /**
         * Whether or not the list item is selected
         */
        isSelected: PropTypes.bool,
        /**
         * Whether or not the text list item is tenatively selected.
         * This comes into play on hover or via arrow key press before an
         * actual selection is made
         */
        isTentativelySelected: PropTypes.bool,
        /**
         * Whether or not the text list item should be focusable
         */
        isFocusable: PropTypes.bool,
        /**
         * Callback function invoked when the text list item is selected
         */
        onSelect: PropTypes.func,
        /**
         * Calback function invoked when the text list item content, by default button, receives focus
         */
        onItemContentFocus: PropTypes.func,
        /**
         * Url path for the button (if it's a link)
         */
        path: PropTypes.string,
        /**
         * Bypasses the default Button container and uses whatever your container your render function returns in its place! (neat!)
         * render is passed the styles that are used on other button containers so your container can match. It also provides
         * children so you can render children into your render container.
         *
         * ({children, onSelect, containerClassName}) => {...render children here}
         */
        render: PropTypes.func,
        /**
         * The secondary content to be displayed
         */
        secondaryContent: PropTypes.node,
        /**
         * The tertiary content to be displayed
         */
        tertiaryContent: PropTypes.node,
        /**
         * Vertical alignment of children items
         */
        verticalAlignment: VERTICAL_ALIGNMENTS_PROP_TYPE,
        /**
         * Whether or not the item should use a progress indicator while the image is loading
         */
        useProgressIndicator: PropTypes.bool,
    };

    static defaultProps = {
        isSelected: false,
        isDisabled: false,
        isTentativelySelected: false,
        isFocusable: true,
        useProgressIndicator: false,
    };

    componentDidUpdate() {
        if (this.props.isFocusable && this.props.isTentativelySelected) {
            this.itemContent.focus();
        }
    }

    render() {
        const {
            buttonType,
            children,
            content,
            extraContent,
            iconType,
            iconTypes,
            iconSize,
            iconColor,
            imageAlt,
            imageUrl,
            isDisabled,
            isSquareImage,
            isSelected,
            isTentativelySelected,
            isFocusable,
            onSelect,
            onItemContentFocus,
            path,
            render,
            secondaryContent,
            tertiaryContent,
            verticalAlignment,
            useProgressIndicator,
        } = this.props;
        const containerClassName = classNames('eds-text-list-item', {
            'eds-text-list-item--selected': isSelected,
            'eds-text-list-item--disabled': isDisabled,
            'eds-text-list-item--tentatively-selected': isTentativelySelected,
        });

        let renderContainer = render;

        // if no render prop is passed, set default container to be Button we all know and love
        if (!renderContainer) {
            const href = isDisabled ? null : path;

            const _getElement = (element) => {
                this.itemContent = element;
            };

            renderContainer = (props) => (
                <Button
                    disabled={isDisabled}
                    href={href}
                    style="none"
                    onClick={props.onSelect}
                    onFocus={props.onItemContentFocus}
                    type={buttonType}
                    data-spec="text-list-item-button"
                    __containerClassName={props.containerClassName}
                    ref={_getElement}
                    size="block"
                >
                    {props.children}
                </Button>
            );
        }

        const renderChildren = (
            <ListItem
                extraContent={extraContent}
                iconType={iconType}
                iconTypes={iconTypes}
                iconSize={iconSize}
                iconColor={iconColor}
                imageUrl={imageUrl}
                imageAlt={imageAlt}
                isDisabled={isDisabled}
                isSelected={isSelected}
                isTentativelySelected={isTentativelySelected}
                isFocusable={isFocusable}
                isSquareImage={isSquareImage}
                verticalAlignment={verticalAlignment}
                useProgressIndicator={useProgressIndicator}
            >
                <Content
                    content={content}
                    level="primary"
                    ellipsis={false}
                    isDisabled={isDisabled}
                />
                <Content
                    content={secondaryContent}
                    level="secondary"
                    isDisabled={isDisabled}
                />
                <Content
                    content={tertiaryContent}
                    level="tertiary"
                    isDisabled={isDisabled}
                />
                {children}
            </ListItem>
        );

        return (
            <div className={containerClassName} data-spec="text-list-item">
                {renderContainer({
                    children: renderChildren,
                    containerClassName: 'eds-text-list-item__button',
                    onSelect,
                    onItemContentFocus,
                })}
            </div>
        );
    }
}
