import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { InputField } from '@eb/eds-input-field';
import {
    BORDER_TYPES_PROP_TYPES,
    BORDER_SIMPLE,
    STYLES_PROP_TYPES,
} from '@eb/eds-input-field';
import { CopyButtonWithTooltip } from '@eb/eds-copy-button';

import { CopyChunky } from '@eb/eds-iconography';

export default class CopyField extends PureComponent {
    static propTypes = {
        /**
         * The id of the input field
         */
        inputFieldId: PropTypes.string.isRequired,
        /**
         * The label to display on the input field
         */
        label: PropTypes.node.isRequired,
        /**
         * The text to display on the input field
         */
        value: PropTypes.string.isRequired,
        /**
         * Style of the input field
         */
        inputFieldStyle: STYLES_PROP_TYPES,
        /**
         * Style of the input field
         */
        inputFieldBorderType: BORDER_TYPES_PROP_TYPES,
        /**
         * Callback function called if the user clicks on the copy button
         */
        onCopyClick: PropTypes.func,
        /**
         * The direction that the tooltip should appear in relative to the icon
         */
        tooltipArrowLocation: PropTypes.string,
    };

    static defaultProps = {
        inputFieldBorderType: BORDER_SIMPLE,
    };

    render() {
        const {
            inputFieldId,
            inputFieldStyle,
            inputFieldBorderType,
            label,
            onCopyClick,
            tooltipArrowLocation,
            value,
        } = this.props;
        const suffix = (
            <CopyButtonWithTooltip
                iconType={<CopyChunky />}
                onCopyClick={onCopyClick}
                value={value}
                tooltipId={`${inputFieldId}-tooltip`}
                tooltipArrowLocation={tooltipArrowLocation}
            />
        );

        return (
            <InputField
                id={inputFieldId}
                label={label}
                readOnly={true}
                style={inputFieldStyle}
                borderType={inputFieldBorderType}
                value={value}
                suffix={suffix}
                hasMinAsideSpacing={true}
            />
        );
    }
}
