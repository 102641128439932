import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as constants from './constants';

import './layout.scss';

const Header = ({ children }) => {
    let headerMarkup = null;

    if (children) {
        headerMarkup = (
            <header
                className="eds-layout__header"
                data-spec="eds-layout__header"
            >
                {children}
            </header>
        );
    }

    return headerMarkup;
};

const Body = ({ children }) => (
    <div className="eds-layout__body" data-spec="eds-layout__body">
        {children}
    </div>
);

export default class Layout extends PureComponent {
    static propTypes = {
        /**
         * Main page contents of the layout
         */
        children: PropTypes.node.isRequired,

        /**
         * (optional) placeholder of content that will exist above the `children`
         */
        header: PropTypes.node,

        /**
         * (optional) adds a maximum width constrain to the main page contents
         * supports 'large' (1272px)
         */
        maxWidth: PropTypes.oneOf(constants.MAX_WIDTHS),

        /**
         * (optional) The amount the `children` should overlap the `header`
         * supports 'small', 'medium' or 'large' peak sizes
         */
        peakSize: PropTypes.oneOf(constants.PEAK_SIZES),

        /**
         * (optional) Turns on horizontal gutters
         * Small Narrow (360px) - 16px
         * Small (480px) - 16px
         * Small Wide (660px) - 16px
         * Medium Narrow (792px) - 24px
         * Medium (960px) - 24px
         * Medium Wide (1080px) - 24px
         * Large Narrow (1152px) - 48px
         * Large (1272px) - 48px
         * Large Wide (1608px) - 72px
         */
        hasHorizontalGutters: PropTypes.bool,
    };

    static defaultProps = {
        hasHorizontalGutters: false,
    };

    render() {
        const {
            children,
            header,
            peakSize,
            maxWidth,
            hasHorizontalGutters,
        } = this.props;

        const className = classNames('eds-layout', {
            [`eds-layout--has-${peakSize}-peak`]: peakSize,
            [`eds-layout--has-${maxWidth}-max-width`]: maxWidth,
            'eds-layout--has-horizontal-gutters': hasHorizontalGutters,
        });

        return (
            <section className={className} data-spec="eds-layout">
                <Header>{header}</Header>
                <Body>{children}</Body>
            </section>
        );
    }
}
