/* eslint-disable */
import { HAS_WINDOW } from '@eb/feature-detection';

(function (w, d) {
    if (!HAS_WINDOW) {
        w = {};
    }

    w.EB = w.EB || {};

    EB.renderFonts = function (fs, fam) {
        // set a default since 'neueplak.js' doesn't pass the fam param
        if (!fam) {
            fam = w.EBFONT_PROPERTIES.family;
        }
        // called at the end of <font>.js
        var h = '',
            s = d.createElement('style');
        for (var i = 0, f; (f = fs[i]); i++) {
            h +=
                "@font-face{font-family:'" +
                fam +
                "';font-weight:" +
                f.weight +
                ';font-style:' +
                f.style +
                ';' +
                'src:url(data:application/font-woff;base64,' +
                f.base64 +
                ") format('woff');}";
        }

        if (s.styleSheet && !s.sheet) {
            // fix for IE
            s.styleSheet.cssText = h;
        } else {
            s.appendChild(d.createTextNode(h));
        }
        d.getElementsByTagName('head')[0].appendChild(s);

        d.documentElement.className += ' font-has-loaded';
    };

    EB.readWriteFontFromLocalStorage = function (f) {
        // keep the cache clean for the user so that they are only storing the latest version
        for (var i = 0; i < f.oldVersions.length; i++) {
            localStorage.removeItem(f.oldVersions[i]);
        }

        var fs = JSON.parse(localStorage.getItem(f.version));

        if (fs) {
            EB.renderFonts(fs, f.family);
        } else {
            var s = d.createElement('script');
            // For NeuePlak use src since it's externally hosted
            // For Condensed let's create the script since it's local
            if (f.src) {
                EB.shouldRenderFonts = true;
                s.src = f.src;
            } else if (f.script) {
                EB.shouldRenderSecondaryFonts = true;
                s.innerHTML = f.script;
            }
            //s.async = true; //force async loading in opera and old firefox
            d.getElementsByTagName('head')[0].appendChild(s);
            setTimeout(function () {
                // if fonts have not loaded after 3 seconds, we stash in local storage but don't render on that initial page load
                if (f.src) {
                    EB.shouldRenderFonts = false;
                } else if (f.script) {
                    EB.shouldRenderSecondaryFonts = false;
                }
            }, 30000);
        }
    };

    if (w.localStorage) {
        // we only serve fonts to browsers that support local storage
        // Check to see if they both have the feature and can set / remove items
        try {
            localStorage.setItem('localStorage', 1);
            localStorage.removeItem('localStorage');

            EB.readWriteFontFromLocalStorage(w.EBFONT_PROPERTIES);
            EB.readWriteFontFromLocalStorage(w.EBFONTCOND_PROPERTIES); // Neue Plak Condensed
        } catch (e) {
            // Browser has localStorage but has it disabled
            // So we write over the prototype for setItem rendering it safe for future calls
            Storage.prototype._setItem = Storage.prototype.setItem;
            Storage.prototype.setItem = function () {};
        }
    }
})(window, document);
