import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
    setupNotificationAutoDelay,
    clearNotificationTimeouts,
} from './helper';
import { ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE } from './constants';

// addFocusDrawerControls is a HOC used to wrap a page component
// it adds `showFocusDrawer` & `closeFocusDrawer` props
// into the scope of the page component which is used in tandem with
// `withFocusDrawerControls` to pass focus drawer options from sub components
const addFocusDrawerControls = (PageComponent) =>
    class AddFocusDrawerControlsWrapper extends PureComponent {
        static childContextTypes = {
            showFocusDrawer: PropTypes.func,
            closeFocusDrawer: PropTypes.func,
            destroyFocusDrawer: PropTypes.func,
            showFocusDrawerBottomBar: PropTypes.func,
            closeFocusDrawerBottomBar: PropTypes.func,
            addFocusDrawerNotification: PropTypes.func,
            hideFocusDrawerNotification: PropTypes.func,
        };

        state = {
            focusDrawerOptions: undefined,
        };

        // Add the showFocusDrawer & closeFocusDrawer functions in to the context tree
        getChildContext() {
            return {
                showFocusDrawer: this._showFocusDrawer.bind(this),
                closeFocusDrawer: this._closeFocusDrawer.bind(this),
                destroyFocusDrawer: this._destroyFocusDrawer.bind(this),
                showFocusDrawerBottomBar: this._showFocusDrawerBottomBar.bind(
                    this,
                ),
                closeFocusDrawerBottomBar: this._closeFocusDrawerBottomBar.bind(
                    this,
                ),
                addFocusDrawerNotification: this._addFocusDrawerNotification.bind(
                    this,
                ),
                hideFocusDrawerNotification: this._hideFocusDrawerNotification.bind(
                    this,
                ),
            };
        }

        componentWillUnmount() {
            // Make sure Notification timers are cleared to prevent memory leaks
            clearNotificationTimeouts(ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE);
        }

        _showFocusDrawer(options = {}) {
            // managing the state of the focus drawer w/in this HOC so that
            // components further down the tree don't have to
            this.setState({
                focusDrawerOptions: {
                    ...options,
                    isShown: true,

                    // Need to always pass in `onClose` so that if the focus drawer
                    // button is closed, we can still visibly close it by updating
                    // the state. If an `onClose` was passed in, it'll get called
                    // as well
                    onClose: this._closeFocusDrawer.bind(this, options.onClose),
                },
            });
        }

        _destroyFocusDrawer(onDestroy) {
            // NOTE: _closeFocusDrawer only set `isShown` to false, meaning it only hides it.
            // But for the cases where an experience uses a mix of declarative `focusDrawerOptions`
            // prop + HOC generated `focusDrawerOptions` state, state has higher precedence than prop
            // and we need to destroy state values completely to ensure prop values are in effect.
            this.setState({
                focusDrawerOptions: undefined,
            });

            if (onDestroy) {
                onDestroy();
            }
        }

        _closeFocusDrawer(onClose) {
            // NOTE: We could also set `focusDrawerOptions` to undefined, but since the
            // drawer is hidden, we don't *have* to clear out the content. that
            // would result in the markup also being cleared out. If the next time
            // the focus drawer is shown and the values end up being the same,
            // we could save some unnecessary DOM rewrites by *only* changing the
            // shown property

            // NOTE: using the callback form for `setState` since the new state
            // I want to set depends on the previous state. Because `setState`
            // is async this ensures that we're using the right version
            this.setState(
                ({ focusDrawerOptions: prevFocusDrawerOptions = {} }) => ({
                    focusDrawerOptions: {
                        ...prevFocusDrawerOptions,
                        isShown: false,
                    },
                }),
            );

            if (onClose) {
                onClose();
            }
        }

        _showFocusDrawerBottomBar(bottomBarOptions = {}) {
            // managing the state of the focus drawer bottom bar w/in this HOC so that
            // components further down the tree don't have to
            this.setState(
                ({ focusDrawerOptions: prevFocusDrawerOptions = {} }) => ({
                    focusDrawerOptions: {
                        ...prevFocusDrawerOptions,
                        bottomBarOptions: {
                            ...bottomBarOptions,
                            isShown: true,
                        },
                    },
                }),
            );
        }

        _closeFocusDrawerBottomBar() {
            // NOTE: We could also set `bottomBarOptions` to undefined, but since the
            // bottom bar is hidden, we don't *have* to clear out the content. that
            // would result in the markup also being cleared out. If the next time
            // the bottom bar is shown and the values end up being the same,
            // we could save some unnecessary DOM rewrites by *only* changing the
            // shown property

            // NOTE: using the callback form for `setState` since the new state
            // I want to set depends on the previous state. Because `setState`
            // is async this ensures that we're using the right version
            this.setState(
                ({ focusDrawerOptions: prevFocusDrawerOptions = {} }) => ({
                    focusDrawerOptions: {
                        ...prevFocusDrawerOptions,
                        bottomBarOptions: {
                            ...prevFocusDrawerOptions.bottomBarOptions,
                            isShown: false,
                        },
                    },
                }),
            );
        }

        _addFocusDrawerNotification(options = {}) {
            // Need to clear any lingering timeouts
            clearNotificationTimeouts(ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE);

            // managing the state of the notificationBar w/in this HOC so that
            // components further down the tree don't have to
            const {
                shouldPersist,
                callAction,
                onClose,
                ...notificationOptions
            } = options;

            this.setState(
                ({ focusDrawerOptions: prevFocusDrawerOptions = {} }) => ({
                    focusDrawerOptions: {
                        ...prevFocusDrawerOptions,
                        notificationOptions: {
                            callAction,
                            hasCloseButton: true,
                            isClosing: false,
                            ...notificationOptions,

                            // Need to always pass in `onClose` so that if the notificationBar
                            // button is pressed this HOC can set its internal state.
                            // If an `onClose` was passed in, it'll get called.
                            onClose: this._hideFocusDrawerNotification.bind(
                                this,
                                onClose,
                            ),
                        },
                    },
                }),
            );

            // Self remove non-callAction notifications. Notifications
            // which have CTAs should not be auto removed
            if (!callAction && !shouldPersist) {
                // Fire the notification removal sequence
                setupNotificationAutoDelay(
                    this._animateClose,
                    this._hideFocusDrawerNotification.bind(this, onClose),
                    ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE,
                );
            }
        }

        _animateClose = (next) => {
            this.setState(
                ({ focusDrawerOptions: prevFocusDrawerOptions = {} }) => ({
                    focusDrawerOptions: {
                        ...prevFocusDrawerOptions,
                        notificationOptions: {
                            ...prevFocusDrawerOptions.notificationOptions,
                            // isClosing signals Structure to apply a css transition effect to the notification
                            isClosing: true,
                        },
                    },
                }),
                next,
            );
        };

        _hideFocusDrawerNotification(onClose) {
            // Need to clear any lingering timeouts
            clearNotificationTimeouts(ADD_FOCUS_DRAWER_CONTROLS_NAMESPACE);

            // NOTE: We could also set `notificationOptions` to undefined, but since the
            // notification is hidden, we don't *have* to clear out the content. that
            // would result in the markup also being cleared out. If the next time
            // the bottom bar is shown and the values end up being the same,
            // we could save some unnecessary DOM rewrites by *only* changing the
            // shown property

            // NOTE: using the callback form for `setState` since the new state
            // I want to set depends on the previous state. Because `setState`
            // is async this ensures that we're using the right version
            this.setState(
                ({ focusDrawerOptions: prevFocusDrawerOptions = {} }) => ({
                    focusDrawerOptions: {
                        ...prevFocusDrawerOptions,
                        notificationOptions: undefined,
                    },
                }),
            );

            if (onClose) {
                onClose();
            }
        }

        // Pass focusDrawerOptions back into the page component's scope as props
        render() {
            const {
                focusDrawerOptions: focusDrawerOptionsFromProp = {},
            } = this.props;
            const {
                focusDrawerOptions: focusDrawerOptionsFromHOC = {},
            } = this.state;

            // NOTE: For the cases where an experience (ahem Coyote) wants to use
            // a mix of declarative `focusDrawerOptions` prop + HOC generated, we
            // need to merge in what the HOC generates for `focusDrawerOptions`
            // with what the page declaratively passed in. Otherwise the HOC
            // would clobber and you would have to go all-in.

            let mergedFocusDrawerOptions = {
                ...focusDrawerOptionsFromProp,
                ...focusDrawerOptionsFromHOC,
            };

            // If the options are empty just to undefined so structure doesn't
            // try to display it
            if (isEmpty(mergedFocusDrawerOptions)) {
                mergedFocusDrawerOptions = undefined;
            }

            return (
                <PageComponent
                    {...this.props}
                    focusDrawerOptions={mergedFocusDrawerOptions}
                />
            );
        }
    };

export default addFocusDrawerControls;
