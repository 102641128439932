
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  import NextSteps from '../../components/nextSteps/NextSteps';

const layoutProps = {
  
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>
<MDXTag name="h1" components={components}>{`Cancelling and Refunding Orders`}</MDXTag>
<MDXTag name="p" components={components}>{`An Order's `}<MDXTag name="inlineCode" components={components} parentName="p">{`status`}</MDXTag>{` reflects it's current position in the cancellation or refund
process. Using this `}<MDXTag name="inlineCode" components={components} parentName="p">{`status`}</MDXTag>{` parameter, you can get a list of all Orders with
the status in which you are interested, for example those Orders whose refund is
has been `}<MDXTag name="inlineCode" components={components} parentName="p">{`denied`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Authenticating Your Access to the Eventbrite API`}</MDXTag>
<MDXTag name="p" components={components}>{`To work with Orders, you must authenticate your access to the Eventbrite API. To
learn how, refer to `}<MDXTag name="a" components={components} parentName="p" props={{"href":"/platform/docs/authentication"}}>{`Authenticating Your Access`}</MDXTag>{`
page.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="h2" components={components}>{`Finding Order information by Refund Status`}</MDXTag>
<MDXTag name="p" components={components}>{`To find Order information by refund status, append the parameter
`}<MDXTag name="inlineCode" components={components} parentName="p">{`refund_request_status`}</MDXTag>{` with the appropriate value to the GET request.`}</MDXTag>
<MDXTag name="p" components={components}>{`The value of the `}<MDXTag name="inlineCode" components={components} parentName="p">{`refund_request_statuses`}</MDXTag>{` parameter can be `}<MDXTag name="inlineCode" components={components} parentName="p">{`completed`}</MDXTag>{`,
`}<MDXTag name="inlineCode" components={components} parentName="p">{`pending`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`outside_policy`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`disputed`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`denied`}</MDXTag>{`.`}</MDXTag>
<MDXTag name="p" components={components}>{`For example the call`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-curl"}}>{`curl -X GET \
  https://www.eventbriteapi.com/v3/events/event_id/orders?refund_request_statuses=completed\
  -H 'Authorization: Bearer PERSONAL_OAUTH_TOKEN' \
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`returns an array of all Orders whose refund has been completed.`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-json"}}>{`{
    "pagination": {
        "object_count": 6,
        "page_number": 1,
        "page_size": 50,
        "page_count": 1,
        "has_more_items": false
    },
    "orders": [
        ...
        ]
}
`}</MDXTag></MDXTag>
<NextSteps
    steps={[
        {
            title: 'Embedding a Checkout Widget',
            link: '/platform/docs/embedded-checkout',
        },
        {
            title: 'Finding Information on an Order',
            link: '/platform/docs/order-lookup',
        },
    ]}
/>
           </MDXTag>
  }
}
  